import { observable, action, transaction } from "mobx";
import * as _ from "lodash";
import IAccount from "interfaces/IAccount";
import { notificationsStore } from "..";
import BaseItemStore from "@vidazoo/ui-framework/lib/stores/BaseItemStore";
import {accountsAPI} from "../../api";

export abstract class BaseAccountStore extends BaseItemStore<IAccount> {
    @observable public isLoadingPlatformNetworks: boolean;
    @observable public platformNetworks: Array<{ _id: string, name: string }>;

    constructor() {
        super(notificationsStore, "account");
    }

    @action public reset() {
        transaction(() => {
            super.reset();

            this.item = {
                ...this.item,
                username: "",
                network: "",
                isActive: true,
                biSources: observable.array([])
            };

            this.isLoadingPlatformNetworks = false;
            this.platformNetworks = observable.array([]);
        });
    }

    @action public addBiSourceId = (value: any, index: number) => {
        this.item.biSources[index].ids = [...this.item.biSources[index].ids, value];
    }

    @action public removeBiSourceId = (value: any, index: number) => {
        this.item.biSources[index].ids = this.item.biSources[index].ids.filter((item) => item !== value);
    }

    @action public addBiSource = () => {
        const value = {
            name: "",
            ids: [],
            providerFilter: null,
            advertisers: true
        };
        this.item.biSources = [...this.item.biSources, value];
    }

    @action public removeBiSource = (value: any) => {
        this.item.biSources = this.item.biSources.filter((item) => item !== value);
    }

    @action
    public getPlatformNetworks() {
        this.isLoadingPlatformNetworks = true;
        accountsAPI.getPlatformNetworks()
            .then((res) => this.setPlatformNetworks(res))
            .catch((err) => this.onLoadFailed(err));
    }

    @action
    private setPlatformNetworks(res) {
        this.platformNetworks = res.data;
        this.isLoadingPlatformNetworks = false;
    }
}
