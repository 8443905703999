import * as React from "react";
import * as PropTypes from "prop-types";
import AccountsStore from "stores/account/AccountsStore";
import { ISelectProps } from "@vidazoo/ui/lib/components/select";
import { FormSelect } from "@vidazoo/ui";

export interface IAccountSelectProps extends ISelectProps {

    accountsStore?: AccountsStore;

    value?: string;

    label?: string;

    onChange?: (e) => void;

}

export interface IAccountSelectState {

}

export default class AccountSelect extends React.Component<IAccountSelectProps, IAccountSelectState> {

    public static defaultProps: Partial<IAccountSelectProps> = {

    };

    constructor(props, context) {
        super(props, context);
    }

    public componentWillMount() {
        const { accountsStore } = this.props;

        accountsStore.getItems();
    }

    public render(): JSX.Element {
        const { accountsStore, value, onChange, ...others } = this.props;

        return (
            <FormSelect
                {...others as any}
                isLoading={accountsStore.isLoading}
                items={accountsStore.items.map((x) => ({ label: x.username, value: x._id }))}
                value={value}
                searchable
                onChange={onChange}
                maxItems={5}
                fill
                required
            />
        );
    }
}
