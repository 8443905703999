import {AggregateChangeComponent, Button, FormInput, LinkButton} from "@vidazoo/ui";
import * as React from "react";
import IDemandPartnerParam from "interfaces/IDemandPartnerParam";
import * as theme from "./theme.scss";
import {Link} from "react-router-dom";
import {normalizeEventValue} from "@vidazoo/ui-framework";

export interface IDemandPartnerParamProps {
    item: IDemandPartnerParam;
    onDelete?: (string: string) => void;
    onChangeProps?: (item: IDemandPartnerParam, name: string, value: string) => void;
}

export interface IDemandPartnerParamState {
}

export default class DemandPartnerParam extends React.Component<IDemandPartnerParamProps, IDemandPartnerParamState> {

    constructor(props, context) {
        super(props, context);
    }

    private onDeleteClick = () => {
        const {onDelete, item} = this.props;

        onDelete(item._id);
    }

    private onChangeProps = (e) => {
        const {onChangeProps, item} = this.props;

        const value = normalizeEventValue(e);
        onChangeProps(item, e.target.name, value);
    }

    public render() {
        const {item} = this.props;
        return(
                <div className={theme.item}>
                    <AggregateChangeComponent onChange={this.onChangeProps} >
                        <div className={theme.row}>
                            <FormInput name="key" placeholder="key" value={item.key} />
                            <FormInput name="value" placeholder="value" value={item.value} className={theme.value} />
                            <LinkButton iconName="icon-delete" onClick={this.onDeleteClick} iconSize={2} />
                        </div>
                    </AggregateChangeComponent>
                </div>
            );
        }
}
