import * as React from "react";
import {Moment} from "moment";
import {IRawReportEntry} from "../../interfaces/IRawReportEntry";
import {Button, DateTimeRangePicker, HLine, PageContainer, PageTitle, SortDirection, Surface} from "@vidazoo/ui";
import List from "./components/list";
import * as theme from "./theme.scss";
import {IDictionary} from "@vidazoo/ui-framework";
import {Loader, DateTimeRangePickerThemed, PresetTimes} from "../../components/";
import {TimeRange} from "../../common/enums";

export interface IRawReportProps {
    getReport: () => void;
    isLoading: boolean;
    report: IRawReportEntry[];
    sortBy: string;
    sortDirection?: SortDirection;
    onSort: (sortBy: string) => void;
    searchQueries?: IDictionary<string>;
    onSearch?: (searchKey: string, value: string) => void;
    from: Moment;
    to: Moment;
    onFromSelect: (from: Moment) => void;
    onToSelect: (to: Moment) => void;
    download: () => void;
    presetTime?: TimeRange;
    onPresetTimeChange?: (preset: TimeRange) => void;
}

export interface IRawReportState {
}

export default class RawReport extends React.Component<IRawReportProps, IRawReportState> {

    public componentDidMount() {
        const {getReport} = this.props;
        getReport();
    }

    public render(): JSX.Element {
        const {report, getReport, isLoading, from, to, onFromSelect, onToSelect, download, onSort, onSearch, sortDirection, sortBy, searchQueries, presetTime, onPresetTimeChange} = this.props;
        return (
            <PageContainer pageTitle="Raw Report" top={1.2} className={theme.list}>
                <Surface level={2}>
                    <div className={theme.rangePickerPanel}>
                        <PageTitle className={theme.title} iconName="icon-reporting" label="Raw Report" />
                        <div className={theme.presetTimes}>
                            <PresetTimes presetTime={presetTime} onPresetChange={onPresetTimeChange}/>
                        </div>
                        <DateTimeRangePickerThemed>
                            <DateTimeRangePicker fromDate={from} maxFromDate={to} toDate={to} minToDate={from} onFromChange={onFromSelect} onToChange={onToSelect} allDay={true} />
                        </DateTimeRangePickerThemed>
                        <HLine />
                        <div className={theme.actions}>
                            <div className={theme.buttons}>
                                <Button iconName="icon-download" onClick={download} medium green>Download CSV</Button>
                                <Button iconName="icon-reload" onClick={getReport} medium blue>Reload Report</Button>
                            </div>
                        </div>
                    </div>
                </Surface>
                {isLoading ? <Loader title="Loading Raw Report" description="Please wait..."/>  : (report ?
                    <List
                        items={report}
                        sortBy={sortBy}
                        onSort={onSort}
                        sortDirection={sortDirection}
                        searchQueries={searchQueries}
                        onSearch={onSearch}
                    /> : null)}
            </PageContainer>
        );
    }
}
