/************************************************
 *                                               *
 *                  BOOTSTRAP                    *
 *                                               *
 ************************************************/

import "styles/main.scss";
import "common/polyfills";
import { configure } from "mobx";
import { initialState, sessionStore } from "stores";

/* tslint:disable no-var-requires */
const jsonlint = require("jsonlint-mod");
(window as any).jsonlint = jsonlint;

/**
 * Service Worker Registration
 */
if (process.env.NODE_ENV === "production") {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register("/sw.js")
            .then((reg) => {
                reg.onupdatefound = () => {
                    const installingWorker = reg.installing;
                    installingWorker.onstatechange = () => {
                        if (installingWorker.state === "installed") {
                            initialState.setPendingNewVersion(true);
                        }
                    };
                };
            });
    }
}

/**
 * SVG Sprite Loader
 */
const __svg__ = { path: "../node_modules/@vidazoo/ui/lib/assets/svg/*.svg", name: "assets/svg/logos.svg" };

(require("webpack-svgstore-plugin/src/helpers/svgxhr") as any)(__svg__);

/**
 * App State
 */
configure({
    enforceActions: "always"
});

sessionStore.initialize();
