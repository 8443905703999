import {AggregateChangeComponent, FormInput, LinkButton} from "@vidazoo/ui";
import * as React from "react";
import * as theme from "./theme.scss";
import {normalizeEventValue} from "@vidazoo/ui-framework";
import {IHardcodedCpmTagKeyValuePair} from "../../../interfaces/IDemandPartner";

export interface IDemandPartnerUrlParamProps {
    item: IHardcodedCpmTagKeyValuePair;
    onDelete?: (string: string) => void;
    onChangeProps?: (item: IHardcodedCpmTagKeyValuePair, name: string, value: string) => void;
}

export interface IDemandPartnerUrlParamState {
}

export default class HardcodedCpmTag extends React.Component<IDemandPartnerUrlParamProps, IDemandPartnerUrlParamState> {

    constructor(props, context) {
        super(props, context);
    }

    private onDeleteClick = () => {
        const {onDelete, item} = this.props;

        onDelete(item._id);
    }

    private onChangeProps = (e) => {
        const {onChangeProps, item} = this.props;

        const value = normalizeEventValue(e);
        onChangeProps(item, e.target.name, value);
    }

    public render() {
        const {item} = this.props;
        return(
                <div className={theme.item}>
                    <AggregateChangeComponent onChange={this.onChangeProps} >
                        <div className={theme.row}>
                            <FormInput name="key" placeholder="Tag Partner Name" value={item.key} className={theme.key} disabled={item.key === "Account"}/>
                            <FormInput name="value" placeholder="CPM" value={item.value} type="number" step={0.01} />
                            <LinkButton iconName="icon-delete" onClick={this.onDeleteClick} iconSize={2} />
                        </div>
                    </AggregateChangeComponent>
                </div>
            );
        }
}
