// System
import {
    BaseNotificationsStore,
    ProfileStore,
    AccountCredentialsStore,
    UserNotificationsStore,
    UserNotificationsSocketService,
    UserNotificationsSettingsStore
} from "@vidazoo/ui-framework";
import InitialState from "./InitialState";
import SessionStore from "./SessionStore";
import CurrentUserStore from "./CurrentUserStore";
import NavigationStore from "./NavigationStore";

// Network
import NetworksStore from "./network/NetworksStore";
import CreateNetworkStore from "./network/CreateNetworkStore";
import NetworkStore from "./network/NetworkStore";

export const initialState = new InitialState();
export const navigationStore = new NavigationStore();
export const sessionStore = new SessionStore();
export const notificationsStore = new BaseNotificationsStore();
export const profileStore = new ProfileStore(notificationsStore);
export const currentUserStore = new CurrentUserStore();
export const accountCredentialsStore = new AccountCredentialsStore(notificationsStore, currentUserStore);

// Account
import AccountsStore from "./account/AccountsStore";
import AccountStore from "./account/AccountStore";
import CreateAccountStore from "./account/CreateAccountStore";

// demandPartners
import CreateDemandPartnerStore from "./demandPartners/CreateDemandPartnerStore";
import DemandPartnersStore from "./demandPartners/DemandPartnersStore";
import DemandPartnerStore from "./demandPartners/DemandPartenrStore";

// reports
import DailyReportStore from "./reports/DailyReportStore";
import ECPMReportStore from "./reports/ECPMReportStore";
import RawReportStore from "./reports/RawReportStore";
import DiscrepancyReportStore from "./reports/DiscrepancyReportStore";

// jobsStatus
import JobsStatusStore from "./jobsStatus/JobsStatusStore";
import {storageService} from "../services";

// Network
export const networksStore = new NetworksStore();
export const networkStore = new NetworkStore();
export const createNetworkStore = new CreateNetworkStore();

// Account
export const accountsStore = new AccountsStore();
export const accountStore = new AccountStore();
export const createAccountStore = new CreateAccountStore();

// demandPartners
export const createDemandPartnerStore = new CreateDemandPartnerStore();
export const demandPartnerStore = new DemandPartnerStore();
export const demandPartnersStore = new DemandPartnersStore();
export const discrepancyReportStore = new DiscrepancyReportStore();

// reports
export const dailyReportStore = new DailyReportStore();
export const ecpmReportStore = new ECPMReportStore();
export const rawReportStore = new RawReportStore();

// jobsStatus
export const jobsStatusStore = new JobsStatusStore();

// User Notifications
export const userNotificationsStore = new UserNotificationsStore(UserNotificationsSocketService);
export const userNotificationsSettingsStore = new UserNotificationsSettingsStore(UserNotificationsSocketService, notificationsStore, storageService);
