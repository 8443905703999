import * as React from "react";
import * as moment from "moment";
import IDemandPartner from "interfaces/IDemandPartner";
import IECPMReportEntry from "interfaces/IECPMReportEntry";
import * as numeral from "numeral";
import * as theme from "./theme.scss";
import * as classnames from "classnames";
import {IntervalType} from "../../../../../../common/enums";
import {DataGridColumn, DataGridRow} from "@vidazoo/ui";

export interface IECPMReportRowProps {

    item?: IECPMReportEntry;
    onMineClick?: (sspId: string, from: moment.Moment, to: moment.Moment) => void;
    openModal?: (title: string, onDone: (from: moment.Moment, to: moment.Moment, sspId?: string) => void) => void;
    onEditClick?: (item: IDemandPartner) => void;
    intervalType: IntervalType;
    columnsWidth: any;
    freezeColumnIndex: number;
    index: number;
}

export interface IECPMReportRowState {
}

export default class ECPMReportRow extends React.Component<IECPMReportRowProps, IECPMReportRowState> {

    private get rowColorClass(): string {
        const {item} = this.props;
        let color;
        if (item.missingData && !item.tagPartnerName) {
            color = theme.red;
        } else if (item.missingData && !item.sspTagName) {
            color = theme.orange;
        } else if (item.imaDiffPercent && item.imaDiffPercent > 10) {
            color = theme.purple;
        } else if (item.cpmDiffPercent && Math.abs(item.cpmDiffPercent) > 5) {
            color = theme.yellow;
        }

        return color;
    }

    public render(): JSX.Element {

        const classes = classnames(theme.row, this.rowColorClass);

        const { item, intervalType, columnsWidth, freezeColumnIndex, index } = this.props;

        return (
            <DataGridRow className={classes} index={index} columnsWidth={columnsWidth} key={index} freezeColumnIndex={freezeColumnIndex}>
                {intervalType === IntervalType.Day ? <DataGridColumn className={theme.column}>{ item.date }</DataGridColumn> : null}
                <DataGridColumn className={theme.column}>{ item.source }</DataGridColumn>
                <DataGridColumn className={theme.column}>{ item.tagPartnerId }</DataGridColumn>
                <DataGridColumn className={theme.column}>{ item.tagPartnerName }</DataGridColumn>
                <DataGridColumn className={theme.column}>{ numeral(item.adSent).format("0,0") }</DataGridColumn>
                <DataGridColumn className={theme.column}>{ numeral(item.adSpm).format("0,0.00") }</DataGridColumn>
                <DataGridColumn className={theme.column}>{ numeral(item.adStart).format("0,0") }</DataGridColumn>
                <DataGridColumn className={theme.column}>{ numeral(item.adCpm).format("$0,0.00") }</DataGridColumn>
                <DataGridColumn className={theme.column}>{ numeral(item.imaFloorCpm).format("$0,0.00") }</DataGridColumn>
                <DataGridColumn className={theme.column}>{ item.sspProvider }</DataGridColumn>
                <DataGridColumn className={theme.column}>{ item.sspTagName }</DataGridColumn>
                <DataGridColumn className={theme.column}>{ numeral(item.sspImpressions).format("0,0") }</DataGridColumn>
                <DataGridColumn className={theme.column}>{ numeral(item.sspNetRevenue).format("$0,0.00") }</DataGridColumn>
                <DataGridColumn className={theme.column}>{ numeral(item.sspRequests).format("0,0") }</DataGridColumn>
                <DataGridColumn className={theme.column}>{ numeral(item.sspEcpm).format("$0,0.00") }</DataGridColumn>
                <DataGridColumn className={theme.column}>{ numeral(item.cpmDiffPercent).format("0,0.00") }%</DataGridColumn>
                <DataGridColumn className={theme.column}>{ numeral(item.impressionsDiffPercent).format("0,0.00") }%</DataGridColumn>
                <DataGridColumn className={theme.column}>{ numeral(item.impressionsDiff).format("0,0") }</DataGridColumn>
                <DataGridColumn className={theme.column}>{ numeral(item.netRevenueDiffPercent).format("0,0.00")}%</DataGridColumn>
                <DataGridColumn className={theme.column}>{ numeral(item.netRevenueDiff).format("0,0")}</DataGridColumn>
            </DataGridRow>
    );
    }
}
