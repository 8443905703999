import { observable, action, transaction, computed } from "mobx";
import { accountsAPI } from "api";
import IUser from "interfaces/IUser";
import { currentUserStore } from ".";
import { socketService } from "services";
import { INetwork } from "interfaces/INetwork";
import IAccount from "interfaces/IAccount";
import * as _ from "lodash";
import {IReportingEntry, UserNotificationsSocketService} from "@vidazoo/ui-framework";

export default class SessionStore {
    @observable public isAuthenticated: boolean;
    @observable public isAuthenticating: boolean;
    @observable public isAuthError: boolean;
    @observable public accessToken: string;
    @observable public selectedAccounts: string[];
    public accounts: IAccount[];
    public indexAccounts: any;

    constructor() {
        window.__sdk__.auth.on("session:change", (err, res) => this.onSessionChange(err, res));

        this.isAuthenticated = false;
        this.isAuthenticating = true;
        this.isAuthError = false;
        this.accessToken = null;
        this.accounts = [];
        this.selectedAccounts = window.__sdk__.auth.selectedAccounts;
        this.indexAccounts = {};
    }

    @action public setSelectedAccounts = (accounts: string[], reload?: boolean) => {
        window.__sdk__.auth.setSelectedAccounts(accounts);
        this.selectedAccounts = accounts;
        currentUserStore.setNetwork(this.indexAccounts[accounts[0]].network);

        if (reload) {
            window.location.reload();
        }
    }

    @computed public get userAccounts(): IAccount[] {
        return this.accounts;
    }

    @computed public get isMultiAccounts(): boolean {
        return this.accounts.length > 1;
    }

    @computed public get isMultiSelectedAccounts(): boolean {
        return this.selectedAccounts.length > 1;
    }

    @action private async onSessionChange(err, response) {
        if (response) {
            this.accessToken = response.accessToken;

            try {
                const account = await accountsAPI.me();

                this.onLogin(response.accessToken, account.data);
            } catch (err) {
                this.onLoginFailed(err);
            }
        }
    }

    @action public initialize() {
        window.__sdk__.auth.authorize();
    }

    @action public logout = () => {
        window.__sdk__.auth.logout();
    }

    @action private async onLogin(accessToken: string, data: { auth: IUser, network: INetwork, accounts: IAccount[], sspTypes: string[]}) {
        this.selectedAccounts = window.__sdk__.auth.selectedAccounts;
        this.accounts = data.accounts;
        this.indexAccounts = _.keyBy(this.accounts, "_id");

        if (!this.selectedAccounts.length || !this.indexAccounts[this.selectedAccounts[0]]) {
            window.__sdk__.auth.setSelectedAccounts([data.accounts[0]._id.toString()]);
            this.selectedAccounts = window.__sdk__.auth.selectedAccounts;
        }

        currentUserStore.initialize({ ...data.auth, network: this.indexAccounts[this.selectedAccounts[0]].network, sspTypes: data.sspTypes});

        if (currentUserStore.isVidazooOrganization) {
            try {
                await UserNotificationsSocketService.initialize((window.__USER_NOTIFICATIONS_API_URL__), this.accessToken);

            } catch (err) {
                this.onLoginFailed(err);
            }
        }

        socketService.initialize(window.__BASE_API_URL__, this.accessToken)
            .then(() => this.onSocketConnected())
            .catch((err) => this.onLoginFailed(err));
    }

    @action private onSocketConnected() {
        transaction(() => {
            this.isAuthenticated = true;
            this.isAuthenticating = false;
            this.isAuthError = false;
        });
    }

    @action private onLoginFailed(err) {
        transaction(() => {
            this.accessToken = null;
            this.isAuthenticated = false;
            this.isAuthenticating = false;
            this.isAuthError = true;
        });
    }

    public isSelectedAccount = (include?: string[] | string, exclude?: string[] | string): boolean => {
        if (include && !(include instanceof Array)) {
            include = [include];
        }

        if (exclude && !(exclude instanceof Array)) {
            exclude = [exclude];
        }

        const included = include && include.length > 0 ? _.some(include, (account) => _.includes(this.selectedAccounts, account)) : true;
        const excluded = exclude && exclude.length > 0 ? !_.some(exclude, (account) => _.includes(this.selectedAccounts, account)) : true;

        return included && excluded;
    }
}
