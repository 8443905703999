import * as React from "react";
import IDemandPartnerParam from "interfaces/IDemandPartnerParam";
import HardcodedCpmTag from "./hardcodedCpmTag";
import {guid} from "@vidazoo/ui-framework";
import {IHardcodedCpmTagKeyValuePair} from "../../interfaces/IDemandPartner";

export interface IDemandPartnerHardcodedCpmTagsProps {
    items?: IHardcodedCpmTagKeyValuePair[];
    onDelete: (id: string) => void;
    onChangeProps?: (item: IHardcodedCpmTagKeyValuePair, name: string, value: string) => void;
    hardcodedCpmAllAccount?: boolean;
}

export interface IDemandPartnerHardcodedCpmTagsState {

}

export default class HardcodedCpmTags extends React.Component<IDemandPartnerHardcodedCpmTagsProps, IDemandPartnerHardcodedCpmTagsState> {

    public render(): JSX.Element {
        const {items, onDelete, onChangeProps, hardcodedCpmAllAccount}  = this.props;
        return (
            <div>
                { hardcodedCpmAllAccount ?
                    <HardcodedCpmTag key={items[0]._id} item={items[0]} onDelete={onDelete} onChangeProps={onChangeProps} /> :
                    items.map((item) => <HardcodedCpmTag key={guid()} item={item} onDelete={onDelete} onChangeProps={onChangeProps} />) }
            </div>
        );
    }
}
