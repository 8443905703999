import * as React from "react";
import * as numeral from "numeral";
import * as _ from "lodash";
import * as theme from "./theme.scss";
import {Button} from "@vidazoo/ui";
import {DailyReportField} from "common/enums";
import {guid} from "@vidazoo/ui-framework";
import {appUrlsService} from "../../../../../services";
import {Link} from "react-router-dom";

export interface IDailyReportRowProps {
    title: string;
    onStartMineClick: (name: string) => void;
    data: {
        requests: number,
        impressions: number,
        netRevenue: number,
        ecpm: number,
        fillRate: number
    };
}

const numeralFormat = {
    requests: "0,0",
    impressions: "0,0",
    netRevenue: "$0,0",
    ecpm: "$0,0.00",
    fillRate: "0,0.00"
};

function totalByDate(dates: number[], data, param): JSX.Element[] {
    return (
        _.map(dates, (date) => (<td className={theme[param]}
                                    key={date}>{numeral((data.days[date] || {})[param]).format(numeralFormat[param]) + (param === "fillRate" ? "%" : "")} </td>))
    );
}

function renderEcpmAndFillRate(param, length, data): JSX.Element {
    let resElement = null;

    if (length > 1) {
        resElement = (param === "ecpm" || param === "fillRate") ?
            <td className={theme[param]}/> :
            <td className={theme[param]}>{numeral(data[param]).format(numeralFormat[param])}</td>;
    }

    return resElement;
}

function renderProviderNameCell(isTotalRow: boolean, name: string, onStartMineClick: (name: string) => void, providerId: string): JSX.Element {
    let resRow;

    if (isTotalRow) {
        resRow = (<td className={theme.name} rowSpan={4} style={{verticalAlign: "middle"}}>Total</td>);
    } else {
        resRow = (<td className={theme.name} rowSpan={5} style={{verticalAlign: "middle"}}>
            <div className={theme.group}>
                <Link to={appUrlsService.editDemandPartner(providerId)}>{name}</Link>
                <Button iconName="icon-reload" onClick={() => onStartMineClick(name)} small purple>Start Mine</Button>
            </div>
        </td>);
    }

    return resRow;
}

export default function ReportRow(props): JSX.Element {
    const {dates, data, isTotalRow, onMineClick} = props;
    return (
        <React.Fragment>
            {Object.keys(data.avg).map((param, index) => (
                <tr className={theme.row} key={guid()}>
                    {index === 0 ? renderProviderNameCell(isTotalRow, data.provider, onMineClick, data.providerId) : null}
                    <td className={theme[param]}>{isTotalRow ? "Total" : ""} {DailyReportField[param]}</td>
                    {totalByDate(dates, data, param)}
                    {renderEcpmAndFillRate(param, dates.length, data.sum)}
                    {dates.length > 1 ?
                        <td className={theme[param]}>{numeral(data.avg[param]).format(numeralFormat[param])}</td> : null}
                </tr>
            ))}
        </React.Fragment>
    );
}

// export default ({title, data, ,onStartMineClick}: IDailyReportRowProps): JSX.Element => {
//     const { requests, impressions, netRevenue, ecpm, fillRate} = data;
//     return (
//         <React.Fragment>
//             <tr>
//                 <td rowSpan={5}>
//                     {title}
//                     <Button iconName="icon-reload" onClick={() => onStartMineClick(title)} small purple>Start Mine</Button>
//                 </td>
//             </tr>
//             <tr>
//                 <td>"Requests"</td>
//                 <td>{numeral(requests).format("0,0")}</td>
//             </tr>
//             <tr>
//                 <td>"Impressions"</td>
//                 <td>{numeral(impressions).format("0,0")}</td>
//             </tr>
//             <tr>
//                 <td>"Net Revenue"</td>
//                 <td>{numeral(netRevenue).format("$0,0")}</td>
//             </tr>
//             <tr>
//                 <td>"ECPM"</td>
//                 <td>{numeral(ecpm).format("$0,0.00")}</td>
//             </tr>
//             <tr>
//                 <td>"Fill Rate"</td>
//                 <td>{numeral(fillRate).format("%0,0.00")}</td>
//             </tr>
//         </React.Fragment>
//     );
// };
