import {action, computed, observable} from "mobx";
import {reportsAPI} from "api";
import * as _ from "lodash";
import {ReportType, TimeRange} from "common/enums";
import {csvBuilder} from "../../services";
import * as moment from "moment";
import {Moment} from "moment";
import {getDatesRange, getTimeByRange} from "../../common/utils";
import IDailyReport from "../../interfaces/dailyReport/IDailyReport";
import {notificationsStore} from "../index";

export default class DailyReportStore {
    @observable public report: IDailyReport;
    @observable public from: Moment;
    @observable public to: Moment;
    @observable public isLoading: boolean;
    @observable public sortBy: string;
    @observable public dates: number[];
    @observable public currentChartParameter: string;
    @observable public preset: TimeRange;

    public constructor() {
        this.reset();
    }

    @action public reset = () => {
        this.report = null;
        this.dates = [];
        this.from = moment.utc().startOf("day").subtract(1, "day");
        this.to = moment.utc().endOf("day").subtract(1, "day");
        this.isLoading = false;
        this.sortBy = "netRevenue";
        this.currentChartParameter = "netRevenue";
        this.preset = TimeRange.YESTERDAY;
    }

    @action public getDailyReport = () => {

        this.isLoading = true;

        this.getDatesRange();

        if (this.from.unix() <= this.to.unix()) {
            reportsAPI.getDailyReport(this.from.unix(), this.to.unix())
                .then((res) => this.setReport(res.data))
                .catch((err) => this.onReportFailed());
        }
    }

    @action private setReport(report: any) {
        this.report = report;
        this.isLoading = false;
    }

    @computed public get sortedReport(): any {
        let iterateesFunc;
        let orders = ["desc"];

        if (!this.report) {
            return;
        }

        switch (this.sortBy) {
            case "ecpm":
                iterateesFunc = (o) => o.avg[this.sortBy];
                break;
            case "fillRate":
                iterateesFunc = (o) => o.avg[this.sortBy];
                break;
            case "provider": {
                iterateesFunc = (o) => o[this.sortBy];
                orders = ["asc"];
                break;
            }
            default:
                iterateesFunc = (o) => o.sum[this.sortBy];
        }

        const report = {
            ...this.report
        };

        report.providersData = _.orderBy<any>(this.report.providersData, [iterateesFunc], orders as any);

        return report;
    }

    @action public setSortBy = (sortBy: string) => {
        this.sortBy = sortBy;
    }

    @action public onFromDateSelect = (newFrom: Moment) => {
        this.from = newFrom;
        this.preset = TimeRange.CUSTOM;
    }

    @action public onToDateSelect = (newTo: Moment) => {
        this.to = newTo;
        this.preset = TimeRange.CUSTOM;
    }

    @action public onPresetTimeChange  = (preset: TimeRange) => {
        this.preset = preset;

        if (TimeRange.CUSTOM === preset) {
            return;
        }

        const {from, to} = getTimeByRange(preset);
        this.setDate(from, to);
    }

    @action public setDate(from: Moment, to: Moment) {
        this.from = from;
        this.to = to;
    }

    @computed public get chartData(): {data: Array<{demandPartner: string, days: string[]}>, from: Moment} {
        if (!this.report) {
            return;
        }

        const chartData: any = {};
        chartData["data"] =  this.report.providersData.map((demandPartner) => {
            const days = Object.keys(demandPartner.days)
                .sort((a: any, b: any) => a - b)
                .map((day) => demandPartner.days[day][this.currentChartParameter]);

            return {
                demandPartner: demandPartner.provider,
                days,
            };
        });

        chartData["from"] = Object.keys(this.report.providersData[0].days)[0];

        return chartData;
    }

    @action public onChartParameterSelect = (parameter: string) => {
        this.currentChartParameter = parameter;
    }

    public downloadCSV = () => {
        const fileName = `moneybooker_daily_report_${this.from.format("DD-MM-YYYY")}__${this.to.format("DD-MM-YYYY")}.csv`;
        csvBuilder.build(ReportType.DAILY_REPORT, {report: this.report, dates: this.dates}, fileName);
    }

    @action private onReportFailed() {
        this.isLoading = false;
        notificationsStore.pushErrorNotification({
            title: "Oops!",
            text: "Something went wrong. try again.",
            timeout: 5000
        });
    }

    private getDatesRange(format?: string) {
        const dates = getDatesRange(this.from.unix(), this.to.unix());
        this.dates = format ? dates.map((date) => moment.utc(date, "X").format(format)) : dates;
    }
}
