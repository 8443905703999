import BaseItemStore from "@vidazoo/ui-framework/lib/stores/BaseItemStore";
import IDemandPartner from "interfaces/IDemandPartner";
import {notificationsStore} from "../index";
import {action, observable, transaction} from "mobx";
import {guid, MemoryClipboard} from "@vidazoo/ui-framework";
import IDemandPartnerParam from "../../interfaces/IDemandPartnerParam";
import {DemandPartnerStoreType} from "common/enums";

export default class BaseDemandPartnerStore extends BaseItemStore<IDemandPartner> {

    private storeType: DemandPartnerStoreType;

    constructor(storeType: DemandPartnerStoreType) {
        super(notificationsStore, "Demand Partner");
        this.storeType = storeType;
    }

    // tslint:disable-next-line:no-empty
    public submit(): any {
    }

    public submitify(): IDemandPartner {
        return this.item;
    }

    @action
    public reset() {
        transaction(() => {
            super.reset();

            this.item = {
                ...this.item,
                ssp: "",
                name: "",
                username: "",
                password: "",
                params: [],
                isActive: true,
                totalRevenueFormula: "",
                autoUpdateCPM: false,
                openRtbParams: null,
                openRtbUrls: null,
                ignoreMinImpressions: false,
                vidazooImpUpdateCPM: false,
                hardcodedCpm: false,
                hardcodedCpmAllAccount: false,
                hardcodedCpmTags: []
            };
        });
    }

    @action
    public updateParam(key: string, value: any): void {
        if (key === "ssp") {
            if (value === "OpenRTB") {
                this.item.openRtbParams = observable({
                    fromParamKey: "",
                    toParamKey: "",
                    impressions: "",
                    revenue: "",
                    requests: "",
                    placement: "",
                    endpointsUrls: observable([])
                });
                this.item.openRtbUrls = observable([]);
            } else {
                this.item.openRtbParams = null;
                this.item.openRtbUrls = null;
            }
        }

        if (key === "hardcodedCpmAllAccount") {
            this.item.hardcodedCpmTags = [];
            if (value) {
                const item = {
                    _id: guid(),
                    key: "Account",
                    value,
                };
                this.addToArray("hardcodedCpmTags", item);
            }
        }

        super.updateParam(key, value);
    }

    @action public updateOpenRtbParam = (key: string, value: any) => {
            this.item.openRtbParams[key] = value;
            this.updateParam(key, value);
    }

    @action public onChangeParamsProps = (param: IDemandPartnerParam, key: string, value: any) => {
        if (key === "hardcodedCpmAllAccount") {
            this.item.hardcodedCpmTags = [];
            const item = {
                _id: guid(),
                key: "Account",
                value,
            };

            this.addToArray("hardcodedCpmTags", item);
        }
        param[key] = value;
    }

    @action public addParam = (key: string = "", value: string = "") => {
        const param = {
            _id: guid(),
            key,
            value,
        };

        this.addToArray("params", param);
    }

    @action public deleteParam = (id: string) => {
        this.removeFromArray("params", id, "_id");
    }

    public getStoreType = () => this.storeType;

    @action public onChangeUrlsProps = (openRtbUrls: IDemandPartnerParam, key: string, value: string) => {
        openRtbUrls[key] = value;
    }

    @action public addUrl = (key: string = "url", value: string = "") => {
        const param = {
            _id: guid(),
            key,
            value,
        };

        this.addToArray("openRtbUrls", param);
    }

    @action public deleteUrl = (id: string) => {
        this.removeFromArray("openRtbUrls", id, "_id");
    }

    @action public copyParams = () => {
        const raw = this.item.params.map((row) => {
            return {key: row.key, value: row.value};
        })
        MemoryClipboard.copy(JSON.stringify(raw));

        notificationsStore.pushNotification({
            title: "Demand Partner Params",
            text: "Copied to your clipboard",
            success: true,
            timeout: 2000
        });
    }

    @action public addHardcodedCpmTag = (key: string = "", value = null) => {
        const param = {
            _id: guid(),
            key,
            value,
        };

        this.addToArray("hardcodedCpmTags", param);
    }

    @action public deleteHardcodedCpmTag = (id: string) => {
        this.removeFromArray("hardcodedCpmTags", id, "_id");
    }

    @action public clearParams = () => {
        this.item.params = [];
    }

}
