import { BaseAPI } from "@vidazoo/ui-framework";

class AccountsAPI extends BaseAPI {
    public getAll(page: number = 1, page_size: number = 50, filter: any = {}, fields: any = {}, sort: any = []) {
        return this.request({
            method: "get",
            url: "/api/accounts/",
            params: { page, page_size, filter, fields, sort }
        });
    }

    public getOne(id: string) {
        return this.request({
            method: "get",
            url: `/api/accounts/${id}/`
        });
    }

    public create(data: any) {
        return this.request({
            method: "post",
            url: "/api/accounts/",
            data
        });
    }

    public delete(id: string) {
        return this.request({
            method: "delete",
            url: `/api/accounts/${id}/`
        });
    }

    public update(id: string, data: any) {
        return this.request({
            method: "patch",
            url: `/api/accounts/${id}/`,
            data
        });
    }

    public active(id: string, isActive: boolean) {
        return this.request({
            method: "patch",
            url: `/api/accounts/${id}/active/`,
            data: { isActive }
        });
    }

    public me() {
        return this.request({
            method: "get",
            url: `/api/accounts/me`
        });
    }

    public getPlatformNetworks() {
        return this.request({
            method: "get",
            url: `/api/accounts/platform_networks/`
        });
    }

}

export default new AccountsAPI(window.__BASE_API_URL__);
