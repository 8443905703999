import { observable, action, computed } from "mobx";
import { storageService } from "services";
import BaseUserStore from "./base/BaseUserStore";
import IUser from "interfaces/IUser";
import * as _ from "lodash";
import { UserRole } from "@vidazoo/ui-framework";
import { INetwork } from "interfaces/INetwork";

export interface ICurrentUserStore extends IUser {
    initialize: (user: IUser) => void;
    isInRoles: (scope: string[]) => boolean;
    isAnyAdmin: () => boolean;
    isAdmin: () => boolean;
    isRootAdmin: () => boolean;
}

export default class CurrentUserStore extends BaseUserStore implements ICurrentUserStore {

    @observable public isVidazooOrganization: boolean;

    constructor() {
        super();
    }

    public initialize(user: IUser) {
        super.initialize(user);

        this.isVidazooOrganization = user.organization ? (user.organization.name === "vidazoo") : false;

        storageService.initialize(this._id);
    }

    public setNetwork = (network: INetwork) => {
        this.network = network;
    }

    public isInRoles = (scope: UserRole[]): boolean => {
        return (scope && scope.length > 0)
            ? _.some(scope, (role) => _.includes(this.roles, role))
            : true;
    }

    public isAnyAdmin(): boolean {
        return this.isInRoles([UserRole.ADMIN, UserRole.ROOT_ADMIN]);
    }

    public isAdmin(): boolean {
        return this.isInRoles([UserRole.ADMIN]);
    }

    public isRootAdmin(): boolean {
        return this.isInRoles([UserRole.ROOT_ADMIN]);
    }

    public shouldViewAll(): boolean {
        return this.isInRoles([UserRole.VIEW_ALL]);
    }

    public isSuperAdmin(): boolean {
        return this.isInRoles([UserRole.SUPER_ADMIN]);
    }

    public isPublisher(): boolean {
        return this.isInRoles([UserRole.PUBLISHER]);
    }

    public isInviteVidazoo = () => {
        return this.isInRoles([UserRole.INVITE_VIDAZOO]);
    }
}
