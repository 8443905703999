import { UserRole } from "@vidazoo/ui-framework";
import IMenuEntry from "interfaces/IMenuEntry";
import { appUrlsService } from "services";

const DEFAULT_ACCESS = [UserRole.USER, UserRole.ADMIN, UserRole.SUPER_ADMIN];

export default [
    {
        title: "Daily report",
        iconName: "icon-dashboard",
        link: { to: appUrlsService.dailyReport() },
        access: DEFAULT_ACCESS
    },
    {
        title: "ECPM report",
        iconName: "icon-campaigns",
        link: { to: appUrlsService.ecpmReport() },
        access: DEFAULT_ACCESS
    },
    {
        title: "Demand Partners",
        iconName: "icon-demand-partners",
        link: { to: appUrlsService.demandPartners() },
        access: DEFAULT_ACCESS
    },
    {
        title: "Jobs Status",
        iconName: "icon-dashboard-2",
        link: { to: appUrlsService.jobsStatus() },
        access: DEFAULT_ACCESS
    },
    {
        title: "Raw Report",
        iconName: "icon-reporting",
        link: { to: appUrlsService.rawReport() },
        access: DEFAULT_ACCESS
    },
    {
        title: "Discrepancy Report",
        iconName: "icon-demand",
        link: { to: appUrlsService.discrepancyReport() },
        access: DEFAULT_ACCESS
    },
] as IMenuEntry[];
