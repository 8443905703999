import * as React from "react";
import {
    AggregateChangeComponent,
    Button,
    ConfirmPopMessage,
    FormInput,
    FormSelect,
    HLine,
    Label,
    PageMessage,
    Switch,
    Tooltip
} from "@vidazoo/ui";
import * as theme from "styles/_baseForm.scss";
import DemandPartnerParams from "components/demandPartnerParams/DemandPartnerParams";
import UrlParams from "components/demandPartnerUrlParams/";
import DemandPartnerStore from "stores/demandPartners/DemandPartenrStore";
import CreateDemandPartnerStore from "stores/demandPartners/CreateDemandPartnerStore";
import * as enums from "common/enums";
import {DemandPartnerStoreType, Timezones} from "common/enums";
import {guid, normalizeEventValue} from "@vidazoo/ui-framework";
import IDemandPartnerParam from "interfaces/IDemandPartnerParam";
import {HardcodedCpmTags} from "../../../../components";
import Modal from "@vidazoo/ui/lib/components/modal";

export interface IDetailsFormProps {
    store?: DemandPartnerStore | CreateDemandPartnerStore;
    sspTypes?: string[];
}

export interface IDetailsFormState {
    credentialsParamsRows: any;
    isPasteRawOpen: boolean;
}

export default class DetailsForm extends React.Component<IDetailsFormProps, IDetailsFormState> {

    constructor(props, context) {
        super(props, context);

        this.state = {
            credentialsParamsRows: [],
            isPasteRawOpen: false
        };
    }

    private onAddClick = () => {
        const {store} = this.props;
        const {addParam} = store;

        addParam();
    }

    private onDeleteClick = (id: string) => {
        const {store} = this.props;
        const {deleteParam} = store;

        deleteParam(id);
    }

    private onChangeDetail = (e) => {
        const {store} = this.props;
        const value = normalizeEventValue(e);
        const openRtbParams = ["fromParamKey", "toParamKey", "impressions", "revenue", "requests", "placement", "endpointsUrls"];
        const isOpenRtbParam = openRtbParams.includes(e.target.name);
        if (isOpenRtbParam) {
            store.updateOpenRtbParam(e.target.name, value);
        } else {
            store.updateParam(e.target.name, value);
        }
    }

    private onChangeParamsProps = (param, key: string, value: string) => {
        const {store} = this.props;

        store.onChangeParamsProps(param, key, value);
    }

    private onAddUrlClick = () => {
        const {store} = this.props;
        const {addUrl} = store;

        addUrl();
    }

    private onDeleteUrlClick = (id: string) => {
        const {store} = this.props;
        const {deleteUrl} = store;

        deleteUrl(id);
    }

    private onChangeUrlsProps = (endpointsUrls: IDemandPartnerParam, key: string, value: string) => {
        const {store} = this.props;

        store.onChangeUrlsProps(endpointsUrls, key, value);
    }

    private onCopyParams = () => {
        const {store} = this.props;
        const {copyParams} = store;

        copyParams();
    }

    private onAddHardcodedCpmTagClick = () => {
        const {store} = this.props;
        const {addHardcodedCpmTag} = store;

        addHardcodedCpmTag();
    }

    private onDeleteHardcodedCpmTagClick = (id: string) => {
        const {store} = this.props;
        const {deleteHardcodedCpmTag} = store;

        deleteHardcodedCpmTag(id);
    }

    private onTogglePasteRow = () => {
        this.setState({isPasteRawOpen: !this.state.isPasteRawOpen});
    };

    private onPasteRawEnvironmentVariables = (e) => {
        const {store} = this.props;
        store.clearParams();
        const input = e.target;
        setTimeout(() => {
            const rows = JSON.parse(input.value);
            for (const row of rows) {
                const param = {_id: guid(), key: row.key, value: row.value};
                store.addToArray("params", param);
            }
            this.onTogglePasteRow();
        });
    };

    public render(): JSX.Element {
        const {store, sspTypes} = this.props;
        const {item, getStoreType} = store;

        return (
            <div className={theme.form}>
                <AggregateChangeComponent onChange={this.onChangeDetail}>
                    <div className={theme.row}>
                        <FormInput className={theme.input} name="name" label="Name" value={item.name} required/>
                    </div>
                    <div className={theme.row}>
                        <FormSelect searchable className={theme.input} name="ssp" items={sspTypes} maxItems={5}
                                    label="SSP"
                                    value={item.ssp} required/>
                    </div>
                    <div className={theme.row}>
                        <FormInput className={theme.input} name="username" label="Username" value={item.username}
                                   required/>
                    </div>
                    <div className={theme.row}>
                        <FormInput className={theme.input} name="password" label="Password"
                                   required={getStoreType() === DemandPartnerStoreType.CREATE}/>
                    </div>
                    <div className={theme.row}>
                        <FormSelect className={theme.input} name="currency" items={Object.keys(enums.Currency)}
                                    label="Currency" value={item.currency} required/>
                    </div>
                    <div className={theme.row}>
                        <FormInput className={theme.input} name="totalRevenueFormula" label="Total Revenue Formula"
                                   value={item.totalRevenueFormula} required/>
                    </div>
                    <div className={theme.row}>
                        <FormSelect label="Timezone" name="timezone" value={item.timezone} items={Timezones}
                                    maxItems={6} onChange={this.onChangeDetail}/>
                    </div>
                    <div className={theme.row}>
                        <Switch className={theme.toggle} name="isActive" onChange={this.onChangeDetail}
                                checked={item.isActive} label="Active"/>
                        <Switch className={theme.toggle} name="autoUpdateCPM" onChange={this.onChangeDetail}
                                checked={item.autoUpdateCPM} label="Auto Update CPM"/>
                        <div style={{display: "flex", alignItems: "center", marginLeft: "2.8rem"}}>
                            <Switch className={theme.toggle} name="ignoreMinImpressions" onChange={this.onChangeDetail} checked={item.ignoreMinImpressions}/>
                            <Tooltip title="This flag will update cpms even if the min imp is lower than configured on skynet task">
                                <Label uppercase={false} size={13} weight={400} style={{marginLeft: "1.2rem", marginRight: "2rem"}}>Ignore Min Impressions</Label>
                            </Tooltip>
                        </div>
                        <Switch className={theme.toggle} name="vidazooImpUpdateCPM" onChange={this.onChangeDetail}
                                checked={item.vidazooImpUpdateCPM} label="Update CPM according to Vidazoo Imp" />
                        <Switch className={theme.toggle} name="hardcodedCpm" onChange={this.onChangeDetail}
                                checked={item.hardcodedCpm} label="Hardcoded CPM" />
                    </div>
                    {item.hardcodedCpm ?
                        <div>
                            <div className={theme.header}>
                                <Label size={13} weight={700} spacing={1.25} className={theme.labelData}>Hardcoded CPM Tags</Label>
                                <div className={theme.header}>
                                    <div style={{marginRight: "10px"}}>
                                        <Switch className={theme.toggle} name="hardcodedCpmAllAccount" onChange={this.onChangeDetail}
                                                checked={item.hardcodedCpmAllAccount} label="Hardcoded all account"/>
                                    </div>
                                    <Button className={theme.button} onClick={this.onAddHardcodedCpmTagClick} small blue outlineGrayFill disabled={item.hardcodedCpmAllAccount}>Add
                                        Tag</Button>
                                </div>
                            </div>
                            <HardcodedCpmTags onDelete={this.onDeleteHardcodedCpmTagClick} onChangeProps={this.onChangeParamsProps}
                                              items={item.hardcodedCpmTags} hardcodedCpmAllAccount={item.hardcodedCpmAllAccount}/>
                        </div>
                    : null}
                </AggregateChangeComponent>
                <HLine/>
                {(item.ssp === "OpenRTB") ?
                    <AggregateChangeComponent onChange={this.onChangeDetail}>
                        <div className={theme.header}>
                            <Label size={13} weight={700} spacing={1.25}>Endpoint Structure</Label>
                        </div>
                        <div className={theme.row}>
                            <FormInput className={theme.input} name="fromParamKey" label="From Param Key"
                                       value={item.openRtbParams.fromParamKey} required/>
                        </div>
                        <div className={theme.row}>
                            <FormInput className={theme.input} name="toParamKey" label="To Param Key"
                                       value={item.openRtbParams.toParamKey} required/>
                        </div>
                        <div className={theme.header}>
                            <Label size={13} weight={700} spacing={1.25}>Json Results</Label>
                        </div>
                        <div className={theme.row}>
                            <FormInput className={theme.input} name="impressions" label="Impressions"
                                       value={item.openRtbParams.impressions} required/>
                        </div>
                        <div className={theme.row}>
                            <FormInput className={theme.input} name="revenue" label="revenue"
                                       value={item.openRtbParams.revenue} required/>
                        </div>
                        <div className={theme.row}>
                            <FormInput className={theme.input} name="placement" label="placement"
                                       value={item.openRtbParams.placement} required/>
                        </div>
                        <div className={theme.row}>
                            <FormInput className={theme.input} name="requests" label="requests"
                                       value={item.openRtbParams.requests}/>
                        </div>
                        <div className={theme.header}>
                            <Label size={13} weight={700} spacing={1.25}>Endpoints Urls
                            </Label>
                        </div>
                        <Button className={theme.button} onClick={this.onAddUrlClick} small blue outlineGrayFill>Add
                            Url</Button>
                        <UrlParams onDelete={this.onDeleteUrlClick} onChangeProps={this.onChangeUrlsProps}
                                   items={item.openRtbUrls}/>
                    </AggregateChangeComponent>
                    :
                    <div>
                        <div className={theme.header}>
                            <Label size={13} weight={700} spacing={1.25} className={theme.labelData}>Demand Partner
                                Parameters</Label>
                            <div className={theme.header}>
                                <Button className={theme.button} onClick={this.onTogglePasteRow} small blue>Paste Params</Button>
                                <Button className={theme.button} onClick={this.onCopyParams} small blue>Copy Params</Button>
                                <Button className={theme.button} onClick={this.onAddClick} small blue outlineGrayFill>Add
                                    Parameter</Button>
                            </div>
                        </div>
                        <DemandPartnerParams onDelete={this.onDeleteClick} onChangeProps={this.onChangeParamsProps}
                                             items={item.params}/>
                        {(!item.params || item.params.length) === 0 &&
                            <PageMessage
                                className={theme.message}
                                small
                                iconName="icon-table-empty"
                                title="No parameters"
                                description="Click 'Add Param' to add the first demand partner parameter"
                            />}
                        <Modal isOpen={this.state.isPasteRawOpen} onClose={this.onTogglePasteRow} external exitOnClickOutside>
                            <ConfirmPopMessage
                                green
                                title="Paste Raw Demand Partner Parameters"
                                text={<FormInput style={{width: 600}} autoFocus fill multiline={true} rows={6} onPaste={this.onPasteRawEnvironmentVariables} />}
                                cancelText="Cancel"
                                onCancelClick={this.onTogglePasteRow}
                                onConfirmClick={null}
                            />
                        </Modal>
                    </div>}
            </div>);
    }
}
