import { BaseAPI } from "@vidazoo/ui-framework";
class ProfileAPI extends BaseAPI {
    public updateProfile(data) {
        return this.request({
            method: "patch",
            url: "/api/users/me",
            data
        });
    }

    public changePassword(oldPassword: string, newPassword: string) {
        return this.request({
            method: "post",
            url: "/api/changePassword",
            data: { oldPassword, newPassword }
        });
    }
}

export default new ProfileAPI(window.__BASE_API_URL__);
