import BaseAPI from "@vidazoo/ui-framework/lib/api/BaseAPI";
import IDemandPartner from "../interfaces/IDemandPartner";

class DemandPartnersAPI extends BaseAPI {
    public getOne(id: string): Promise<any> {
        return this.request({
            method: "get",
            url: `/api/demand_partners/${id}/`
        });
    }
    public getAll(page: number = 1, page_size: number = 50, filter: any = {}, fields: any = {}, sort: any = []) {
        return this.request({
            method: "get",
            url: "/api/demand_partners/",
            params: { page, page_size, filter, fields, sort}
        });
    }

    public startMine(from: number, to: number, demandPartnersIds?: string[]) {
        return this.request({
            method: "post",
            url: `/api/demand_partners/start_miner`,
            params: { ids: demandPartnersIds, from, to }
        });
    }

    public update(id: string, data: IDemandPartner) {
        return this.request({
            method: "patch",
            url: `/api/demand_partners/${id}/`,
            data
        });
    }

    public create(data: IDemandPartner) {
        return this.request({
            method: "post",
            url: "/api/demand_partners",
            data
        });
    }

    public delete(id: string) {
        return this.request({
            method: "delete",
            url: `/api/demand_partners/${id}/`,
        });
    }

    public isActive(id: string, isActive: boolean) {
        return this.request({
            method: "patch",
            url: `/api/demand_partners/${id}/active`,
            data: {isActive}
        });
    }

    public autoUpdateCPMActive(id: string, isActive: boolean) {
        return this.request({
            method: "patch",
            url: `/api/demand_partners/${id}/auto_update_cpm`,
            data: {isActive}
        });
    }

    public vidazooImpUpdateCPM(id: string, isActive: boolean) {
        return this.request({
            method: "patch",
            url: `/api/demand_partners/${id}/vidazoo_imp_update_cpm`,
            data: {isActive}
        });
    }

    public startMineCsv(data: any) {
        return this.request({
            method: "post",
            url: `/api/demand_partners/start_miner_csv`,
            data
        });
    }
}

export default new DemandPartnersAPI(window.__BASE_API_URL__);
