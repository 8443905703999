import {action, computed, observable} from "mobx";
import {reportsAPI} from "api";
import IECPMReportEntry from "interfaces/IECPMReportEntry";
import IECPMSums from "interfaces/IECPMSums";
import {EcpmReportFilter, IntervalType, ReportType} from "../../common/enums";
import {csvBuilder, storageService} from "services";
import BaseReportStore from "./BaseReportStore";
import * as _ from "lodash";
import {multiWordFilter} from "@vidazoo/ui/lib/components/utils";

const SEARCHABLE_FIELDS = [
    "adCpm",
    "adSent",
    "adSpm",
    "adStart",
    "cpmDiffPercent",
    "impressionsDiff",
    "impressionsDiffPercent",
    "source",
    "ssp",
    "sspEcpm",
    "sspImpressions",
    "sspNetRevenue",
    "sspProvider",
    "sspRequests",
    "sspTagId",
    "sspTagName",
    "tagPartnerId",
"tagPartnerName"];

export default class ECPMSReportStore extends BaseReportStore<IECPMReportEntry> {
    @observable public timezone: string;
    private includeConstraints = false;
    private groupByTagPartnerId = false;
    @observable public intervalType: IntervalType;
    @observable public reportFilter: EcpmReportFilter;

    constructor() {
        super(SEARCHABLE_FIELDS);
        this.reset();
    }

    @action public getECPMReport = () => {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        if (this.from.unix() <= this.to.unix()) {
            reportsAPI.getECPMReport(this.from.unix(), this.to.unix(), this.includeConstraints, this.groupByTagPartnerId, this.timezone, this.intervalType)
                .then((res) => this.setReport(res.data))
                .catch((err) => this.onReportFailed());
        }
    }

    @action protected reset(sort: string = "source") {
        super.reset(sort);
        this.timezone = "Etc/GMT+0";
        this.intervalType = IntervalType.Cumulative;
        this.reportFilter = null;
    }

    @action public onTimezoneSelect = (newTimeZone: string) => {
        this.timezone = newTimeZone;
    }

    @computed public get reportSums(): IECPMSums {

        if (!this.filteredItems) {
            return;
        }

        const sums = {
            adSent: 0,
            adSpm: 0,
            adStart: 0,
            adCpm: 0,
            sspEcpm: 0,
            sspImpressions: 0,
            sspNetRevenue: 0,
            sspRequests: 0,
            netRevenueDiffPercent: 0,
            netRevenueDiff: 0,
            impressionsDiff: 0,
            impressionsDiffPercent: 0,
            cpmDiffPercent: 0
        };

        this.filteredItems.map((row) => {
            sums.adSent += row.adSent;
            sums.adSpm += row.adSpm;
            sums.adStart += row.adStart;
            sums.sspImpressions += row.sspImpressions;
            sums.sspNetRevenue += row.sspNetRevenue;
            sums.sspRequests += row.sspRequests;
        });

        sums.adCpm = sums.adStart > 0 ? sums.adSpm / (sums.adStart / 1000) : 0;
        sums.sspEcpm = sums.sspImpressions ? sums.sspNetRevenue / (sums.sspImpressions / 1000) : 0;
        sums.netRevenueDiff = sums.sspNetRevenue - sums.adSpm;
        sums.netRevenueDiffPercent =  sums.sspNetRevenue > 0 ? ((sums.netRevenueDiff / sums.sspNetRevenue) * 100) : 0;
        sums.impressionsDiff = sums.sspImpressions - sums.adStart;
        sums.impressionsDiffPercent = sums.sspImpressions > 0 ? ((sums.impressionsDiff / sums.sspImpressions) * 100) : 0;
        sums.cpmDiffPercent = sums.sspEcpm ? (((sums.sspEcpm - sums.adCpm) / sums.sspEcpm) * 100) : 0;

        return sums;
    }

    public downloadCSV = () => {
        const fileName = `moneybooker_ecpm_report_${this.from.format("DD-MM-YYYY")}__${this.to.format("DD-MM-YYYY")}.csv`;
        const reportToCsv = {report: this.filteredItems, sums: this.reportSums};
        csvBuilder.build(ReportType.ECPM_REPORT, reportToCsv, fileName, this.intervalType);
    }

    @action public onIntervalTypeSelect = (type: IntervalType) => {
        this.report = null;
        this.resetSearchQueries();
        this.intervalType = type;
    }

    public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
        const name = this.intervalType === IntervalType.Cumulative ? "ecpm_report_cumulative" : "ecpm_report_day"
        storageService.setColumnsWidth(name, columnsWidth);
    };

    public getColumnsWidth = (): { [index: string]: number } => {
        const name = this.intervalType === IntervalType.Cumulative ? "ecpm_report_cumulative" : "ecpm_report_day"
        const columnsWidth = storageService.getColumnsWidth(name);
        if (!columnsWidth) {
            const dayDefault = {0: 173, 1: 170, 2: 156, 3: 308, 4: 167, 5: 160, 6: 149, 7: 108, 8: 106, 9: 156, 10: 133, 11: 165, 12: 153, 13: 140, 14: 136, 15: 145, 16: 140, 17: 176, 18: 159, 19: 129};
            const cumulativeDefault = {0: 173, 1: 177, 2: 300, 3: 174, 4: 167, 5: 160, 6: 149, 7: 108, 8: 226, 9: 303, 10: 133, 11: 165, 12: 121, 13: 140, 14: 136, 15: 145, 16: 152, 17: 176, 18: 159};
            return this.intervalType === IntervalType.Cumulative ? cumulativeDefault : dayDefault;
        }
        return columnsWidth;
    };

    @action
    public setTotalRows(header, totalResults) {
        header.displayValue = `Total Rows: ${totalResults}`;
    }

    @computed public get filteredItems(): IECPMReportEntry[] {
        const hasQueries = _(this.searchQueries).values().compact().value().length > 0;

        if (!hasQueries && !this.reportFilter) {
            return this.report;
        }

        return _.filter(this.report, (item) => {

            const matchQueries = _.map(this.searchQueries, (query: string, field: string) => {
                // return (item[field] ? item[field].toString() : "").toLowerCase().indexOf(query.toLowerCase()) > -1;

                // null values will pass the _.get check ¯\_(ツ)_/¯
                const value = (_.get(item, field, "") || "").toString();

                return multiWordFilter(value, query) && this.onFilterByReportFilter(item);
            });

            return _.every(matchQueries);
        });
    }

    @action public onEcpmReportFilterSelect = (type: EcpmReportFilter) => {
        this.reportFilter = type;
    }

    public onFilterByReportFilter = (item: IECPMReportEntry): boolean => {
        switch (this.reportFilter) {
            case EcpmReportFilter.MISSING_BI_DATA:
                return item.missingData && !item.tagPartnerName;
            case EcpmReportFilter.MISSING_SSP_DATA:
                return item.missingData && !item.sspTagName;
            case EcpmReportFilter.CPM_ECPM_5:
                return item.cpmDiffPercent && Math.abs(item.cpmDiffPercent) > 5;
            case EcpmReportFilter.CPM_IMA_10:
                return item.imaDiffPercent && item.imaDiffPercent > 10;
            default:
                return true;
        }
    }
}
