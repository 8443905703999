import * as React from "react";
import IDemandPartnerParam from "interfaces/IDemandPartnerParam";
import DemandPartnerParam from "./demandPartnerParam";
import {guid} from "@vidazoo/ui-framework";

export interface IDemandPartnerParamsProps {
   items?: IDemandPartnerParam[];
   onDelete: (id: string) => void;
   onChangeProps?: (item: IDemandPartnerParam, name: string, value: string) => void;
}

export interface IDemandPartnerParamsState {

}

export default class DemandPartnerParams extends React.Component<IDemandPartnerParamsProps, IDemandPartnerParamsState> {

    public render(): JSX.Element {
        const {items, onDelete, onChangeProps}  = this.props;
        return (
            <div>
                { items.map((item) => <DemandPartnerParam key={guid()} item={item} onDelete={onDelete} onChangeProps={onChangeProps} />) }
            </div>
        );
    }
}
