import {BaseAPI} from "@vidazoo/ui-framework";
import {IntervalType} from "../common/enums";

class ReportsAPI extends BaseAPI {
    public getDailyReport(from: number, to: number) {
        return this.request({
            method: "get",
            url: "/api/reports/daily_report/",
            params: { from, to }
        });
    }

    public getECPMReport(from: number, to: number, includeConstraints: boolean, groupByTagPartnerId: boolean, defaultTimezone: string, intervalType: IntervalType) {
        return this.request({
            method: "get",
            url: "/api/reports/ecpm_report/",
            params: { from, to, includeConstraints, groupByTagPartnerId, defaultTimezone, intervalType }
        });
    }

    public getRawReport(from: number, to: number) {
        return this.request({
            method: "get",
            url: "/api/reports/raw_report/",
            params: { from, to }
        });
    }

    public getDiscrepancyReport(from: number, to: number, defaultTimezone: string, intervalType: IntervalType) {
        return this.request({
            method: "get",
            url: "/api/reports/discrepancy_report/",
            params: { from, to, defaultTimezone, intervalType }
        });
    }
}

export default new ReportsAPI(window.__BASE_API_URL__);
