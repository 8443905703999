import * as React from "react";
import Switch from "react-router-dom/Switch";
import Redirect from "react-router-dom/Redirect";
import { AuthRoute, AppHeader } from "components";
import {
    Accounts,
    Network,
    Networks,
    DemandPartners,
    DemandPartner,
    DailyReport,
    ECPMReport,
    RawReport,
    JobsStatus,
    Account,
    NotificationCenter,
    NotificationSettings,
    DiscrepancyReport
} from "..";
import { NotificationStack, INotification } from "@vidazoo/ui/lib/components/notificationStack";
import * as theme from "./theme.scss";
import { appUrlsService } from "services";
import { currentUserStore } from "stores";
import { Profile } from "containers";
import {SpreadPageLoader, UserRole} from "@vidazoo/ui-framework";

export interface IAppProps {

    userName?: string;

    userEmail?: string;

    userAvatarUrl?: string;

    onSignOut?: () => void;

    notifications?: INotification[];

    removeNotification?: (notification: INotification, timeout?: boolean) => void;

    openMyProfile?: () => void;

    isVidazooOrganization?: boolean;

    userId?: string;
}

export interface IAppState {

}

export default class App extends React.Component<IAppProps, IAppState> {

    public static defaultProps: Partial<IAppProps> = {

    };

    constructor(props, context) {
        super(props, context);
    }

    public render(): JSX.Element {
        const { userName, userEmail, userAvatarUrl, onSignOut, notifications, removeNotification, openMyProfile, userId, isVidazooOrganization } = this.props;

        return (
            <div className={theme.app}>
                <AppHeader
                    {...{
                        userName,
                        userEmail,
                        userAvatarUrl,
                        onSignOut,
                        openMyProfile,
                        userId,
                        isVidazooOrganization
                    }}
                    isInRoles={currentUserStore.isInRoles}
                />
                <NotificationStack
                    verticalGap={6}
                    horizontalGap={0}
                    notifications={notifications}
                    onClose={removeNotification}
                />
                <Profile />
                <div className={theme.pageContainer}>
                    <React.Suspense fallback={<SpreadPageLoader blue />}>
                        <Switch>
                            <AuthRoute key="1" path={appUrlsService.accounts()} component={Accounts} scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]} />
                            <AuthRoute key="2" path={appUrlsService.editAccount()} component={Account} scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]} />
                            <AuthRoute key="3" path={appUrlsService.demandPartners()} component={DemandPartners} scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]} />
                            <AuthRoute key="4" path={appUrlsService.demandPartner()} component={DemandPartner} scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]} />
                            <AuthRoute key="5" path={appUrlsService.rawReport()} component={RawReport} scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]} />
                            <AuthRoute key="6" path={appUrlsService.ecpmReport()} component={ECPMReport} scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]} />
                            <AuthRoute key="7" path={appUrlsService.dailyReport()} component={DailyReport} scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]} />
                            <AuthRoute key="8" path={appUrlsService.jobsStatus()} component={JobsStatus} scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]} />
                            <AuthRoute key="9" path={appUrlsService.networks()} component={Networks} scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]} />
                            <AuthRoute key="10" path={appUrlsService.network()} component={Network} scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]} />
                            <AuthRoute key="11" path={appUrlsService.notificationCenter()} component={NotificationCenter} scope={[UserRole.USER, UserRole.ADMIN, UserRole.SUPER_ADMIN]}/>
                            <AuthRoute key="12" path={appUrlsService.notificationSettings()} component={NotificationSettings} scope={[UserRole.USER, UserRole.ADMIN, UserRole.SUPER_ADMIN]}/>
                            <AuthRoute key="6" path={appUrlsService.discrepancyReport()} component={DiscrepancyReport} scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]} />
                            <Redirect to={appUrlsService.dailyReport()} />
                        </Switch>
                    </React.Suspense>
                </div>
            </div>
        );
    }
}
