import * as React from "react";
import IDemandPartnerParam from "interfaces/IDemandPartnerParam";
import DemandPartnerUrlParam from "./demandPartnerUrlParam";
import {guid} from "@vidazoo/ui-framework";

export interface IDemandPartnerUrlParamsProps {
   items?: IDemandPartnerParam[];
   onDelete: (id: string) => void;
   onChangeProps?: (item: IDemandPartnerParam, name: string, value: string) => void;
}

export interface IDemandPartnerUrlParamsState {

}

export default class UrlParams extends React.Component<IDemandPartnerUrlParamsProps, IDemandPartnerUrlParamsState> {

    public render(): JSX.Element {
        const {items, onDelete, onChangeProps}  = this.props;
        return (
            <div>
                { items.map((item) => <DemandPartnerUrlParam key={guid()} item={item} onDelete={onDelete} onChangeProps={onChangeProps} />) }
            </div>
        );
    }
}
