import * as React from "react";
import {Modal} from "@vidazoo/ui";

export interface IModalDeleteProps {
    error: any;
    isOpen: boolean;
    onDone: () => void;
}

export default ({error, isOpen, onDone}: IModalDeleteProps) => (
    <Modal title="Error" exitOnClickOutside isOpen={isOpen} onClose={onDone} panel>
        <p>{error}</p>
    </Modal>
);
