import { observable, action, computed, transaction } from "mobx";
import * as _ from "lodash";
import { BaseListStore } from "@vidazoo/ui-framework";
import { accountsAPI } from "api";
import IAccount from "interfaces/IAccount";
import { INetwork } from "interfaces/INetwork";
import { notificationsStore } from "..";

const ACCOUNTS_SEARCHABLE_FIELDS = ["username"];

const ACCOUNTS_LIST_FIELDS = { username: 1, created: 1, isActive: 1 };

export default class AccountsStore extends BaseListStore<IAccount, any, string>  {
    constructor() {
        super(notificationsStore, ACCOUNTS_SEARCHABLE_FIELDS, "account", "username");
    }

    @action public getItems = () => {
        if (this.isLoading) {
            return;
        }

        transaction(() => {
            this.isLoading = true;
            this.resetSearchQueries();
            this.setPromptDeleteItem(null);
        });

        const sort = {
            [this.sortBy]: this.sortDir
        };

        accountsAPI.getAll(null, null, {}, ACCOUNTS_LIST_FIELDS, sort)
            .then((res) => this.onLoadItemsSuccess(res))
            .catch((res) => this.onLoadItemsError(res));
    }

    @action public toggleActiveState = (item: IAccount) => {
        item.isActive = !item.isActive;

        accountsAPI.active(item._id, item.isActive)
            .then(() => this.onUpdateStateSuccess())
            .catch(() => this.onUpdateStateError(item));
    }

    @action protected deleteItem = () => {
        accountsAPI.delete(this.promptDeleteItem._id)
            .then(() => this.onDeleteItemSuccess(this.promptDeleteItem._id))
            .catch(() => this.onDeleteItemError());
    }
}
