import JobStatus from "./JobsStatus";
import {inject, observer} from "mobx-react";

export default inject((stores: any, props: any, context: any) => ({
    jobs: stores.jobsStatusStore.filteredItems,
    getJobs: stores.jobsStatusStore.getJobs,
    sortBy: stores.jobsStatusStore.sortBy,
    onSort: stores.jobsStatusStore.sort,
    sortDirection: stores.jobsStatusStore.sortDirection,
    searchQueries: stores.jobsStatusStore.searchQueries,
    onSearch: stores.jobsStatusStore.setSearchQuery,
    cancelJob: stores.jobsStatusStore.cancelJob,
    isLoading: stores.jobsStatusStore.isLoading,
    forceStartJob: stores.jobsStatusStore.forceStartJob,
    from: stores.jobsStatusStore.from,
    to: stores.jobsStatusStore.to,
    minDate: stores.jobsStatusStore.minDate,
    maxDate: stores.jobsStatusStore.maxDate,
    onFromSelect: stores.jobsStatusStore.onFromDateSelect,
    onToSelect: stores.jobsStatusStore.onToDateSelect,
    setColumnsWidth: stores.jobsStatusStore.setColumnsWidth,
    getColumnsWidth: stores.jobsStatusStore.getColumnsWidth,
}))(observer(JobStatus));
