import * as React from "react";
import {IBreadcrumb} from "@vidazoo/ui/lib/components/breadcrumbs";
import {appUrlsService} from "../../../services";
import {Breadcrumbs, Button, Form, PageContainer, Surface, Tab, TabsPanel} from "@vidazoo/ui";
import DetailsForm from "../components/detailsForm";
import * as theme from "styles/_baseForm.scss";
import CurrentUserStore from "../../../stores/CurrentUserStore";
import CreateDemandPartnerStore from "stores/demandPartners/CreateDemandPartnerStore";
import {RouteComponentProps} from "react-router";
import * as _ from "lodash";

export interface ICreateDemandPartnerProps extends RouteComponentProps<{ flag?: string }> {
    createDemandPartnerStore?: CreateDemandPartnerStore;
    currentUserStore?: CurrentUserStore;
}

export interface ICreateDemandPartnerState {

}

export default class CreateDemandPartner extends React.Component<ICreateDemandPartnerProps, ICreateDemandPartnerState> {
    public componentWillMount() {
        const {createDemandPartnerStore, location} = this.props;

        if (!_.get(location, "query.duplicate")) {
            createDemandPartnerStore.reset();
        }
    }

    private getTitle() {
        const {createDemandPartnerStore} = this.props;
        return createDemandPartnerStore.item.name;
    }

    public renderBreadCrumbs(): IBreadcrumb[] {
        return [
            {
                label: "Demand Partners",
                iconName: "icon-demand-partners",
                link: {to: appUrlsService.demandPartners()}
            },
            {
                label: "Create"
            },
            {
                label: this.getTitle(),
                primary: true
            }
        ];
    }

    private onSubmit = () => {
        const {createDemandPartnerStore} = this.props;

        createDemandPartnerStore.submit();
    }

    private onCancelClick = () => {
        const {history} = this.props;

        history.push(appUrlsService.demandPartners());
    }

    public render(): JSX.Element {
        const {createDemandPartnerStore, currentUserStore} = this.props;
        const {sspTypes} = currentUserStore;
        return (
            <PageContainer inner top={1.2}>
                <Breadcrumbs className={theme.breadcrumbs} breadcrumbs={this.renderBreadCrumbs()}/>
                <Form className={theme.form} onSubmit={this.onSubmit}>
                    <TabsPanel className={theme.midContent}>
                        <Tab label="Create">
                            <Surface className={theme.surface} level={1}>
                                <DetailsForm store={createDemandPartnerStore} sspTypes={sspTypes}/>
                            </Surface>
                        </Tab>
                    </TabsPanel>
                    <div className={theme.actions}>
                        <Button className={theme.actions} onClick={this.onCancelClick} type="button" medium
                                gray>Cancel</Button>
                        <Button className={theme.actions} type="submit" medium blue>Create Partner</Button>
                    </div>
                </Form>
            </PageContainer>
        );
    }
}
