import {action} from "mobx";
import {navigationStore} from "../index";
import BaseDemandPartnerStore from "./BaseDemandPartnerStore";
import demandPartnersAPI from "../../api/demandPartnersAPI";
import {guid} from "@vidazoo/ui-framework";
import {appUrlsService} from "../../services";
import {DemandPartnerStoreType} from "common/enums";

export default class CreateDemandPartnerStore extends BaseDemandPartnerStore {
    constructor() {
        super(DemandPartnerStoreType.CREATE);
    }

    @action public submit() {
        const item = this.submitify(); // TODO: should changed

        demandPartnersAPI.create(item)
            .then((res) => navigationStore.push(appUrlsService.demandPartners()))
            .then(() => this.onSubmitSuccess())
            .catch((err) => this.onSubmitFailed(err));

        this.item.params.forEach((param) => (param._id = guid()));
    }
}
