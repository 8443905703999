import * as React from "react";
import * as PropTypes from "prop-types";
import { History } from "history";
import { Router, Switch } from "react-router-dom";
import { App } from "containers";
import { AuthRoute } from "components";
import { PageMessage } from "@vidazoo/ui";
import { SpreadPageLoader, ScrollToTop, PendingNewVersion } from "@vidazoo/ui-framework";
import { appUrlsService } from "services";

export interface IRootProps {
    isAuthenticating?: boolean;

    isAuthError?: boolean;

    history?: History;

    pendingNewVersion?: boolean;

    setPendingNewVersionState?: (state: boolean) => void;
}

export interface IRootState {

}

export default class Root extends React.Component<IRootProps, IRootState> {

    public static defaultProps: Partial<IRootProps> = {

    };

    constructor(props, context) {
        super(props, context);
    }

    private onDismissPendingNewVersion = () => {
        const { setPendingNewVersionState } = this.props;

        setPendingNewVersionState(false);
    }

    private onConfirmPendingNewVersion = () => {

        this.onDismissPendingNewVersion();

        window.location.reload();
    }

    public render(): JSX.Element {
        const { isAuthenticating, isAuthError, history, pendingNewVersion } = this.props;

        if (isAuthError) {
            return (
                <div className="flex flex-1 align-center justify-center">
                    <PageMessage
                        small
                        title="Oops! something went wrong."
                        description="We had an error loading your data. Please try again."
                        iconName="icon-vidazoo-triangle"
                    />
                </div>
            );
        }

        if (isAuthenticating) {
            return (
                <SpreadPageLoader blue />
            );
        }

        return (
            <React.Fragment>
                <PendingNewVersion onDismiss={this.onDismissPendingNewVersion} onConfirm={this.onConfirmPendingNewVersion} pendingNewVersion={pendingNewVersion} />
                <Router history={history}>
                    <React.Fragment>
                        <ScrollToTop />
                        <Switch>
                            <AuthRoute path={appUrlsService.app()} component={App} />
                        </Switch>
                    </React.Fragment>
                </Router>
            </React.Fragment>
        );
    }
}
