import * as React from "react";
import { IDictionary, guid } from "@vidazoo/ui-framework";
import { SortDirection, Table, TableHeaderColumn, TableBody, Surface, NativeScrollable } from "@vidazoo/ui";
import RawReportRow from "./components/rawReportRow/RawReportRow";
import {IRawReportEntry} from "interfaces/IRawReportEntry";

export interface IListProps {
    items?: IRawReportEntry[];
    searchQueries: IDictionary<string>;
    sortBy?: string;
    sortDirection: SortDirection;
    onSort: (sortBy: string) => void;
    onSearch?: (searchKey: string, value: string) => void;
}

export default ({ items, sortBy, onSort, sortDirection, searchQueries, onSearch }: IListProps): JSX.Element => (
    <Surface level={1}>
        <NativeScrollable scrollSize={6} suppressY stickyOverflowScrollX bold>
            <Table stickyHeaderOffsetTop={80} enableStickyHeader={true} fill  >
                <thead>
                <tr>
                    <TableHeaderColumn
                        blue
                        label="SSP"
                        sortable={true} // TODO: is sortable has any effect?
                        sortBy="ssp"
                        onSort={onSort}
                        sortActive={sortBy === "ssp"}
                        sortDirection={sortDirection}
                        searchKey="ssp"
                        searchable={true}
                        onSearch={onSearch}
                        searchQuery={searchQueries["ssp"]}
                    />
                    <TableHeaderColumn
                        blue
                        label="Provider"
                        sortable={true}
                        sortBy="provider"
                        onSort={onSort}
                        sortActive={sortBy === "provider"}
                        sortDirection={sortDirection}
                        searchKey="provider"
                        searchable={true}
                        onSearch={onSearch}
                        searchQuery={searchQueries["provider"]}
                    />
                    <TableHeaderColumn
                        blue
                        label="Date"
                        sortable={true}
                        sortBy="date"
                        onSort={onSort}
                        sortActive={sortBy === "date"}
                        sortDirection={sortDirection}
                    />
                    <TableHeaderColumn
                        blue
                        label="Tag"
                        sortable={true}
                        sortBy="tag"
                        onSort={onSort}
                        sortActive={sortBy === "tag"}
                        sortDirection={sortDirection}
                        searchKey="tag"
                        searchable={true}
                        onSearch={onSearch}
                        searchQuery={searchQueries["tag"]}
                    />
                    <TableHeaderColumn
                        blue
                        label="Impressions"
                        sortable={true}
                        sortBy="impressions"
                        onSort={onSort}
                        sortActive={sortBy === "impressions"}
                        sortDirection={sortDirection}
                    />
                    <TableHeaderColumn
                        blue
                        label="Requests"
                        sortable={true}
                        sortBy="requests"
                        onSort={onSort}
                        sortActive={sortBy === "requests"}
                        sortDirection={sortDirection}
                    />
                    <TableHeaderColumn
                        blue
                        label="Revenue"
                        sortable={true}
                        sortBy="revenue"
                        onSort={onSort}
                        sortActive={sortBy === "revenue"}
                        sortDirection={sortDirection}
                    />
                    <TableHeaderColumn
                        blue
                        label="Updated"
                        sortable={true}
                        sortBy="updated"
                        onSort={onSort}
                        sortActive={sortBy === "updated"}
                        sortDirection={sortDirection}
                        searchKey="createDate"
                    />
                </tr>
                </thead>
                <TableBody itemHeight={47}>{items.map((item) =>  <RawReportRow item={item} key={guid()} />)}</TableBody>
            </Table>
        </NativeScrollable>
    </Surface>
);
