import {action, computed, observable} from "mobx";
import {reportsAPI} from "api";
import IECPMReportEntry from "interfaces/IECPMReportEntry";
import {IntervalType, ReportType} from "../../common/enums";
import {csvBuilder} from "services";
import BaseReportStore from "./BaseReportStore";
import IDiscrepancySums from "../../interfaces/IDiscrepancySums";

const SEARCHABLE_FIELDS = [
    "adSent",
    "adSpm",
    "adStart",
    "impressionsDiff",
    "impressionsDiffPercent",
    "source",
    "ssp",
    "sspImpressions",
    "sspNetRevenue",
    "sspProvider",
    "sspRequests",
    "sspTagId",
    "sspTagName",
];

export default class DiscrepancyReportStore extends BaseReportStore<IECPMReportEntry> {
    @observable public timezone: string;
    @observable public intervalType: IntervalType;

    constructor() {
        super(SEARCHABLE_FIELDS);
        this.reset();
    }

    @action public getDiscrepancyReport = () => {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        if (this.from.unix() <= this.to.unix()) {
            reportsAPI.getDiscrepancyReport(this.from.unix(), this.to.unix(), this.timezone, this.intervalType)
                .then((res) => this.setReport(res.data))
                .catch((err) => this.onReportFailed());
        }
    }

    @action protected reset(sort: string = "source") {
        super.reset(sort);
        this.timezone = "Etc/GMT+0";
        this.intervalType = IntervalType.Cumulative;
    }

    @action public onTimezoneSelect = (newTimeZone: string) => {
        this.timezone = newTimeZone;
    }

    @computed public get reportSums(): IDiscrepancySums {

        if (!this.filteredItems) {
            return;
        }

        const sums = {
            adSent: 0,
            adSpm: 0,
            adStart: 0,
            sspImpressions: 0,
            sspNetRevenue: 0,
            sspRequests: 0,
            netRevenueDiffPercent: 0,
            netRevenueDiff: 0,
            impressionsDiff: 0,
            impressionsDiffPercent: 0,
        };

        this.filteredItems.map((row) => {
            sums.adSent += row.adSent;
            sums.adSpm += row.adSpm;
            sums.adStart += row.adStart;
            sums.sspImpressions += row.sspImpressions;
            sums.sspNetRevenue += row.sspNetRevenue;
            sums.sspRequests += row.sspRequests;
        });

        sums.netRevenueDiff = sums.sspNetRevenue - sums.adSpm;
        sums.netRevenueDiffPercent =  sums.sspNetRevenue > 0 ? ((sums.netRevenueDiff / sums.sspNetRevenue) * 100) : 0;
        sums.impressionsDiff = sums.sspImpressions - sums.adStart;
        sums.impressionsDiffPercent = sums.sspImpressions > 0 ? ((sums.impressionsDiff / sums.sspImpressions) * 100) : 0;

        return sums;
    }

    public downloadCSV = () => {
        const fileName = `moneybooker_discrepancy_report_${this.from.format("DD-MM-YYYY")}__${this.to.format("DD-MM-YYYY")}.csv`;
        const reportToCsv = {report: this.report, sums: this.reportSums};
        csvBuilder.build(ReportType.DISCREPANCY_REPORT, reportToCsv, fileName, this.intervalType);
    }

    @action public onIntervalTypeSelect = (type: IntervalType) => {
       this.report = null;
       this.resetSearchQueries();
       this.intervalType = type;
    }
}
