import { observable, action, computed, transaction } from "mobx";
import * as _ from "lodash";
import IAccount from "interfaces/IAccount";
import { accountsAPI } from "api";
import { appUrlsService } from "services";
import { navigationStore } from "..";
import { IBaseItemStore } from "@vidazoo/ui-framework/lib/stores/BaseItemStore";
import { BaseAccountStore } from "./BaseAccountStore";

export interface IAccountStore extends IBaseItemStore<IAccount> {
    getItem: (id: string) => void;
    submit: () => void;
    delete: () => void;
}

export default class AccountStore extends BaseAccountStore implements IAccountStore {

    @action public getItem(id: string) {
        this.isLoading = true;

        accountsAPI.getOne(id)
            .then((item) => this.setItem(item.data))
            .catch((err) => this.onLoadFailed(err));
    }

    @action public submit() {
        accountsAPI.update(this.item._id, this.item)
            .then(() => this.onSubmitSuccess())
            .catch((err) => this.onSubmitFailed(err));
    }

    @action public delete() {
        accountsAPI.delete(this.item._id)
            .then(() => this.onSubmitSuccess())
            .then(() => navigationStore.push(appUrlsService.accounts()))
            .catch((err) => this.onSubmitFailed(err));
    }
}
