import * as React from "react";
import {Modal, Table} from "@vidazoo/ui";
import {IJobReportInfo} from "interfaces/IJobReportInfo";
import * as theme from "./theme.scss";
import * as moment from "moment";
import * as numeral from "numeral";

export interface IModalReportProps {
    items: IJobReportInfo[];
    isOpen: boolean;
    onDone: () => void;
    demandPartnerName: string;
}

export default ({items, isOpen, onDone, demandPartnerName}: IModalReportProps) => (
    <Modal title={`Report - ${demandPartnerName}`} exitOnClickOutside isOpen={isOpen} onClose={onDone} panel windowClass={theme.windowStyle}
           scrollableContent>
        <Table stickyHeaderOffsetTop={1} enableStickyHeader={true}>
            <thead>
            <tr>
                <th>Tag</th>
                <th>Revenue</th>
                <th>Impressions</th>
                <th>Requests</th>
                <th>Gross Revenue</th>
                <th>Date</th>
                <th>SSP</th>
                <th>Timezone</th>
                <th>Tag ID</th>
                <th>Net Revenue</th>
            </tr>
            </thead>
            <tbody>
            {items.map((item) =>
                <tr>
                    <td>{item.tag}</td>
                    <td>{numeral(item.revenue).format("$0,0.00")}</td>
                    <td>{item.impressions}</td>
                    <td>{item.requests}</td>
                    <td>{numeral(item.gross_revenue).format("$0,0.00")}</td>
                    {item.date ? <td>{moment.unix(item.date).format("DD/MM/YYYY")}</td> : <td/>}
                    {item.ssp ? <td>{item.ssp}</td> : <td/>}
                    {item.timezone ? <td>{item.timezone}</td> : <td/>}
                    {item.provider ? <td>{item.provider}</td> : <td/>}
                    {item.tag_id ? <td>{item.tag_id}</td> : <td/>}
                </tr>
            )}
            </tbody>
        </Table>
    </Modal>
);
