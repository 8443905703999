import DiscrepancyReport from "./DiscrepancyReport";
import {inject, observer} from "mobx-react";

export default inject((stores: any, props: any, context: any): any => ({
    report: stores.discrepancyReportStore.filteredItems,
    getReport: stores.discrepancyReportStore.getDiscrepancyReport,
    sortBy: stores.discrepancyReportStore.sortBy,
    onSort: stores.discrepancyReportStore.sort,
    sums: stores.discrepancyReportStore.reportSums,
    sortDirection: stores.discrepancyReportStore.sortDirection,
    searchQueries: stores.discrepancyReportStore.searchQueries,
    onSearch: stores.discrepancyReportStore.setSearchQuery,
    isLoading: stores.discrepancyReportStore.isLoading,
    from: stores.discrepancyReportStore.from,
    to: stores.discrepancyReportStore.to,
    onFromSelect: stores.discrepancyReportStore.onFromDateSelect,
    onToSelect: stores.discrepancyReportStore.onToDateSelect,
    onTimeZoneSelect: stores.discrepancyReportStore.onTimezoneSelect,
    timezone: stores.discrepancyReportStore.timezone,
    download: stores.discrepancyReportStore.downloadCSV,
    presetTime: stores.discrepancyReportStore.preset,
    onPresetTimeChange: stores.discrepancyReportStore.onPresetTimeChange,
    intervalType: stores.discrepancyReportStore.intervalType,
    onIntervalTypeSelect: stores.discrepancyReportStore.onIntervalTypeSelect
}))(observer(DiscrepancyReport));
