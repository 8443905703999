import {ILabelValuePair} from "@vidazoo/ui-framework";

export enum Currency {
    USD = "USD",
    EUR = "EUR",
    ILS = "ILS",
    RUB = "RUB",
    CAD = "CAD",
    GBP = "GBP"
}

export enum Timezone {
    "GMT+12" = "Etc/GMT-12",
    "GMT+11" = "Etc/GMT-11",
    "GMT+10" = "Etc/GMT-10",
    "GMT+9" = "Etc/GMT-9",
    "GMT+8" = "Etc/GMT-8",
    "GMT+7" = "Etc/GMT-7",
    "GMT+6" = "Etc/GMT-6",
    "GMT+5" = "Etc/GMT-5",
    "GMT+4" = "Etc/GMT-4",
    "GMT+3" = "Etc/GMT-3",
    "GMT+2" = "Etc/GMT-2",
    "GMT+1" = "Etc/GMT-1",
    "GMT-1" = "Etc/GMT+1",
    "GMT-2" = "Etc/GMT+2",
    "GMT-3" = "Etc/GMT+3",
    "GMT-4" = "Etc/GMT+4",
    "GMT-5" = "Etc/GMT+5",
    "GMT-6" = "Etc/GMT+6",
    "GMT-7" = "Etc/GMT+7",
    "GMT-8" = "Etc/GMT+8",
    "GMT-9" = "Etc/GMT+9",
    "GMT-10" = "Etc/GMT+10",
    "GMT-11" = "Etc/GMT+11",
    "GMT-12" = "Etc/GMT+12",
    "UTC - Greenwich Mean Time" = "Etc/GMT+0",
    "EST - New York" = "America/New_York",
    "PST - Los Angeles" = "America/Los_Angeles"
}

export const Timezones: ILabelValuePair[] = [
    {value: Timezone["GMT+12"], label: "GMT+12"},
    {value: Timezone["GMT+11"], label: "GMT+11"},
    {value: Timezone["GMT+10"], label: "GMT+10"},
    {value: Timezone["GMT+9"], label: "GMT+9"},
    {value: Timezone["GMT+8"], label: "GMT+8"},
    {value: Timezone["GMT+7"], label: "GMT+7"},
    {value: Timezone["GMT+6"], label: "GMT+6"},
    {value: Timezone["GMT+5"], label: "GMT+5"},
    {value: Timezone["GMT+4"], label: "GMT+4"},
    {value: Timezone["GMT+3"], label: "GMT+3"},
    {value: Timezone["GMT+2"], label: "GMT+2"},
    {value: Timezone["GMT+1"], label: "GMT+1"},
    {value: Timezone["GMT-1"], label: "GMT-1"},
    {value: Timezone["GMT-2"], label: "GMT-2"},
    {value: Timezone["GMT-3"], label: "GMT-3"},
    {value: Timezone["GMT-4"], label: "GMT-4"},
    {value: Timezone["GMT-5"], label: "GMT-5"},
    {value: Timezone["GMT-6"], label: "GMT-6"},
    {value: Timezone["GMT-7"], label: "GMT-7"},
    {value: Timezone["GMT-8"], label: "GMT-8"},
    {value: Timezone["GMT-9"], label: "GMT-9"},
    {value: Timezone["GMT-10"], label: "GMT-10"},
    {value: Timezone["GMT-11"], label: "GMT-11"},
    {value: Timezone["GMT-12"], label: "GMT-12"},
    {value: Timezone["UTC - Greenwich Mean Time"], label: "UTC - Greenwich Mean Time"},
    {value: Timezone["EST - New York"], label: "EST - New York"},
    {value: Timezone["PST - Los Angeles"], label: "PST - Los Angeles"},
];

export enum DailyReportField {
    "provider" = "Name",
    "requests" = "Requests",
    "impressions" = "Impressions",
    "netRevenue" = "Revenue",
    "fillRate" = "Fill Rate",
    "ecpm" = "ECPM"
}

export enum ReportType {
    "RAW_REPORT",
    "DAILY_REPORT",
    "ECPM_REPORT",
    "DISCREPANCY_REPORT",
}

export enum DemandPartnerStoreType {
    EDIT,
    CREATE,
    BASE
}

export enum TimeRange {
    TODAY = "today",
    YESTERDAY = "yesterday",
    LAST_7_DAYS = "last7days",
    WEEK_TO_DATE = "weekToDate",
    LAST_MONTH = "lastMonth",
    MONTH_TO_DATE = "monthToDate",
    CUSTOM = "custom"
}

export enum JobStatus {
    "pending",
    "started",
    "completed",
    "failed",
    "cancelled"
}

export enum IntervalType {
    Cumulative = "cumulative",
    Day = "day"
}
export const IntervalTypes: ILabelValuePair[] = [
    {label: "Cumulative", value: IntervalType.Cumulative},
    {label: "Day", value: IntervalType.Day}
]

export enum EcpmReportFilter {
    MISSING_BI_DATA = "missingBiData",
    MISSING_SSP_DATA = "missingSspData",
    CPM_ECPM_5 = "cpmEcpm5",
    CPM_IMA_10 = "cpmIma10",
}

export const EcpmReportFilters: ILabelValuePair[] = [
    {label: "Missing BI Data", value: EcpmReportFilter.MISSING_BI_DATA},
    {label: "Missing SSP Data", value: EcpmReportFilter.MISSING_SSP_DATA},
    {label: "ECPM diff > 5%", value: EcpmReportFilter.CPM_ECPM_5},
    {label: "IMA diff > 10%", value: EcpmReportFilter.CPM_IMA_10},
    {label: "Empty Filter", value: null},
]
