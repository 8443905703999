import * as React from "react";
import {ThemeProvider} from "@vidazoo/ui";
import * as theme from "./theme.scss";

export default (props): JSX.Element => {
    return (
        <ThemeProvider {...props} theme={
            {
                RTDateTimeRangePicker: {
                    "date-time-range-picker": theme["date-time-range-picker"],
                    "all-day-checkbox": theme["all-day-checkbox"],
                }
            }
        }
        />
    );
};
