import * as React from "react";
import * as PropTypes from "prop-types";
import NetworksStore from "stores/network/NetworksStore";
import { ISelectProps } from "@vidazoo/ui/lib/components/select";
import { FormSelect } from "@vidazoo/ui";

export interface INetworkSelectProps extends ISelectProps {

    networksStore?: NetworksStore;

    value?: string;

    onChange?: (e) => void;

}

export interface INetworkSelectState {

}

export default class NetworkSelect extends React.Component<INetworkSelectProps, INetworkSelectState> {

    public static defaultProps: Partial<INetworkSelectProps> = {

    };

    constructor(props, context) {
        super(props, context);
    }

    public componentWillMount() {
        const { networksStore } = this.props;

        networksStore.getItems();
    }

    public render(): JSX.Element {
        const { networksStore, value, onChange, ...others } = this.props;

        return (
            <FormSelect
                {...others as any}
                isLoading={networksStore.isLoading}
                items={networksStore.items.map((x) => ({ label: x.name, value: x._id }))}
                value={value}
                onChange={onChange}
                maxItems={5}
                fill
                required
                name="network"
                label="Network"
            />
        );
    }
}
