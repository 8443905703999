import { guid } from "@vidazoo/ui-framework/lib/utils";
import * as moment from "moment";
import {TimeRange} from "./enums";
import {Moment} from "moment";

export function serialize(obj) {
    const str = [];

    for (const p in obj) {
        if (obj.hasOwnProperty(p)) {
            str.push(p + "=" + obj[p]);
        }
    }

    return str.join("&");
}

export function moveElement(arr, oldIndex, newIndex) {
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
}

const getUrlParams = (url = "", addParamToCollection) => {
    const queryLocation = url.indexOf("?");

    if (queryLocation > -1) {
        const queryString = url.slice(queryLocation + 1, url.length);
        const parts = queryString.split("&");

        for (let i = 0, len = parts.length; i < len; i++) {
            const pair = parts[i].split("=");
            addParamToCollection(pair);
        }
    }
};

export const getUrlParamsAsArray = (url) => {
    const res = [];
    getUrlParams(url, ([key, value]) => res.push({ key, value }));
    return res;
};

export const getUrlParamsAsDictionary = (url) => {
    const res = {};
    getUrlParams(url, ([key, value]) => res[key] = value);
    return res;
};

export const toFixed = (number, precision) => {
    number = parseFloat(parseFloat(number).toPrecision(12));

    if (precision) {
        number = parseFloat(number.toFixed(precision));
    }

    return number;
};

export const formatDuration = (seconds: number = 0) => {
    return seconds < 3600 ? new Date(seconds * 1000).toISOString().substr(14, 5) : new Date(seconds * 1000).toISOString().substr(11, 8);
};

export function multiWordRegex(expected: string) {
    let reg = "";
    expected.split(" ").forEach((v) => {
        v = v.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
        reg += "(?=.*" + v + ")";
    });

    return reg;
}

export function generatePlayerIterationCode(startIteration) {
    const id = guid();

    return `/*__vdzcws__*/
        window.__vdz_hook_${id}_start_iteration = ${startIteration};
        if(!window.__vdz_hook_${id}_current_iteration) {
            window.__vdz_hook_${id}_current_iteration = 0;
        }
        if(++window.__vdz_hook_${id}_current_iteration < window.__vdz_hook_${id}_start_iteration) {
            return;
        }
        /*__vdzcwe__*/`;
}

export const getDatesRange = (startDate, stopDate) => {
    const dateArray = [];
    let currentDate = moment.utc(startDate, "X").startOf("day");
    stopDate = moment.utc(stopDate, "X").startOf("day");

    while (currentDate <= stopDate) {
        dateArray.push(currentDate.unix());
        currentDate = currentDate.add(1, "days");
    }

    return dateArray;
};

export const getObjectValues = (object: any): any[] => {
    return Object.keys(object).map((key) => object[key]);
};

export const getKeyByValue = (object: any, value: any): any => {
    return Object.keys(object).find((key) => object[key] === value);
};

export const download = (content, fileName, mimeType?) => {
    const a = document.createElement("a");
    mimeType = mimeType || "application/octet-stream";

    // @ts-ignore
    if (navigator.msSaveBlob) { // IE10
        // @ts-ignore
        return navigator.msSaveBlob(new Blob([content], {type: mimeType}), fileName);
    } else if ("download" in a) { // html5 A[download]
        a.href = "data:" + mimeType + "," + encodeURIComponent(content);
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        setTimeout(() => {
            a.click();
            document.body.removeChild(a);
        }, 66);
        return true;
    } else { // do iframe dataURL download (old ch+FF):
        const f = document.createElement("iframe");
        document.body.appendChild(f);
        f.src = "data:" + mimeType + "," + encodeURIComponent(content);

        setTimeout(() => {
            document.body.removeChild(f);
        }, 333);
        return true;
    }
};

export function getTimeByRange(time): {from: Moment, to: Moment} {
    let from;
    let to;
    switch (time) {
        case TimeRange.TODAY:
            from = moment.utc().startOf("day");
            to = moment.utc().endOf("day");
            break;
        case TimeRange.YESTERDAY:
            from =  moment.utc().startOf("day").subtract(1, "day");
            to = moment.utc().endOf("day").subtract(1, "day");
            break;
        case TimeRange.LAST_7_DAYS:
            from =  moment.utc().startOf("day").subtract(7, "day");
            to = moment.utc().endOf("day");
            break;
        case TimeRange.WEEK_TO_DATE:
            from =  moment.utc().startOf("week");
            to = moment.utc().endOf("day");
            break;
        case TimeRange.LAST_MONTH:
            from = moment.utc().startOf("month").subtract(1, "month");
            to = moment.utc().subtract(1, "month").endOf("month");
            break;
        case TimeRange.MONTH_TO_DATE:
            from =  moment.utc().startOf("month");
            to = moment.utc().endOf("day");
            break;
    }

    return {from, to};
}
