import { BaseAPI } from "@vidazoo/ui-framework";

class NetworksAPI extends BaseAPI {

    public getAll(page: number = 1, page_size: number = 50, filter: any = {}, fields: any = {}, sort: any = []) {
        return this.request({
            method: "get",
            url: "/api/networks/",
            params: { page, page_size, filter, fields, sort }
        });
    }

    public getOne(id: string) {
        return this.request({
            method: "get",
            url: `/api/networks/${id}/`
        });
    }

    public create(data: any) {
        return this.request({
            method: "post",
            url: "/api/networks/",
            data
        });
    }

    public delete(id: string) {
        return this.request({
            method: "delete",
            url: `/api/users/${id}/`
        });
    }

    public update(id: string, data: any) {
        return this.request({
            method: "patch",
            url: `/api/networks/${id}/`,
            data
        });
    }

    public active(id: string, isActive: boolean) {
        return this.request({
            method: "patch",
            url: `/api/networks/${id}/active/`,
            data: { isActive }
        });
    }
}

export default new NetworksAPI(window.__BASE_API_URL__, null, "networksForTargeting");
