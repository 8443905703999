import * as React from "react";
import {TableCell, Switch, LinkButton, TableCellToggle, Checkbox, Button} from "@vidazoo/ui";
import * as moment from "moment";
import * as numeral from "numeral";
import * as theme from "./theme.scss";
import {IRawReportEntry} from "interfaces/IRawReportEntry";

export interface IRawReportRowProps {
    item?: IRawReportEntry;
}

export interface IRawReportRowState {

}

export default class RawReportRow extends React.Component<IRawReportRowProps, IRawReportRowState> {

    public render(): JSX.Element {

        const {item} = this.props;

        return (
            <React.Fragment>
                <tr className={theme.cell}>
                    <TableCell>{ item.ssp }</TableCell>
                    <TableCell maxWidth={200}>{ item.provider }</TableCell>
                    <TableCell>{ moment.utc(item.date, "X").format("DD/MM/YYYY")}</TableCell>
                    <TableCell maxWidth={200}>{ item.tag }</TableCell>
                    <TableCell>{ numeral(item.impressions).format("0,0") }</TableCell>
                    <TableCell>{ numeral(item.requests).format("0,0") }</TableCell>
                    <TableCell>{ numeral(item.revenue).format("$0,0.0000") }</TableCell>
                    <TableCell>{ moment.utc(item.updated).local().format("DD/MM/YYYY HH:mm")}</TableCell>
                </tr>
            </React.Fragment>
        );
    }
}
