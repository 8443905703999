import BaseListStore from "@vidazoo/ui-framework/lib/stores/BaseListStore";
import IDemandPartner from "../../interfaces/IDemandPartner";
import IUser from "../../interfaces/IUser";
import {INetwork} from "../../interfaces/INetwork";
import {action, observable} from "mobx";
import demandPartnersAPI from "../../api/demandPartnersAPI";
import {notificationsStore} from "..";
import {Moment} from "moment";
import * as papaparse from "papaparse";
import {storageService} from "../../services";

const DEMAND_PARTNERS_SEARCHABLE_FIELDS = ["name", "ssp", "username", "isActive", "autoUpdatedCPM", "createdDate"];

const DEMAND_PARTNERS_LIST_FIELDS = {
    name: 1,
    ssp: 1,
    username: 1,
    isActive: 1,
    autoUpdateCPM: 1,
    created: 1,
    timezone: 1,
    totalRevenueFormula: 1,
    vidazooImpUpdateCPM: 1
};

export default class DemandPartnersStore extends BaseListStore<IDemandPartner, IUser, INetwork> {
    @observable public isLoading;
    @observable public csvFile;
    @observable public csvFileEmptyError: boolean;
    @observable public filterButton: string = "active";

    constructor() {
        super(notificationsStore, DEMAND_PARTNERS_SEARCHABLE_FIELDS, "Demand Partner");
    }

    @action public getItems = () => {
        const filter: any = {};

        (this.filterButton === "active") && (filter.isActive = true);
        (this.filterButton === "archived") && (filter.isActive = false);

        this.selectedValues = observable([]);
        this.isLoading = true;
        demandPartnersAPI.getAll(1, 10000, filter, DEMAND_PARTNERS_LIST_FIELDS, {})
            .then((res) => this.onLoadItemsSuccess(res))
            .catch((res) => this.onLoadItemsError(res));
    }

    @action public startMine = (from: Moment, to: Moment, demandPartnerIds?: string[], name?: string) => {
        demandPartnersAPI.startMine(from.unix(), to.unix(), demandPartnerIds);

        let msg;
        if (name) {
            msg = `Start Mining ${name}`;
        } else if (demandPartnerIds) {
            msg = `Start Mining ${demandPartnerIds.length} Partners`;
        } else {
            msg = `Start Mining All Partners`;
        }

        this.onStartMining(msg);
    }

    @action public startMineByName = (demandPartnerName: string, from: Moment, to: Moment) => {
        const demandPartnerId = this.items.find((demandPartner) => demandPartner.name === demandPartnerName)._id;
        this.startMine(from, to, [demandPartnerId], demandPartnerName);
    }

    @action public onActiveToggle = (item: IDemandPartner) => {
        item.isActive = !item.isActive;

        demandPartnersAPI.isActive(item._id, item.isActive)
            .then(() => this.onUpdateStateSuccess())
            .catch(() => this.onUpdateStateError(item));
    }

    @action public onAutoUpdateCPMToggle = (item: IDemandPartner) => {
        item.autoUpdateCPM = !item.autoUpdateCPM;

        demandPartnersAPI.autoUpdateCPMActive(item._id, item.autoUpdateCPM)
            .then(() => this.onUpdateStateSuccess())
            .catch(() => this.onUpdateStateError(item));
    }

    @action public onVidazooImpUpdateCPM = (item: IDemandPartner) => {
        item.vidazooImpUpdateCPM = !item.vidazooImpUpdateCPM;

        demandPartnersAPI.vidazooImpUpdateCPM(item._id, item.vidazooImpUpdateCPM)
            .then(() => this.onUpdateStateSuccess())
            .catch(() => this.onUpdateStateError(item));
    }

    @action public onCancelDelete = () => {
        this.promptDeleteItem = null;
    }

    @action public onDelete = () => {
        demandPartnersAPI.delete(this.promptDeleteItem._id.toString())
            .then(() => this.onDeleteItemSuccess(this.promptDeleteItem._id))
            .catch(() => this.onDeleteItemError());
        this.promptDeleteItem = null;
    }

    @action
    public onStartMining(msg?: string) {
        this.notificationsStore.pushNotification({
            title: msg,
            timeout: 5000
        });
    }

    @action
    public onStartMiningAll() {
        this.notificationsStore.pushNotification({
            title: `Start Mining All`,
            timeout: 5000
        });
    }

    @action
    public onMinerFailed(demandPartner: string, name: string) {
        this.notificationsStore.pushErrorNotification({
            title: "Mining Failed",
            text: `Failed mining ${demandPartner}(${name})`,
            timeout: 5000
        });
    }

    @action public startMineCsv = (from: Moment, to: Moment, demandPartnerId: string, isJson: boolean, name?: string) => {
        const data = {
            from: from.unix(),
            to: to.unix(),
            id: demandPartnerId,
            uploadedReport: {data: this.csvFile, isJson}
        };
        demandPartnersAPI.startMineCsv(data);

        let msg;
        if (name) {
            msg = `Start Mining CSV ${name}`;
        }

        this.onStartMining(msg);
    }

    @action public uploadFile = (e, toJson: boolean) => {
        this.csvFileEmptyError = false;
        const files = e.target.files;

        if (!files || !files.length) {
            this.csvFileEmptyError = true;
            return;
        }

        const reader = new FileReader();
        reader.readAsText(files[0], "UTF-8");
        reader["toJson"] = toJson;

        reader.onloadend = this.onLoadFile;
    }

    @action private onLoadFile = (event) => {

        if (event.currentTarget.toJson) {
            this.csvFile = event.target.result;
        } else {
            const csv: { data: any[] } = papaparse.parse(event.target.result, {header: true});

            this.csvFile = csv.data;
        }

        if (!this.csvFile.length) {
            this.onLoadFileFailed();
        }
    }

    @action
    private onLoadFileFailed() {

        notificationsStore.pushErrorNotification({
            title: "Operation Failed",
            text: "Failed to load .CSV file",
            timeout: 5000
        });
    }

    @action public resetUploadFile = () => {
        this.csvFile = null;
        this.csvFileEmptyError = false;
    }

    public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
        storageService.setColumnsWidth(`demandPartners`, columnsWidth);
    }

    public getColumnsWidth = (): { [index: string]: number } => {
        return storageService.getColumnsWidth(`demandPartners`);
    }

    @action public onButtonGroupChange = (value: string) => {
        this.filterButton = value;
        this.getItems();
    }
}
