import DailyReport from "./DailyReport";
import {inject, observer} from "mobx-react";

export default inject((stores: any, props: any, context: any): any => ({
  report: stores.dailyReportStore.sortedReport,
  getReport: stores.dailyReportStore.getDailyReport,
  getDemandPartners: stores.demandPartnersStore.getItems,
  onStartMineClick: stores.demandPartnersStore.startMineByName,
  isLoading: stores.dailyReportStore.isLoading,
  from: stores.dailyReportStore.from,
  to: stores.dailyReportStore.to,
  dates: stores.dailyReportStore.dates,
  onFromSelect: stores.dailyReportStore.onFromDateSelect,
  onToSelect: stores.dailyReportStore.onToDateSelect,
  onSort: stores.dailyReportStore.sort,
  sortBy: stores.dailyReportStore.sortBy,
  onSortChange: stores.dailyReportStore.setSortBy,
  download: stores.dailyReportStore.downloadCSV,
  resetReport: stores.dailyReportStore.reset,
  chartData: stores.dailyReportStore.chartData,
  chartParameter: stores.dailyReportStore.currentChartParameter,
  onChartParameterSelect: stores.dailyReportStore.onChartParameterSelect,
  presetTime: stores.dailyReportStore.preset,
  onPresetTimeChange: stores.dailyReportStore.onPresetTimeChange
}))(observer(DailyReport));
