import ReportsAPI from "api/reportsAPI";
import {action} from "mobx";
import {csvBuilder} from "../../services";
import {ReportType} from "../../common/enums";
import {IRawReportEntry} from "../../interfaces/IRawReportEntry";
import BaseReportStore from "./BaseReportStore";

const SEARCHABLE_FIELDS = [
"date",
"impressions",
"provider",
"requests",
"revenue",
"ssp",
"tag",
"tagId",
"updated"];

export default class RawReportStore extends BaseReportStore<IRawReportEntry> {

    constructor() {
        super(SEARCHABLE_FIELDS);
        this.reset();
    }

   @action public getRawReport = () => {
        this.isLoading = true;

        if (this.from <= this.to) {
            ReportsAPI.getRawReport(this.from.unix(), this.to.unix())
                .then((res) => this.setReport(res.data))
                .catch((err) => this.onReportFailed());
        }
    }

    @action protected reset(sort: string = "ssp") {
        super.reset(sort);
    }

    public downloadCSV = () => {
        const fileName = `moneybooker_raw_report_${this.from.format("DD-MM-YYYY")}__${this.to.format("DD-MM-YYYY")}.csv`;
        csvBuilder.build(ReportType.RAW_REPORT, this.report, fileName);
    }
}
