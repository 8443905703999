import App from "./App";
import { inject } from "mobx-react";

export default inject((stores: any, props: any, context: any): any => ({
    userName: stores.currentUserStore.fullName,
    userEmail: stores.currentUserStore.email,
    userAvatarUrl: stores.currentUserStore.avatar,
    onSignOut: stores.sessionStore.logout,
    notifications: stores.notificationsStore.notifications,
    removeNotification: stores.notificationsStore.removeNotification,
    openMyProfile: stores.profileStore.open,
    userId: stores.currentUserStore._id,
    isVidazooOrganization: stores.currentUserStore.isVidazooOrganization,
}))(App);
