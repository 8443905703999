import { observable, action, computed, transaction } from "mobx";
import * as _ from "lodash";
import { networksAPI } from "api";
import BaseItemStore, { IBaseItemStore } from "@vidazoo/ui-framework/lib/stores/BaseItemStore";
import { INetwork } from "interfaces/INetwork";
import { notificationsStore, navigationStore } from "..";
import appUrlsService from "services/appUrlsService";

export interface INetworkStore extends IBaseItemStore<INetwork> {
    getItem: (id: string) => void;
    submit: () => void;
    delete: () => void;
}

export default class NetworkStore extends BaseItemStore<INetwork> implements INetworkStore {

    constructor() {
        super(notificationsStore, "network");
    }

    @action public reset() {
        transaction(() => {
            super.reset();

            this.item = {
                ...this.item,
                name: ""
            };
        });
    }

    @action public getItem(id: string) {
        this.isLoading = true;

        networksAPI.getOne(id)
            .then((item) => this.setItem(item.data))
            .catch((err) => this.onSubmitFailed(err));
    }

    @action public submit() {
        networksAPI.update(this.item._id, this.item)
            .then(() => this.onSubmitSuccess())
            .catch((err) => this.onSubmitFailed(err));
    }

    @action public delete() {
        networksAPI.delete(this.item._id)
            .then(() => this.onSubmitSuccess())
            .then(() => navigationStore.push(appUrlsService.networks()))
            .catch((err) => this.onSubmitFailed(err));
    }
}
