import {action, computed, IObservableArray, observable} from "mobx";
import {IDictionary} from "@vidazoo/ui-framework";
import {multiWordFilter} from "@vidazoo/ui/lib/components/utils";
import {SortDirection} from "@vidazoo/ui";
import {notificationsStore} from "../index";
import * as _ from "lodash";
import * as moment from "moment";
import {Moment} from "moment";
import {TimeRange} from "../../common/enums";
import {getTimeByRange} from "../../common/utils";

export default class BaseReportStore<T> {
    @observable public report: IObservableArray<T>;
    @observable public sortBy: string;
    @observable public sortDirection: SortDirection;
    @observable public searchQueries: IDictionary<string>;
    @observable public isLoading: boolean;
    @observable public from: Moment;
    @observable public to: Moment;
    @observable public preset: TimeRange;

    constructor(protected searchList: any[]) {
        this.reset();
    }

    @action public setSearchQuery = (searchKey: string, value: string) => {
        this.searchQueries[searchKey] = value;
    }

    @action public sort = (sortBy: string, preserveDirection?: boolean) => {
        if (sortBy === this.sortBy && !preserveDirection) {
            this.sortDirection = this.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        }

        this.sortBy = sortBy;
        this.report.replace(_.orderBy(this.report, [this.sortBy], [this.sortDirection.toString() as any]));
    }

    @action public onFromDateSelect = (newFrom: Moment) => {
        this.from = newFrom;
        this.preset = TimeRange.CUSTOM;
    }

    @action public onToDateSelect = (newTo: Moment) => {
        this.to = newTo;
        this.preset = TimeRange.CUSTOM;
    }

    @action public onPresetTimeChange  = (preset: TimeRange) => {
        this.preset = preset;

        if (TimeRange.CUSTOM === preset) {
            return;
        }

        const {from, to} = getTimeByRange(preset);
        this.setDate(from, to);
    }

    @action public setDate(from: Moment, to: Moment) {
        this.from = from;
        this.to = to;
    }

    @computed public get filteredItems(): T[] {

        const hasQueries = _(this.searchQueries).values().compact().value().length > 0;

        if (!hasQueries) {
            return this.report;
        }

        return _.filter(this.report, (item) => {
            const matchQueries = _.map(this.searchQueries, (query: string, field: string) => {
                // return (item[field] ? item[field].toString() : "").toLowerCase().indexOf(query.toLowerCase()) > -1;

                // null values will pass the _.get check ¯\_(ツ)_/¯
                const value = (_.get(item, field, "") || "").toString();

                return multiWordFilter(value, query);
            });

            return _.every(matchQueries);
        });
    }

    @action protected setReport(report: any) {
        this.report = report;
        this.isLoading = false;
    }

    @action protected reset(sort: string = "name") {
        this.from = moment.utc().startOf("day").subtract(1, "day");
        this.to = moment.utc().endOf("day").subtract(1, "day");
        this.report = null;
        this.sortBy = sort;
        this.sortDirection = SortDirection.ASC;
        this.isLoading = false;
        this.preset = TimeRange.YESTERDAY;
        this.resetSearchQueries();
    }

    @action protected resetSearchQueries() {
        const searchQueries = {};
        _.forEach(this.searchList, (field) => {
            searchQueries[field] = "";
        });

        this.searchQueries = searchQueries;
    }

    @action protected onReportFailed() {
        this.isLoading = false;
        notificationsStore.pushErrorNotification({
            title: "Oops!",
            text: "Something went wrong. try again.",
            timeout: 5000
        });
    }

    protected get sortDir(): number {
        return this.sortDirection === SortDirection.ASC ? 1 : -1;
    }
}
