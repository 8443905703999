import { observable, action, computed, transaction } from "mobx";
import * as _ from "lodash";
import { accountsAPI } from "api";
import { navigationStore } from "..";
import { appUrlsService } from "services";
import { sessionStore } from "..";
import { BaseAccountStore } from "./BaseAccountStore";

export default class CreateAccountStore extends BaseAccountStore {

    @action public submit() {
        const item = this.setAccounts();

        accountsAPI.create(item)
            .then(() => navigationStore.push(appUrlsService.accounts()))
            .then(() => this.onSubmitSuccess())
            .catch((err) => this.onSubmitFailed(err));
    }

    @action protected setAccounts = () => {
        const item = JSON.parse(JSON.stringify(this.item));

        item["x-accounts"] = [sessionStore.selectedAccounts[0]];

        return item;
    }
}
