import * as React from "react";
import {Chart} from "../../../../components";
import {getConfig} from "./config";
import {ButtonsGroup, GroupButton, Panel} from "@vidazoo/ui";
import * as theme from "./theme.scss";
import * as Highcharts from "highcharts";
import * as numeral from "numeral";
import * as moment from "moment";

function formatData(value: number, parameter: string) {
    let formattedValue;
    switch (parameter) {
        case "netRevenue":
            formattedValue = numeral(value).format("$0,0");
            break;
        case "ecpm":
            formattedValue = numeral(value).format("$0,0.0");
            break;
        case "impressions":
            formattedValue = numeral(value).format("0,0");
            break;
        case "requests":
            formattedValue = numeral(value).format("0,0");
            break;
        case "fillRate":
            formattedValue = numeral(value).format("0,0.0") + "%";
            break;
    }

    return formattedValue;
}

function tooltipFormatter() {
    let title = this.x;
    if (this.points[0].point.series.chart.options.tooltip.xDateFormat) {
        title = Highcharts.dateFormat(this.points[0].point.series.chart.options.tooltip.xDateFormat, this.x);
    }

    let s = `<table class="${theme["tooltip-table"]}">`;
    s += `<thead><tr><th class="${theme["tooltip-title"]}" colspan="2">${title}</th></tr></thead>`;
    s += `<tbody>`;

    this.points = this.points.sort((a, b) => b.y - a.y);
    this.points.forEach((point) => {
        s += `<tr class="${theme["tooltip-series"]}">
                <td class="${theme["tooltip-label"]}">${point.series.name}</td>
                <td class="${theme["tooltip-value"]}" style="color: ${point.color};">${formatData(point.y, point.series.userOptions.parameter)}</td>
              </tr>`;
    });

    s += `</tbody></table>`;

    return s;
}

function onLegendItemClick(event) {
    if (event.browserEvent.altKey) {
        this.chart.series.forEach((s) => s.setVisible(s === this, false));
        this.redraw();
        return false;
    } else {
        return true;
    }
}

function onResetLegendClick() {
    this.series.forEach((s) => s.setVisible(true, false));
    this.redraw();
}

export interface IDailyReportChartProps {
    chartData: any;
    onChangeParameter: (parameter: string) => void;
    parameter: string;
}

export interface IDailyReportChartState {

}

export default class DailyReportChart extends React.Component<IDailyReportChartProps, IDailyReportChartState> {
    private get config() {
        const {chartData, parameter} = this.props;
        const series = chartData.data.map((item) => ({
            name: item.demandPartner,
            data: item.days,
            pointStart: moment.unix(chartData.from).valueOf(),
            pointInterval: 24 * 3600 * 1000,
            parameter
        } as any));

        const config = getConfig(series);
        config.plotOptions["pointStart"] = chartData.from;
        config.plotOptions.series.events["legendItemClick"] = onLegendItemClick;
        config.tooltip.formatter = tooltipFormatter;
        config.exporting.buttons["resetLegend"] = {text: "Reset Legend", onclick: onResetLegendClick, y: -15, theme: {
                "stroke-width": 1, "stroke": "rgb(153, 155, 164)",
            }};
        return config;
    }

    public render(): JSX.Element {
        const {parameter, onChangeParameter} = this.props;
        const config = this.config;

        return (
            <Panel>
                <div className={theme["chart-buttons"]}>
                    <ButtonsGroup onChange={onChangeParameter} active={parameter} dark>
                        <GroupButton name="netRevenue">Revenue</GroupButton>
                        <GroupButton name="impressions">Impressions</GroupButton>
                        <GroupButton name="requests">Requests</GroupButton>
                        <GroupButton name="ecpm">ECPM</GroupButton>
                        <GroupButton name="fillRate">Fill Rate</GroupButton>
                    </ButtonsGroup>
                </div>
                <Chart config={config}/>
            </Panel>
        );
    }

}
