import DetailsForm from "../components/detailsForm";
import * as React from "react";
import * as theme from "styles/_baseForm.scss";
import DemandPartnerStore from "stores/demandPartners/DemandPartenrStore";
import {RouteComponentProps} from "react-router";
import CurrentUserStore from "stores/CurrentUserStore";
import {Breadcrumbs, Button, Form, PageContainer, Surface, Tab, TabsPanel} from "@vidazoo/ui";
import {IBreadcrumb} from "@vidazoo/ui/lib/components/breadcrumbs";
import {appUrlsService} from "services";
import {Loader} from "../../../components";
import {HistoryLog} from "@vidazoo/ui-framework";

export interface IEditDemandPartnerProps extends RouteComponentProps<{id: string}> {
    demandPartnerStore?: DemandPartnerStore;
    currentUserStore?: CurrentUserStore;
}

export interface IEditDemandPartnerState {

}

export default class EditDemandPartner extends React.Component<IEditDemandPartnerProps, IEditDemandPartnerState> {

    public componentDidMount() {
        const { demandPartnerStore, match } = this.props;

        demandPartnerStore.getItem(match.params.id);
    }

    private getTitle() {
        const {demandPartnerStore} = this.props;
        return demandPartnerStore.item.name;
    }

    public renderBreadCrumbs(): IBreadcrumb[] {
        return [
            {
                label: "Demand Partners",
                iconName: "icon-demand-partners",
                link: {to: appUrlsService.demandPartners()}
            },
            {
                label: "Update"
            },
            {
                label: this.getTitle(),
                primary: true
            }
        ];
    }

    public onSubmit = () => {
        const {demandPartnerStore} = this.props;

        demandPartnerStore.submit();
    }

    private onDuplicateClick = () => {
        const { demandPartnerStore } = this.props;

        demandPartnerStore.duplicate();
    }

    private onCancelClick = () => {
        const { history } = this.props;

        history.push(appUrlsService.demandPartners());
    }

    private onDeleteClick = () => {
        const {demandPartnerStore} = this.props;

        demandPartnerStore.delete();
    }

    public render(): JSX.Element {
        const {demandPartnerStore, currentUserStore, match} = this.props;
        const {isLoading} = demandPartnerStore;
        const {sspTypes} = currentUserStore;
        return(
            <PageContainer inner top={1.2}>
                <div className={["flex", "justify-space-between"].join(" ")}>
                    <Breadcrumbs className={theme.breadcrumbs} breadcrumbs={this.renderBreadCrumbs()} />
                    <HistoryLog
                        isRootAdmin={currentUserStore.isRootAdmin()}
                        isVidazooUser={currentUserStore.isVidazooOrganization}
                        resourceName="demand_partners"
                        itemId={match.params.id}
                        baseUrl={window.__BASE_API_URL__}
                    />
                </div>
                {isLoading ? <Loader title="Loading Demand Partner" description="Please wait..."/>  :
                <Form className={theme.form} onSubmit={this.onSubmit}>
                    <TabsPanel className={theme.midContent}>
                        <Tab label="Edit">
                            <Surface className={theme.surface} level={1}>
                                <DetailsForm store={demandPartnerStore} sspTypes={sspTypes}/>
                            </Surface>
                        </Tab>
                    </TabsPanel>
                    <div className={theme.actions}>
                        <Button className={theme.actions} onClick={this.onCancelClick} type="button" medium gray>Cancel</Button>
                        <Button className={theme.actions} onClick={this.onDeleteClick} type="button" medium red>Delete</Button>
                        <Button className={theme.actions} onClick={this.onDuplicateClick} type="button" medium gray60>Duplicate</Button>
                        <Button className={theme.actions} type="submit" medium blue>Update Partner</Button>
                    </div>
                </Form>}
            </PageContainer>
        );
    }
}
