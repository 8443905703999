import * as React from "react";
import * as classnames from "classnames";
import * as theme from "./theme.scss";
import { PageMessage } from "@vidazoo/ui";
import * as Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
HC_exporting(Highcharts);

function tooltipFormatter() {
    let title = this.x;
    if (this.points[0].point.series.chart.options.tooltip.xDateFormat) {
        title = Highcharts.dateFormat(this.points[0].point.series.chart.options.tooltip.xDateFormat, this.x);
    }

    let s = `<span class="${theme["tooltip-title"]}">${title}</span>`;

    this.points.forEach((point) => {
        s += `<span class="${theme["tooltip-series"]}"><span class="${theme["tooltip-label"]}">${point.series.name}:</span> <span class="${theme["tooltip-value"]}" style="color: ${point.color};">${point.y.toLocaleString()}</span></span>`;
    });

    return s;
}

export interface IChartProps {

    className?: string;

    style?: any;

    config: Highcharts.Options;

    displayNoData?: boolean;

    isMap?: boolean;

}

export interface IChartState {

}

export default class Chart extends React.Component<IChartProps, IChartState> {

    public static defaultProps: Partial<IChartProps> = {
        config: {
            credits: { enabled: false },
            chart: {}
        },
        displayNoData: false
    };

    private $el: HTMLDivElement;
    private chart: Highcharts.Chart;

    constructor(props, context) {
        super(props, context);

        this.$el = null;
        this.chart = null;
    }

    public componentDidUpdate() {
        const { config } = this.props;

        this.setSeriesLinearGradient(config);

        this.chart && this.chart.update(config, true);
    }

    public componentDidMount() {
        const { config, isMap } = this.props;

        if (config.chart) {
            config.chart.renderTo = this.$el;
        }

        this.setSeriesLinearGradient(config);

        this.setTooltipFormatter(config);

        this.chart = isMap
            ? (Highcharts as any).mapChart(this.$el, config)
            : Highcharts.chart(config);
    }

    private setSeriesLinearGradient(config: Highcharts.Options) {
        const height = this.$el.offsetHeight;

        if (config.series && config.series.length) {
            for (let i = 0, len = config.series.length; i < len; i++) {
                const fillColor = (config.series[i] as any).fillColor;

                if (fillColor && fillColor.linearGradient) {
                    fillColor.linearGradient = [0, 0, 0, height];
                }
            }
        }
    }

    private setTooltipFormatter(config: Highcharts.Options) {
        if (config.tooltip && !config.tooltip.formatter) {
            config.tooltip.formatter = tooltipFormatter;
        }
    }

    private renderNoData(): JSX.Element {
        return (
            <div className="spread flex align-center justify-center">
            <PageMessage
                iconName="icon-dashboard"
        title="No Data"
            />
            </div>
    );
    }

    private setRef = (el) => {
        this.$el = el;
    }

    public render(): JSX.Element {

        const { className, style, displayNoData } = this.props;

        const classes = classnames(theme.chart, className);

        const innerStyle = {
            display: displayNoData ? "none" : ""
        };

        return (
            <div className={classes} style={style}>
            {displayNoData ? this.renderNoData() : null}
            <div className={theme.inner} style={innerStyle} ref={this.setRef}></div>
        </div>
    );
    }
}
