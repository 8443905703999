import * as React from "react";
import * as moment from "moment";
import * as theme from "./theme.scss";

export default function Headers(props): JSX.Element {
    const {dates} = props;
    return (
        <tr className={theme.header}>
            <th>Provider</th>
            <th>Date</th>
            {dates.map((date) => <th key={date}>{moment.utc(date, "X").format("DD/MM/YYYY")}</th>)}
            {dates.length > 1 ? <th className="provider">Sum</th> : null}
            {dates.length > 1 ? <th className="provider">Avg</th> : null}
        </tr>
    );
}
