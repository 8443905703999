class AppUrlsService {

    public app(): string {
        return `/`;
    }

    public accounts(): string {
        return `/accounts`;
    }

    public account(): string {
        return `/account`;
    }

    public createAccount(): string {
        return `${this.account()}/create`;
    }

    public editAccount(id: string = ":id"): string {
        return `${this.account()}/${id}/edit`;
    }

    public networks(): string {
        return `/networks`;
    }

    public network(): string {
        return `/network`;
    }

    public createNetwork(): string {
        return `${this.network()}/create`;
    }

    public editNetwork(id: string = ":id"): string {
        return `${this.network()}/${id}/edit`;
    }

    public ecpmReport(): string {
        return `/ecpm_report`;
    }

    public dailyReport(): string {
        return `/daily_report`;
    }

    public rawReport(): string {
        return `/raw_report`;
    }

    public demandPartners(): string {
        return `/demand_partners`;
    }

    public demandPartner(): string {
        return `/demand_partner`;
    }

    public editDemandPartner(id: string = ":id"): string {
        return `${this.demandPartner()}/${id}/edit`;
    }

    public createDemandPartner(flag: string= ""): string {
        return `${this.demandPartner()}/create${flag}`;
    }

    public jobsStatus(): string {
        return `/jobs_status`;
    }

    public notificationCenter(): string {
        return `/notification-center`;
    }

    public notificationSettings(): string {
        return `/notification-settings`;
    }

    public discrepancyReport(): string {
        return `/discrepancy_report`;
    }
}

export default new AppUrlsService();
