import * as React from "react";
import List from "./components/list";
import {
    Button,
    DateTimeRangePicker,
    Density,
    FormSelect,
    HLine,
    Label,
    PageContainer,
    PageTitle,
    SortDirection,
    Surface,
    TimezonePicker
} from "@vidazoo/ui";
import * as theme from "./theme.scss";
import {
    EcpmReportFilter,
    EcpmReportFilters,
    IntervalType,
    IntervalTypes,
    TimeRange,
    Timezone
} from "../../common/enums";
import {IDictionary} from "@vidazoo/ui-framework";
import IECPMReportEntry from "interfaces/IECPMReportEntry";
import {Moment} from "moment";
import {ReportingMessage} from "@vidazoo/ui-framework/lib/containers/reporting/components";
import IECPMSums from "interfaces/IECPMSums";
import {Loader, DateTimeRangePickerThemed, PresetTimes} from "../../components";

export interface IECPMReportProps {
    report: IECPMReportEntry[];
    getReport: () => void;
    sortBy: string;
    sortDirection?: SortDirection;
    sums: IECPMSums;
    onSort: (sortBy: string) => void;
    searchQueries?: IDictionary<string>;
    onSearch?: (searchKey: string, value: string) => void;
    isLoading: boolean;
    from: Moment;
    to: Moment;
    onFromSelect: (from: Moment) => void;
    onToSelect: (to: Moment) => void;
    onTimeZoneSelect: (timezone: string) => void;
    timezone: Timezone;
    download: () => void;
    presetTime?: TimeRange;
    onPresetTimeChange?: (preset: TimeRange) => void;
    intervalType: IntervalType;
    onIntervalTypeSelect: (type: IntervalType) => void;
    onEcpmReportFilterSelect: (type: EcpmReportFilter) => void;
    density?: Density;
    loadMore?: () => void;
    setColumnsWidth: (columnsWidth: { [index: string]: number }) => void;
    getColumnsWidth: () => { [index: string]: number };
    reportFilter?: EcpmReportFilter;
}

export interface IECPMReportState {
    isLoading: boolean;
}

export default class ECPMReport extends React.Component<IECPMReportProps, IECPMReportState> {

    constructor(props, context) {
        super(props, context);
    }

    private onGetReportClick = () => {
        const {getReport} = this.props;

        getReport();
    }

    private onTimezoneSelect = (e) => {
        const {onTimeZoneSelect} = this.props;

        onTimeZoneSelect(e.target.value);
    }

    private onIntervalTypeSelect = (e) => {
        const {onIntervalTypeSelect} = this.props;

        onIntervalTypeSelect(e.target.value);
    }

    private onEcpmReportFilterSelect = (e) => {
        const {onEcpmReportFilterSelect} = this.props;

        onEcpmReportFilterSelect(e.target.value);
    }

    public render(): JSX.Element {
            const {report, onSort, sums, sortBy, sortDirection, searchQueries, onSearch, isLoading, from, to, onFromSelect, onToSelect, timezone, download, presetTime, onPresetTimeChange, intervalType, density, loadMore, setColumnsWidth, getColumnsWidth, reportFilter} = this.props;
            return (
                <PageContainer pageTitle="ECPM Report" top={1.2} className={theme.list}>
                    <Surface level={2}>
                        <div className={theme.rangePickerPanel}>
                            <PageTitle className={theme.title} iconName="icon-campaigns" label="ECPM Report" />
                            <div className={theme.presetTimes}>
                                <PresetTimes presetTime={presetTime} onPresetChange={onPresetTimeChange}/>
                            </div>
                            <div className={theme.rangePicker}>
                                <DateTimeRangePickerThemed>
                                    <DateTimeRangePicker fromDate={from} maxFromDate={to} toDate={to} minToDate={from} onFromChange={onFromSelect} onToChange={onToSelect} allDay={true} />
                                </DateTimeRangePickerThemed>
                                <Label className={theme.label} spacing={1} size={11}>Default Timezone (for rows with missing SSP Data) </Label><TimezonePicker timezone={timezone} onChange={this.onTimezoneSelect}/>
                                <Label className={theme.label} spacing={1} size={11}>Interval Type </Label><FormSelect name="intervalType" value={intervalType} items={IntervalTypes} onChange={this.onIntervalTypeSelect}/>
                            </div>
                            <HLine />
                            <div className={theme.actions}>
                                <div className={theme.colors}>
                                    <div><div className={theme.red}/><p> = Missing BI Data</p></div>
                                    <div><div className={theme.orange}/><p> = Missing SSP Data</p></div>
                                    <div><div className={theme.yellow}/><p> = ECPM diff {">"} 5%</p></div>
                                    <div><div className={theme.purple}/><p> = IMA diff {">"} 10%</p></div>
                                </div>
                                <div className={theme.buttons}>
                                    <div className={theme.rangePicker}>
                                        <FormSelect name="reportFilter" placeholder="Select Filter" value={reportFilter} items={EcpmReportFilters} onChange={this.onEcpmReportFilterSelect}/>
                                    </div>
                                    <Button iconName="icon-download" onClick={download} medium green>Download CSV</Button>
                                    <Button onClick={this.onGetReportClick} medium blue>Get Report</Button>
                                </div>
                            </div>
                        </div>
                    </Surface>
                    {isLoading ? <Loader title="Loading ECPM Report" description="Please wait..."/>  : (report ?
                        <List
                        items={report}
                        sums={sums}
                        sortBy={sortBy}
                        onSort={onSort}
                        sortDirection={sortDirection}
                        searchQueries={searchQueries}
                        onSearch={onSearch}
                        intervalType={intervalType}
                        density={density}
                        loadMore={loadMore}
                        setColumnsWidth={setColumnsWidth}
                        getColumnsWidth={getColumnsWidth}
                    /> : null)}
                    {(!isLoading && !report)  ? <div className={theme.message}>
                        <ReportingMessage iconName="icon-calendar" title="Select Date Range" message="Select date range and press on get report" />
                    </div> : null}
                </PageContainer>
            );
        }

}
