import * as moment from "moment";
import {IntervalType, ReportType} from "../common/enums";
import IDailyReport from "../interfaces/dailyReport/IDailyReport";
import IECPMReportEntry from "../interfaces/IECPMReportEntry";
import IECPMSums from "../interfaces/IECPMSums";
import IDiscrepancyReportEntry from "../interfaces/IDiscrepancyReportEntry";
import IDiscrepancySums from "../interfaces/IDiscrepancySums";

class CSVBuilder {
    public build = (reportType, data, fileName, intervalType?: IntervalType) => {
        let content;

        switch (reportType) {
            case ReportType.RAW_REPORT:
                content = this.getRawReportCSVContent(data);
                break;
            case ReportType.DAILY_REPORT:
                content = this.getDailyReportCSVContent(data);
                break;
            case ReportType.ECPM_REPORT:
                content = this.getECPMReportCSVContent(data, intervalType);
                break;
            case ReportType.DISCREPANCY_REPORT:
                content = this.getDiscrepancyReportCSVContent(data, intervalType);
                break;
        }

        this.download(content, fileName);
    }

    private getRawReportCSVContent(data) {
        const header = "SSP,Provider,Date,Tag Name,Impressions,Requests,Revenue,Updated\r\n";

        const rows = data.map((r) => {
            return `${r.ssp},${r.provider},${moment.utc(r.date, "X").format("DD/MM/YYYY")},"${r.tag}",${r.impressions},${r.requests},${r.revenue},${moment(r.updated).format("DD/MM/YYYY HH:mm")}`;
        });

        return header + rows.join("\r\n");
    }

    private getDailyReportCSVContent(data: {report: IDailyReport, dates: number[]}) {
        const multi = data.dates.length > 1;
        const header = `Date,${data.dates.map((date) => moment.utc(date, "X").format("DD-MM-YYYY")).join(",")}${multi ? ",Sum,Avg" : ""}\r\n`;
        let content = "";

        const {days, sum, avg} = data.report.totals;

        content += "Total Requests,";
        content += data.dates.map((date) => (days[date].requests)).join(",");
        content += multi ? `,${sum.requests},${avg.requests}` : "";
        content += "\r\n";

        content += "Total Imp,";
        content += data.dates.map((date) => (days[date].impressions)).join(",");
        content += multi ? `,${sum.impressions},${avg.impressions}` : "";
        content += "\r\n";

        content += "Total Net Revenue,";
        content += data.dates.map((date) => (days[date].netRevenue)).join(",");
        content += multi ? `,${sum.netRevenue},${avg.netRevenue}` : "";
        content += "\r\n";

        content += "Ecpm,";
        content += data.dates.map((date) => (days[date].ecpm)).join(",");
        content += multi ? `,,${avg.ecpm}` : "";
        content += "\r\n";

        data.report.providersData.map((providerData) => {

            // tslint:disable-next-line:no-shadowed-variable
            const {days, sum, avg, provider} = providerData;

            content += `${provider} Requests,`;
            content += data.dates.map((date) => ((days[date]).requests)).join(",");
            content += multi ? `,${sum.requests},${avg.requests}` : "";
            content += "\r\n";

            content += `${provider} Imp,`;
            content += data.dates.map((date) => (days[date].impressions)).join(",");
            content += multi ? `,${sum.impressions},${avg.impressions}` : "";
            content += "\r\n";

            content += `${provider} Net Revenue,`;
            content += data.dates.map((date) => (days[date].netRevenue)).join(",");
            content += multi ? `,${sum.netRevenue},${avg.netRevenue}` : "";
            content += "\r\n";

            content += `${provider} Ecpm,`;
            content += data.dates.map((date) => (days[date].ecpm)).join(",");
            content += multi ? `,,${avg.ecpm}` : "";
            content += "\r\n";

            content += `${provider} Fillrate,`;
            content += data.dates.map((date) => (days[date].fillRate)).join(",");
            content += multi ? `,,${avg.fillRate}` : "";
            content += "\r\n";
        });

        return header + content;
    }

    private getECPMReportCSVContent(data: {report: IECPMReportEntry[], sums: IECPMSums}, intervalType: IntervalType) {
        const {report, sums} = data;
        let header = "Source,BI Partner ID, Network ID,BI Partner Name,Ad Sent,Ad SPM,Ad Start,Ad CPM,IMA Floor,SSP Provider,SSP Tag Name,SSP Impressions,SSP Net Revenue,SSP Requests,SSP ECPM,ECPM Diff (%), Impressions Diff, Impressions Diff (%), Revenue Diff, Revenue Diff (%)\r\n";
        let sumsRow = `"TOTALS","","","","${sums.adSent}","${sums.adSpm}","${sums.adStart}","${sums.adCpm}","","","","${sums.sspImpressions}","${sums.sspNetRevenue}","${sums.sspRequests}","${sums.sspEcpm}","${sums.cpmDiffPercent}","${sums.impressionsDiff}","${sums.impressionsDiffPercent}","${sums.netRevenueDiff}","${sums.netRevenueDiffPercent}"\r\n"`;
        if (intervalType === IntervalType.Day) {
            header = `Date,${header}`;
            sumsRow = `"",${sumsRow}`;
        }
        const rows = report.map((r) => {
            let row = `"${r.source || ""}","${r.tagPartnerId}","${r.networkId}","${r.tagPartnerName}",${r.adSent},${r.adSpm},${r.adStart},${r.adCpm},${r.imaFloorCpm},"${r.sspProvider}","${r.sspTagName}",${r.sspImpressions},${r.sspNetRevenue},${r.sspRequests},${r.sspEcpm},${r.cpmDiffPercent || 0},${r.impressionsDiff},${r.impressionsDiffPercent},${r.netRevenueDiff},"${r.netRevenueDiffPercent || 0}"`;
            if (intervalType === IntervalType.Day) {
                row = `"${r.date}",${row}`;
            }
            return row;
        });

        return header + sumsRow + rows.join("\r\n");
    }

    private getDiscrepancyReportCSVContent(data: {report: IDiscrepancyReportEntry[], sums: IDiscrepancySums}, intervalType: IntervalType) {
        const {report, sums} = data;
        let header = "Partner,Ad Sent,Ad SPM,Ad Start,SSP Requests,SSP Net Revenue,SSP Impressions, Impressions Diff, Impressions Diff (%), Revenue Diff, Revenue Diff (%)\r\n";
        let sumsRow = `"TOTALS","${sums.adSent}","${sums.adSpm}","${sums.adStart}","${sums.sspRequests}","${sums.sspNetRevenue}","${sums.sspImpressions}","${sums.impressionsDiff}","${sums.impressionsDiffPercent}","${sums.netRevenueDiff}","${sums.netRevenueDiffPercent}"\r\n"`;
        if (intervalType === IntervalType.Day) {
            header = `Date,${header}`;
            sumsRow = `"",${sumsRow}`;
        }
        const rows = report.map((r) => {
            let row = `"${r.sspProvider}",${r.adSent},${r.adSpm},${r.adStart},${r.sspRequests},${r.sspNetRevenue},${r.sspImpressions},${r.impressionsDiff},${r.impressionsDiffPercent},${r.netRevenueDiff},"${r.netRevenueDiffPercent || 0}"`;
            if (intervalType === IntervalType.Day) {
                row = `"${r.date}",${row}`;
            }
            return row;
        });

        return header + sumsRow + rows.join("\r\n");
    }

    private download(content: any, fileName: string) {
        const blob = new Blob([content]);
        // const url = window.URL.createObjectURL(blob, { type: "text/plain" } as any);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }
}

export default new CSVBuilder();
