import { observable, action, computed } from "mobx";
import * as _ from "lodash";
import { gravatar } from "@vidazoo/ui-framework";
import IUser from "interfaces/IUser";
import { INetwork } from "interfaces/INetwork";

export default class BaseUserStore implements IUser {
    public _id: string;
    public date: number;
    public created: number;
    public updated: number;
    public isDeleted: boolean;
    public avatar: string;
    public sspTypes: string[];
    @observable public username: string;
    @observable public network: INetwork;
    @observable public email: string;
    @observable public roles: string[];
    @observable public firstName: string;
    @observable public lastName: string;
    @observable public isActive: boolean;

    @action public initialize(user: IUser) {
        this._id = user._id;
        this.date = user.date;
        this.username = user.username;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.roles = user.roles;
        this.email = user.email;
        this.network = user.network;
        this.isActive = user.isActive;
        this.avatar = gravatar.getUrl(this.email, 100);
        this.sspTypes = user.sspTypes;
    }

    @action public reset() {
        this._id = null;
        this.date = null;
        this.username = "";
        this.network = null;
        this.firstName = "";
        this.lastName = "";
        this.roles = ["user"];
        this.email = "";
        this.isActive = true;
        this.sspTypes = [];
    }

    @action public updateParam(key: string, value: any) {
        if (this[key] !== undefined) {
            this[key] = value;
        }
    }

    @action public updateParams(partial: Partial<IUser>) {
        _.forEach(partial, (value: string, key: string) => {
            this.updateParam(key, value);
        });
    }

    @computed get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    public toJSON() {
        return {
            id: this._id,
            firstName: this.firstName,
            lastName: this.lastName,
            roles: this.roles,
            email: this.email
        };
    }
}
