import RawReport from "./RawReport";
import {inject, observer} from "mobx-react";

export default inject((stores: any, props: any, context: any): any => ({
    report: stores.rawReportStore.filteredItems,
    getReport: stores.rawReportStore.getRawReport,
    sortBy: stores.rawReportStore.sortBy,
    isLoading: stores.rawReportStore.isLoading,
    onSort: stores.rawReportStore.sort,
    sortDirection: stores.rawReportStore.sortDirection,
    searchQueries: stores.rawReportStore.searchQueries,
    onSearch: stores.rawReportStore.setSearchQuery,
    from: stores.rawReportStore.from,
    to: stores.rawReportStore.to,
    onFromSelect: stores.rawReportStore.onFromDateSelect,
    onToSelect: stores.rawReportStore.onToDateSelect,
    download: stores.rawReportStore.downloadCSV,
    presetTime: stores.rawReportStore.preset,
    onPresetTimeChange: stores.rawReportStore.onPresetTimeChange
}))(observer(RawReport));
