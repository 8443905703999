import ECPMReport from "./ECPMReport";
import {inject, observer} from "mobx-react";

export default inject((stores: any, props: any, context: any): any => ({
    report: stores.ecpmReportStore.filteredItems,
    getReport: stores.ecpmReportStore.getECPMReport,
    sortBy: stores.ecpmReportStore.sortBy,
    onSort: stores.ecpmReportStore.sort,
    sums: stores.ecpmReportStore.reportSums,
    sortDirection: stores.ecpmReportStore.sortDirection,
    searchQueries: stores.ecpmReportStore.searchQueries,
    onSearch: stores.ecpmReportStore.setSearchQuery,
    isLoading: stores.ecpmReportStore.isLoading,
    from: stores.ecpmReportStore.from,
    to: stores.ecpmReportStore.to,
    onFromSelect: stores.ecpmReportStore.onFromDateSelect,
    onToSelect: stores.ecpmReportStore.onToDateSelect,
    onTimeZoneSelect: stores.ecpmReportStore.onTimezoneSelect,
    timezone: stores.ecpmReportStore.timezone,
    download: stores.ecpmReportStore.downloadCSV,
    presetTime: stores.ecpmReportStore.preset,
    onPresetTimeChange: stores.ecpmReportStore.onPresetTimeChange,
    intervalType: stores.ecpmReportStore.intervalType,
    onIntervalTypeSelect: stores.ecpmReportStore.onIntervalTypeSelect,
    density: stores.ecpmReportStore.density,
    loadMore: stores.ecpmReportStore.loadMore,
    setColumnsWidth: stores.ecpmReportStore.setColumnsWidth,
    getColumnsWidth: stores.ecpmReportStore.getColumnsWidth,
    onEcpmReportFilterSelect: stores.ecpmReportStore.onEcpmReportFilterSelect,
    reportFilter: stores.ecpmReportStore.reportFilter,
}))(observer(ECPMReport));
