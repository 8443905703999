import { UserRole } from "@vidazoo/ui-framework";
import IMenuEntry from "interfaces/IMenuEntry";
import { appUrlsService } from "services";

const DEFAULT_ACCESS = [UserRole.USER, UserRole.ADMIN, UserRole.SUPER_ADMIN];

export default {
    label: "Settings",
    iconName: "icon-settings",
    access: [UserRole.SUPER_ADMIN, UserRole.ROOT_ADMIN],
    items: [
        {
            label: "Networks",
            link: { to: appUrlsService.networks() },
            access: [UserRole.ROOT_ADMIN]
        },
        {
            label: "Accounts",
            link: { to: appUrlsService.accounts() },
            access: [UserRole.ROOT_ADMIN, UserRole.ADMIN, UserRole.SUPER_ADMIN]
        }
    ]
} as IMenuEntry;
