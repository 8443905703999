import {BaseSocketService} from "@vidazoo/ui-framework";
import {sessionStore, jobsStatusStore} from "../stores";
import {IJobEntry} from "../interfaces/IJobEntry";

class SocketService extends BaseSocketService {

    protected onSocketError(err) {
        if (err === "unauthorized") {
            window.__sdk__.auth.logout();
        }
    }

    // protected onSocketConnect() {
    //     this.socket.emit("subscribe", {accountId: sessionStore.selectedAccounts[0]});
    //     this.socket.on("demandPartnerJobStatusUpdate", (data) => this.onDemandPartnerJobStatusUpdate(data));
    // }
    //
    // private onDemandPartnerJobStatusUpdate(data: Partial<IJobEntry>) {
    //     jobsStatusStore.onJobStatusChange(data);
    // }
}

export default new SocketService();
