import * as React from "react";
import { Label, FormChips, Tooltip, Button } from "@vidazoo/ui";
import { pauseEvent } from "@vidazoo/ui/lib/components/utils";
import * as theme from "styles/baseList.scss";
import { IButtonProps } from "@vidazoo/ui/lib/components";

export interface IAddAccountButtonProps extends IButtonProps {
    isMultiSelectedAccounts?: boolean;
    onClick?: () => void;
    label: string;
}

export interface IAddAccountButtonState {

}

export default class AddItemButton extends React.Component<IAddAccountButtonProps, IAddAccountButtonState> {

    constructor(props, context) {
        super(props, context);

    }

    public render(): JSX.Element {
        const { isMultiSelectedAccounts, label, ...others } = this.props;

        return isMultiSelectedAccounts
            ? <Tooltip title="You can not create item when more than one account selected." position="left">
                <div>
                    <Button style={{ pointerEvents: "none" }} {...others} disabled>{label}</Button>
                </div>
            </Tooltip>
            : <Button {...others}>{label}</Button>;
    }
}
