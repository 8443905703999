import * as React from "react";
import {guid, IDictionary} from "@vidazoo/ui-framework";
import {
    NativeScrollable,
    SortDirection,
    Surface,
    Table,
    TableBody,
    TableHeaderColumn,
    TableHeaderSummaryColumn
} from "@vidazoo/ui";
import DiscrepancyReportRow from "./components/discrepancyReportRow";
import IDiscrepancyReportEntry from "interfaces/IDiscrepancyReportEntry";
import * as numeral from "numeral";
import TableThemedProvider from "./components/tableThemedProvider/index";
import IDiscrepancySums from "../../../../interfaces/IDiscrepancySums";
import {IntervalType} from "../../../../common/enums";

export interface IListProps {
    items?: IDiscrepancyReportEntry[];
    sums: IDiscrepancySums;
    searchQueries: IDictionary<string>;
    sortBy?: string;
    sortDirection: SortDirection;
    onSort: (sortBy: string) => void;
    onSearch: (searchKey: string, value: string) => void;
    intervalType: IntervalType;
    timezone: string;
}

export default ({ items, sums, sortBy, onSort, sortDirection, searchQueries, onSearch, intervalType, timezone}: IListProps): JSX.Element => (
    <Surface level={2}>
        <NativeScrollable scrollSize={6} suppressY stickyOverflowScrollX bold >
            <TableThemedProvider>
                <Table stickyHeaderOffsetTop={80} enableStickyHeader density={3} fill>
                    <thead>
                        <tr>
                            {intervalType === IntervalType.Day ?
                                <TableHeaderColumn
                                    blue
                                    label="Date"
                                    sortable={true}
                                    sortBy="date"
                                    onSort={onSort}
                                    sortActive={sortBy === "date"}
                                    sortDirection={sortDirection}
                                    searchKey="date"
                                    searchable={true}
                                    onSearch={onSearch}
                                    searchQuery={searchQueries["date"]}
                                />
                                : null}
                            <TableHeaderColumn
                                blue
                                label="Partner"
                                sortable={true}
                                sortBy="sspProvider"
                                onSort={onSort}
                                sortActive={sortBy === "sspProvider"}
                                sortDirection={sortDirection}
                                searchKey="sspProvider"
                                searchable={true}
                                onSearch={onSearch}
                                searchQuery={searchQueries["sspProvider"]}
                            />
                            <TableHeaderColumn
                                blue
                                label="VZ req"
                                sortable={true}
                                sortBy="adSent"
                                onSort={onSort}
                                sortActive={sortBy === "adSent"}
                                sortDirection={sortDirection}
                            />
                            <TableHeaderColumn
                                blue
                                label="VZ revenue"
                                sortable={true}
                                sortBy="adStart"
                                onSort={onSort}
                                sortActive={sortBy === "adStart"}
                                sortDirection={sortDirection}
                            />
                            <TableHeaderColumn
                                blue
                                label="VZ imp"
                                sortable={true}
                                sortBy="adSpm"
                                onSort={onSort}
                                sortActive={sortBy === "adSpm"}
                                sortDirection={sortDirection}
                            />
                            <TableHeaderColumn
                                blue
                                label="SSP req"
                                sortable={true}
                                sortBy="sspRequests"
                                onSort={onSort}
                                sortActive={sortBy === "sspRequests"}
                                sortDirection={sortDirection}
                            />
                            <TableHeaderColumn
                                blue
                                label="SSP revenue"
                                sortable={true}
                                sortBy="sspNetRevenue"
                                onSort={onSort}
                                sortActive={sortBy === "sspNetRevenue"}
                                sortDirection={sortDirection}
                            />
                            <TableHeaderColumn
                                blue
                                label="SSP imp"
                                sortable={true}
                                sortBy="sspImpressions"
                                onSort={onSort}
                                sortActive={sortBy === "sspImpressions"}
                                sortDirection={sortDirection}
                            />
                            <TableHeaderColumn
                                blue
                                label="IMP Diff(%)"
                                sortable={true}
                                sortBy="impressionsDiffPercent"
                                onSort={onSort}
                                sortActive={sortBy === "impressionsDiffPercent"}
                                sortDirection={sortDirection}
                            />
                            <TableHeaderColumn
                                blue
                                label="IMP Diff"
                                sortable={true}
                                sortBy="impressionsDiff"
                                onSort={onSort}
                                sortActive={sortBy === "impressionsDiff"}
                                sortDirection={sortDirection}
                            />
                            <TableHeaderColumn
                                blue
                                label="REV Diff(%)"
                                sortable={true}
                                sortBy="netRevenueDiffPercent"
                                onSort={onSort}
                                sortActive={sortBy === "netRevenueDiffPercent"}
                                sortDirection={sortDirection}
                            />
                            <TableHeaderColumn
                                blue
                                label="REV Diff"
                                sortable={true}
                                sortBy="netRevenueDiff"
                                onSort={onSort}
                                sortActive={sortBy === "netRevenueDiff"}
                                sortDirection={sortDirection}
                            />
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {intervalType === IntervalType.Day ? <TableHeaderSummaryColumn /> : null}
                            <TableHeaderSummaryColumn />
                            <TableHeaderSummaryColumn value={numeral(sums.adSent).format("0,0").toString()} />
                            <TableHeaderSummaryColumn value={numeral(sums.adSpm).format("$0,0.00").toString()} />
                            <TableHeaderSummaryColumn value={numeral(sums.adStart).format("0,0").toString()} />
                            <TableHeaderSummaryColumn value={numeral(sums.sspRequests).format("0,0").toString()} />
                            <TableHeaderSummaryColumn value={numeral(sums.sspNetRevenue).format("$0,0.00").toString()} />
                            <TableHeaderSummaryColumn value={numeral(sums.sspImpressions).format("0,0").toString()} />
                            <TableHeaderSummaryColumn value={numeral(sums.impressionsDiffPercent).format("0,0.00").toString() + "%"}/>
                            <TableHeaderSummaryColumn value={numeral(sums.impressionsDiff).format("0,0.00").toString()}/>
                            <TableHeaderSummaryColumn value={numeral(sums.netRevenueDiffPercent).format("0,0.00").toString() + "%"} />
                            <TableHeaderSummaryColumn value={numeral(sums.netRevenueDiff).format("0,0").toString()} />
                        </tr>
                    </tbody>
                    <TableBody itemHeight={47}>{items.map((item) =>  <DiscrepancyReportRow item={item} key={guid()} intervalType={intervalType} timezone={timezone}/>)}</TableBody>
                </Table>
            </TableThemedProvider>
        </NativeScrollable>
    </Surface>
);
