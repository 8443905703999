import * as React from "react";
import TableCell from "./components/tableCellWrapper/index";
import * as moment from "moment";
import IDemandPartner from "interfaces/IDemandPartner";
import * as numeral from "numeral";
import IDiscrepancyReportEntry from "../../../../../../interfaces/IDiscrepancyReportEntry";
import {IntervalType} from "../../../../../../common/enums";

export interface IDiscrepancyReportRowProps {

    item?: IDiscrepancyReportEntry;
    onMineClick?: (sspId: string, from: moment.Moment, to: moment.Moment) => void;
    openModal?: (title: string, onDone: (from: moment.Moment, to: moment.Moment, sspId?: string) => void) => void;
    onEditClick?: (item: IDemandPartner) => void;
    intervalType: IntervalType;
    timezone: string;
}

export interface IDiscrepancyReportRowState {
}

export default class DiscrepancyReportRow extends React.Component<IDiscrepancyReportRowProps, IDiscrepancyReportRowState> {

    private get rowColor(): string {
        const {item} = this.props;
        let color;
        if (item.missingData) {
            color = "#cb8234";
        } else if (item.impressionsDiffPercent && Math.abs(item.impressionsDiffPercent) > 5) {
            color = "#efcb3b";
        }

        return color;
    }

    public render(): JSX.Element {

        const { item, intervalType, timezone } = this.props;

        return (
            <React.Fragment>
                <tr style={{backgroundColor: this.rowColor}}>
                    {intervalType === IntervalType.Day ? <TableCell>{ item.date }</TableCell> : null}
                    <TableCell>{ item.sspProvider }</TableCell>
                    <TableCell>{ numeral(item.adSent).format("0,0") }</TableCell>
                    <TableCell>{ numeral(item.adSpm).format("$0,0.00") }</TableCell>
                    <TableCell>{ numeral(item.adStart).format("0,0") }</TableCell>
                    <TableCell>{ numeral(item.sspRequests).format("0,0") }</TableCell>
                    <TableCell>{ numeral(item.sspNetRevenue).format("$0,0.00") }</TableCell>
                    <TableCell>{ numeral(item.sspImpressions).format("0,0") }</TableCell>
                    <TableCell>{ numeral(item.impressionsDiffPercent).format("0,0.00") }%</TableCell>
                    <TableCell>{ numeral(item.impressionsDiff).format("0,0") }</TableCell>
                    <TableCell>{ numeral(item.netRevenueDiffPercent).format("0,0.00")}%</TableCell>
                    <TableCell>{ numeral(item.netRevenueDiff).format("0,0")}</TableCell>
                </tr>
            </React.Fragment>

    );
    }
}
