import * as React from "react";
import * as PropTypes from "prop-types";

export interface ISelectedAccountProps {

    children?: any;

    isSelectedAccount?: (include?: string[], exclude?: string[]) => boolean;

    include?: string[];

    exclude?: string[];
}

export interface IISelectedAccountPropsState {

}

export default ({ isSelectedAccount, include, exclude, children }: ISelectedAccountProps): JSX.Element =>
    isSelectedAccount(include, exclude) ? children : null;
