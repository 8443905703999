import Root from "./Root";
import { observer, inject } from "mobx-react";

export default inject((stores: any, props: any, context: any): any => ({
    isAuthenticating: stores.sessionStore.isAuthenticating,
    isAuthError: stores.sessionStore.isAuthError,
    history: stores.navigationStore.history,
    pendingNewVersion: stores.initialState.pendingNewVersion,
    setPendingNewVersionState: stores.initialState.setPendingNewVersion
}))(Root);
