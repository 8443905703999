import {action, toJS} from "mobx";
import {createDemandPartnerStore, navigationStore} from "../index";
import BaseDemandPartnerStore from "./BaseDemandPartnerStore";
import demandPartnersAPI from "../../api/demandPartnersAPI";
import * as _ from "lodash";
import {guid} from "@vidazoo/ui-framework";
import {appUrlsService} from "../../services";
import {DemandPartnerStoreType} from "common/enums";

export default class DemandPartnerStore extends BaseDemandPartnerStore {
    constructor() {
        super(DemandPartnerStoreType.EDIT);
        this.reset();
    }

    @action public submit() {
        const item = this.submitify(); // TODO: should changed

        demandPartnersAPI.update(item._id, item)
            .then(() => this.onSubmitSuccess())
            .catch((err) => this.onSubmitFailed(err));

        this.item.params.forEach((param) => (param._id = guid()));
        this.item.hardcodedCpmTags.forEach((param) => (param._id = guid()));
    }

    @action public getItem(id: string) {
        this.isLoading = true;
        demandPartnersAPI.getOne(id)
            .then((res) => this.setItem(res.data))
            .catch((err) => this.onLoadFailed(err));
    }

    @action public setItem(item: any) {
        _.extend(this.item, item);
        this.item.params.forEach((param) => (param._id = guid()));
        this.item.hardcodedCpmTags.forEach((param) => (param._id = guid()));
        this.isLoading = false;
    }

    @action public duplicate() {
        const item = _.clone(toJS(this.item));
        _.unset(item, "_id");
        createDemandPartnerStore.reset();
        createDemandPartnerStore.setItem(item);

        navigationStore.push({ pathname: appUrlsService.createDemandPartner(), query: { duplicate: true }});
    }

    @action public delete() {
        demandPartnersAPI.delete(this.item._id)
            .then(() => this.onSubmitSuccess())
            .then(() => navigationStore.push(appUrlsService.demandPartners()))
            .catch((err) => this.onSubmitFailed(err));
    }
}
