import * as React from "react";
import {ButtonsGroup, GroupButton} from "@vidazoo/ui";
import {TimeRange} from "../../common/enums";

interface IPresetTimesProps {
    presetTime: TimeRange;
    onPresetChange: (preset) => void;
}

interface IPresetTimesState {

}

export default class PresetTimes extends React.Component<IPresetTimesProps, IPresetTimesState> {
    private onPresetTimeChange = (preset) => {
        const {onPresetChange} = this.props;
        onPresetChange(preset);
    }

    public render() {
        const {presetTime} = this.props;
        return (
            <ButtonsGroup onChange={this.onPresetTimeChange} active={presetTime}>
                <GroupButton name={TimeRange.TODAY}>Today</GroupButton>
                <GroupButton name={TimeRange.YESTERDAY}>Yesterday</GroupButton>
                <GroupButton name={TimeRange.LAST_7_DAYS}>Last 7 Days</GroupButton>
                <GroupButton name={TimeRange.WEEK_TO_DATE}>Week To Date</GroupButton>
                <GroupButton name={TimeRange.LAST_MONTH}>Last Month</GroupButton>
                <GroupButton name={TimeRange.MONTH_TO_DATE}>Month To Date</GroupButton>
                <GroupButton name="custom">Custom</GroupButton>
            </ButtonsGroup>
        );
    }
}
