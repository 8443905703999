import "bootstrap";
import * as React from "react";
import {render} from "react-dom";
import {Link} from "react-router-dom";
import {default as UIProvider, IUIProviderConfig} from "@vidazoo/ui/lib/components/uiProvider";
import {Root} from "containers";
import {Provider as StateProvider} from "mobx-react";
import * as stores from "stores";
import * as _ from "lodash";
import {hot} from "react-hot-loader/root";
import {ThemeManager} from "@vidazoo/ui-framework";

_.templateSettings.interpolate = /([a-zA-Z]+)/g;

const uiProviderConfig: IUIProviderConfig = {
    appContainerSelector: "#root",
    linkComponent: Link,
    baseAppTitle: "Money Booker",
    warnDevProductionApiUsage: true
};

render(
    <UIProvider config={uiProviderConfig}>
        <StateProvider {...stores}>
            <ThemeManager>
                <Root />
            </ThemeManager>
        </StateProvider>
    </UIProvider>,
    document.querySelector(uiProviderConfig.appContainerSelector)
);

if (process.env.NODE_ENV === "development") {
    hot(Root);
}
