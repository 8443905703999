import {Loader, PageMessage} from "@vidazoo/ui";
import * as React from "react";
import * as theme from "./theme.scss";

export interface ILoaderProps {
    title?: string;
    description?: string;
}

export default ({title, description}: ILoaderProps): JSX.Element => {
    return (
        <div className={theme.message}>
            <Loader size={2} gray className={theme.loader}/>
            <PageMessage
                small
                title={title}
                description={description}
            />
        </div>
    );
};
