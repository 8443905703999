import { observable, action, computed, transaction } from "mobx";
import * as _ from "lodash";
import { networksAPI } from "api";
import BaseItemStore, { IBaseItemStore } from "@vidazoo/ui-framework/lib/stores/BaseItemStore";
import { INetwork } from "interfaces/INetwork";
import { notificationsStore, navigationStore } from "..";
import { appUrlsService } from "services";

export interface ICreateNetworkStoreStore extends IBaseItemStore<INetwork> {
}

export default class CreateNetworkStore extends BaseItemStore<INetwork> implements ICreateNetworkStoreStore {

    constructor() {
        super(notificationsStore, "network");
    }

    @action public reset() {
        transaction(() => {
            super.reset();
            this.item = {
                ...this.item,
                name: ""
            };
        });
    }

    @action public submit() {
        networksAPI.create(this.item)
            .then(() => navigationStore.push(appUrlsService.networks()))
            .then(() => this.onSubmitSuccess())
            .catch((err) => this.onSubmitFailed(err));
    }
}
