
export const getConfig = (series) => ({
    lang: {
        thousandsSep: ","
    },
    chart: {
        spacing: [0, 0, 0, 0],
        marginTop: 18,
        backgroundColor: null,
        type: "line",
        style: {
            overflow: "visible"
        }
    },
    title: {
        text: null,
    },
    plotOptions: {
        series: {
            pointInterval: 24 * 3600 * 1000,
            marker: {
                enabled: true,
                lineColor: null,
                fillColor: "#ffffff",
                radius: 2,
                lineWidth: 1
            },

            events: {
            },
            borderWidth: 0,
            lineWidth: 2,
            states: {
                hover: {
                    lineWidth: 2,
                    halo: {
                        size: 8,
                        attributes: {
                            "opacity": 0.3,
                            "stroke-width": 2
                        }
                    }
                }
            }
        }
    },
    legend: {
        enabled: true,
    },
    credits: {
        enabled: false
    },
    tooltip: {
        borderWidth: 0,
        backgroundColor: null,
        useHTML: true,
        shared: true,
        crosshairs: {
            color: "#e5e6e8",
            width: 1
        },
        style: {
            pointerEvents: "visiblePainted",
        },
        xDateFormat: "%d/%m/%Y",
        positioner: (labelWidth, labelHeight, point) => {
            return {x: point.plotX - (labelWidth / 2) , y: 110};
        },
    },
    xAxis: [{
        type: "datetime",
        dateTimeLabelFormats: {
            day: "%d/%m/%Y"
        },
        lineColor: "#999ba4",
    }

    ],
    yAxis: [
        {
            title: {
                enabled: false
            },
            labels: {
                enabled: true,
                align: "right",
                padding: 0,
                x: -10,
                useHTML: true,
                style: {
                    color: "#999ba4",
                    fontWeight: "600",
                    fontFamily: "'Nunito Sans', sans-serif",
                    fontSize: 11,
                    textTransform: "uppercase",
                }
            },
            min: 0,
            gridLineWidth: 1,
            gridLineColor: "#e5e6e8",
            maxPadding: 0,
            minPadding: 0
        }],
    exporting: {
        buttons: {
            contextButton: {
                enabled: false,
            }
        }
    },
    // navigation: {
    //     buttonOptions: {
    //         theme: {
    //             'stroke-width': 1,
    //             stroke: 'silver',
    //             r: 0,
    //             states: {
    //                 hover: {
    //                     fill: '#a4edba'
    //                 },
    //                 select: {
    //                     stroke: '#039',
    //                     fill: '#a4edba'
    //                 }
    //             }
    //         }
    //     }
    // },

    series,
}) as Highcharts.Options;
