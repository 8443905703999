import * as React from "react";
import {ThemeProvider} from "@vidazoo/ui";
import * as theme from "./theme.scss";

export default (props): JSX.Element => (
    <ThemeProvider {...props} theme={
        {
            RTTable: {
                table: theme.table
            },
            RTTableHeaderColumn: {
                column: theme.column,
                inner: theme.inner
            },
            RTTableHeaderSummaryColumn: {
                column: theme.column,
                inner: theme.inner
            }
        }
    }/>
);
