import {action, computed, IObservableArray, observable} from "mobx";
import * as _ from "lodash";
import {multiWordFilter} from "@vidazoo/ui/lib/components/utils";
import {SortDirection} from "@vidazoo/ui";
import {IDictionary} from "@vidazoo/ui-framework";
import {IJobEntry} from "../../interfaces/IJobEntry";
import {jobsStatusAPI} from "api";
import {notificationsStore} from "../index";
import {Moment} from "moment";
import {TimeRange} from "../../common/enums";
import * as moment from "moment";
import {storageService} from "../../services";

const SSP_CREDENTIALS_SEARCHABLE_FIELDS = ["ssp", "status", "created", "updated", "params.demandPartner.name"];

export default class JobsStatusStore {
    @observable public sortBy: string;
    @observable public sortDirection: SortDirection;
    @observable public searchQueries: IDictionary<string>;
    @observable public jobs: IObservableArray<IJobEntry>;
    @observable public isLoading: boolean;
    @observable public from: Moment;
    @observable public to: Moment;
    @observable public minDate: Moment;
    @observable public maxDate: Moment;

    constructor(private searchList = SSP_CREDENTIALS_SEARCHABLE_FIELDS) {
        this.reset();
    }

    @action
    public getJobs = () => {
        this.isLoading = true;
        jobsStatusAPI.getJobs(this.from.unix(), this.to.unix())
            .then((res) => this.setJobs(res.data))
            .catch(() => this.onGetJobsFailed());
    }

    @action public setSearchQuery = (searchKey: string, value: string) => {
        this.searchQueries[searchKey] = value;
    }

    @action public sort = (sortBy: string, preserveDirection?: boolean) => {

        if (!this.jobs) {
            return;
        }

        if (sortBy === this.sortBy && !preserveDirection) {
            this.sortDirection = this.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        }

        this.sortBy = sortBy;
        this.jobs.replace(_.orderBy(this.jobs, [this.sortBy], [this.sortDirection.toString() as any]));
    }

    @computed
    public get filteredItems(): IJobEntry[] {

        const hasQueries = _(this.searchQueries).values().compact().value().length > 0;

        if (!hasQueries) {
            return this.jobs;
        }

        return _.filter(this.jobs, (item) => {
            const matchQueries = _.map(this.searchQueries, (query: string, field: string) => {
                // return (item[field] ? item[field].toString() : "").toLowerCase().indexOf(query.toLowerCase()) > -1;

                // null values will pass the _.get check ¯\_(ツ)_/¯
                const value = (_.get(item, field, "") || "").toString();

                return multiWordFilter(value, query);
            });

            return _.every(matchQueries);
        });
    }

    @action
    public onJobStatusChange(data: Partial<IJobEntry>) {
        const {id, status, updated, report, error} = data;
        const foundJob = this.findJobById(id);

        if (foundJob) {
            foundJob.status = status;
            foundJob.updated = updated;

            if (report) {
                foundJob.report = report;
            } else {
                foundJob.error = error;
            }
        }
    }

    @action
    public cancelJob = (id: number) => {
        jobsStatusAPI.cancelJob(id);
    }

    @action
    public forceStartJob(id: number) {
        jobsStatusAPI.forceStartJob(id);
    }

    @action
    private reset() {
        this.jobs = observable([]);
        this.isLoading = false;
        this.from = moment().startOf("day");
        this.to = moment().endOf("day");
        this.minDate = moment().utc().startOf("day").subtract(7, "day");
        this.maxDate = moment().utc().startOf("day");
        this.resetSearchQueries();
    }

    @action
    private resetSearchQueries() {
        const searchQueries = {};
        _.forEach(this.searchList, (field) => {
            searchQueries[field] = "";
        });

        this.searchQueries = searchQueries;
    }

    @action
    private setJobs(jobs: any[]) {
        this.jobs = observable(jobs);
        this.sortDirection = SortDirection.DESC;
        this.sort("updated", true);
        this.isLoading = false;
    }

    @action
    private onGetJobsFailed() {
        notificationsStore.pushErrorNotification({
            title: "Oops!",
            text: "Something went wrong. try again.",
            timeout: 5000
        });

        this.isLoading = false;
    }

    @action
    private findJobById(jobId: number) {
        return this.jobs.find((job) => job.id === jobId);
    }

    @action public onFromDateSelect = (newFrom: Moment) => {
        this.from = newFrom;
    }

    @action public onToDateSelect = (newTo: Moment) => {
        this.to = newTo;
    }

    public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
        storageService.setColumnsWidth(`jobStatus`, columnsWidth);
    }

    public getColumnsWidth = (): { [index: string]: number } => {
        return storageService.getColumnsWidth(`jobStatus`);
    }
}
