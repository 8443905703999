import * as React from "react";
import List from "./components/list";
import {
    Button,
    DateTimeRangePicker,
    PageContainer,
    PageTitle,
    SortDirection,
    Surface,
} from "@vidazoo/ui";
import ModalReport from "./components/modalReport";
import ModalError from "./components/modalError";
import {IJobEntry} from "../../interfaces/IJobEntry";
import {IDictionary, noop} from "@vidazoo/ui-framework";
import * as theme from "styles/baseList.scss";
import {Loader} from "../../components";
import {ReportingMessage} from "@vidazoo/ui-framework/lib/containers/reporting/components";
import * as selfTheme from "./theme.scss";
import {Moment} from "moment";

export interface IJobsStatusProps {
    jobs: IJobEntry[];
    getJobs: () => void;
    sortBy: string;
    sortDirection?: SortDirection;
    onSort: (sortBy: string) => void;
    searchQueries?: IDictionary<string>;
    onSearch?: (searchKey: string, value: string) => void;
    isLoading: boolean;
    cancelJob: (id: number) => void;
    forceStartJob: (id: number) => void;
    from: Moment;
    to: Moment;
    minDate: Moment;
    maxDate: Moment;
    onFromSelect: (from: Moment) => void;
    onToSelect: (to: Moment) => void;
    setColumnsWidth: (columnsWidth: { [index: string]: number }) => void;
    getColumnsWidth: () => { [index: string]: number };
}

export interface IJobStatusState {
    isModalReportOpen: boolean;
    modalReportItems: any[];
    isModalErrorOpen: boolean;
    modalErrorMsg: any;
    modalReportDemandPartnerName: string;
}

export default class JobsStatus extends React.Component<IJobsStatusProps, IJobStatusState> {

    constructor(props, context) {
        super(props, context);

        this.state = {
            isModalReportOpen: false,
            modalReportItems: [],
            isModalErrorOpen: false,
            modalErrorMsg: null,
            modalReportDemandPartnerName: ""
        };
    }

    public componentDidMount() {
        const {getJobs} = this.props;
        getJobs();
    }

    private onCloseModalReportClick = () => {
        this.setState({isModalReportOpen: false});
    }

    private onCloseModalErrorClick = () => {
        this.setState({isModalErrorOpen: false});
    }

    private openModalReport = (items: IJobEntry[], demandPartnerName: string) => {
        this.setState({
            isModalReportOpen: true,
            modalReportItems: items,
            modalReportDemandPartnerName: demandPartnerName
        });
    }

    private openModalError = (error: string) => {
        this.setState({
            isModalErrorOpen: true,
            modalErrorMsg: error
        });
    }

    private renderEmptyListMessage(): JSX.Element {
        const {jobs, isLoading} = this.props;

        if (!jobs && !isLoading) {
            return <ReportingMessage iconName="icon-table-empty" title="No Jobs at this time"/>;
        }
    }

    private renderJobsList(): JSX.Element {
        const {isLoading, jobs, searchQueries, sortBy, sortDirection, onSort, onSearch, cancelJob, forceStartJob, setColumnsWidth, getColumnsWidth} = this.props;

        if (jobs && !isLoading) {
            return (
                <List
                    items={jobs}
                    onReportResultsClick={this.openModalReport}
                    onErrorInfoClick={this.openModalError}
                    cancelJob={cancelJob}
                    onSort={onSort}
                    onSearch={onSearch}
                    searchQueries={searchQueries}
                    sortDirection={sortDirection}
                    sortBy={sortBy}
                    forceStartJob={forceStartJob}
                    setColumnsWidth={setColumnsWidth}
                    getColumnsWidth={getColumnsWidth}
                />
            );
        }
    }

    private renderLoader(): JSX.Element {
        const {isLoading} = this.props;

        if (isLoading) {
            return <Loader title="Loading Jobs" description="Please Wait..."/>;
        }
    }

    public render(): JSX.Element {
        const {isModalReportOpen, modalReportItems, isModalErrorOpen, modalErrorMsg, modalReportDemandPartnerName} = this.state;
        const {minDate, maxDate, from, to, onFromSelect, onToSelect, getJobs} = this.props;

        return (
            <PageContainer pageTitle="Jobs Status" top={1.2} className={theme.list}>
                <Surface level={2}>
                    <div className={selfTheme.rangePickerPanel}>
                        <PageTitle className={theme.title} iconName="icon-dashboard-2" label="Jobs Status"/>
                        <div className={selfTheme.actions}>
                            <div className={selfTheme.rangePicker}>
                                <DateTimeRangePicker fromDate={from} toDate={to} fromPickerProps={{dateProps: {minDate, maxDate, enablePassiveDay: false}}}
                                                     toPickerProps={{dateProps: {minDate, maxDate, enablePassiveDay: false}}}  onFromChange={onFromSelect} onToChange={onToSelect} enableAllDay={false} />
                            </div>
                            <div className={selfTheme.buttons}>
                                <Button iconName="icon-reload" onClick={getJobs} medium blue> Reload Jobs</Button>
                            </div>
                        </div>
                    </div>
                </Surface>
                {this.renderLoader()}
                {this.renderJobsList()}
                {this.renderEmptyListMessage()}
                <ModalReport isOpen={isModalReportOpen} items={modalReportItems} demandPartnerName={modalReportDemandPartnerName} onDone={this.onCloseModalReportClick}/>
                <ModalError isOpen={isModalErrorOpen} error={modalErrorMsg} onDone={this.onCloseModalErrorClick}/>
            </PageContainer>
        );
    }
}
