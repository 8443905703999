import * as React from "react";
import * as theme from "./theme.scss";
import {Checkbox, DataGridColumn, DataGridRow, Icon, Label, LinkButton, Switch, TableCell} from "@vidazoo/ui";
import {IJobEntry} from "interfaces/IJobEntry";
import * as moment from "moment";
import {JobStatus} from "common/enums";
import {Link} from "react-router-dom";
import {appUrlsService} from "../../../../../../services";

export interface IJobRowProps {
    item?: IJobEntry;
    onReportResultsClick: (items: IJobEntry[], demandPartnerName: string) => void;
    onErrorInfoClick: (error: string) => void;
    cancelJob: (id: number) => void;
    forceStartJob: (id: number) => void;
    columnsWidth: any;
    freezeColumnIndex: number;
    index: number;
}

export interface IJobRowState {
}

export default class JobRow extends React.Component<IJobRowProps, IJobRowState> {

    private onReportResultsClick = () => {
        const {item, onReportResultsClick} = this.props;
        onReportResultsClick(item.report.rows, item.params.demandPartner.name);
    }

    private onErrorInfoClick = () => {
        const {item, onErrorInfoClick} = this.props;
        onErrorInfoClick(item.error);
    }

    private onCancelJobClick = () => {
        const {item, cancelJob} = this.props;
        cancelJob(item.id);
    }

    private onForceStartJob = () => {
        const {forceStartJob, item} = this.props;
        forceStartJob(item.id);
    }

    private renderWarningIcon(): JSX.Element {
        const {item} = this.props;
        const oneHourAfter = moment.unix(item.updated).add("1", "hour");

        if (moment().isBefore(oneHourAfter) || (JobStatus.pending !== JobStatus[item.status] && JobStatus.started !== JobStatus[item.status])) {
            return;
        }

        return <Icon iconName="icon-warning" iconColor="#f4a142" size={2.2}/>;
    }

    private renderStatus(status: string): JSX.Element {
        return (
            <div className={theme.statusContainer}>
                <div className={theme.statusWrapper} style={{transform: `translateY(-${JobStatus[status] * 2.8}rem)`}}>
                    <div className={theme.statusHolder}><Label>PENDING</Label></div>
                    <div className={theme.statusHolder}><Label className={theme.yellow}>STARTED</Label></div>
                    <div className={theme.statusHolder}><Label className={theme.green}>COMPLETED</Label></div>
                    <div className={theme.statusHolder}><Label className={theme.red}>FAILED</Label></div>
                    <div className={theme.statusHolder}><Label className={theme.grey}>CANCELLED</Label></div>
                </div>
            </div>
        );
    }

    public render(): JSX.Element {
        const {item, index, columnsWidth, freezeColumnIndex} = this.props;

        // return (
        //     <React.Fragment>
        //         <tr>
        //             <TableCell>
        //                 <div className={theme.nameCell}>
        //                     {item.params.demandPartner.name}
        //                     {this.renderWarningIcon()}
        //                 </div>
        //             </TableCell>
        //             <TableCell>{item.ssp}</TableCell>
        //             <TableCell>{this.renderStatus(item.status)}</TableCell>
        //             <TableCell>{moment.unix(item.params.from).utc().format("DD/MM/YYYY")}</TableCell>
        //             <TableCell>{moment.unix(item.params.to).utc().format("DD/MM/YYYY")}</TableCell>
        //             <TableCell>{moment.unix(item.created).format("DD/MM/YYYY HH:mm")}</TableCell>
        //             <TableCell>{moment.unix(item.updated).format("DD/MM/YYYY HH:mm")}</TableCell>
        //             <TableCell className={theme.cell}>{item.report ?
        //                 <LinkButton className={theme.link} iconName="icon-reporting" iconSize={2.8}
        //                             onClick={this.onReportResultsClick}/> : null}</TableCell>
        //             <TableCell className={theme.cell}>{item.error ?
        //                 <LinkButton className={theme.link} iconName="icon-help-support" iconSize={2.8} red
        //                             onClick={this.onErrorInfoClick}/> : null}</TableCell>
        //             <TableCell className={theme.cell}>
        //                 <div className={theme.actionButtons}>
        //                     <LinkButton className={theme.link} iconName="icon-delete" iconSize={2}
        //                                 onClick={this.onCancelJobClick}
        //                                 disabled={JobStatus.pending !== JobStatus[item.status]}/>
        //                     <LinkButton className={theme.link} iconName="icon-reload" iconSize={2}
        //                                 onClick={this.onForceStartJob}
        //                                 disabled={JobStatus.pending !== JobStatus[item.status]}/>
        //                 </div>
        //             </TableCell>
        //         </tr>
        //     </React.Fragment>
        // );

        return (
            <DataGridRow className={theme.row} index={index} columnsWidth={columnsWidth} key={index}
                         freezeColumnIndex={freezeColumnIndex}>
                <DataGridColumn className={theme.column}><Link
                    to={appUrlsService.editDemandPartner(item.params.demandPartner._id)}>
                    {item.params.demandPartner.name}
                    {this.renderWarningIcon()}</Link></DataGridColumn>
                <DataGridColumn className={theme.column}>{item.ssp}</DataGridColumn>
                <DataGridColumn className={theme.column}>{this.renderStatus(item.status)}</DataGridColumn>
                <DataGridColumn className={theme.column}>{moment.unix(item.params.from).utc().format("DD/MM/YYYY")}</DataGridColumn>
                <DataGridColumn className={theme.column}>{moment.unix(item.params.to).utc().format("DD/MM/YYYY")}</DataGridColumn>
                <DataGridColumn className={theme.column}>{moment.unix(item.created).format("DD/MM/YYYY HH:mm")}</DataGridColumn>
                <DataGridColumn className={theme.column}>{moment.unix(item.updated).format("DD/MM/YYYY HH:mm")}</DataGridColumn>
                <DataGridColumn className={theme.column}>{item.report ?
                    <LinkButton className={theme.link} iconName="icon-reporting" iconSize={2.8}
                                onClick={this.onReportResultsClick}/> : null}</DataGridColumn>
                <DataGridColumn className={theme.column}>{item.error ?
                    <LinkButton className={theme.link} iconName="icon-help-support" iconSize={2.8} red
                                onClick={this.onErrorInfoClick}/> : null}</DataGridColumn>
                <DataGridColumn className={theme.column}>
                    <div className={theme.actionButtons}>
                        <LinkButton className={theme.link} iconName="icon-delete" iconSize={2}
                                    onClick={this.onCancelJobClick}
                                    disabled={JobStatus.pending !== JobStatus[item.status]}/>
                        <LinkButton className={theme.link} iconName="icon-reload" iconSize={2}
                                    onClick={this.onForceStartJob}
                                    disabled={JobStatus.pending !== JobStatus[item.status]}/>
                    </div>
                </DataGridColumn>
                {/*<DataGridColumn className={theme.column}><Switch small checked={item.vidazooImpUpdateCPM}*/}
                {/*                                                 onChange={this.onVidazooImpUpdateCPM}/></DataGridColumn>*/}
                {/*<DataGridColumn className={theme.column}><Switch small checked={item.autoUpdateCPM}*/}
                {/*                                                 onChange={this.onAutoUpdateCPM}/></DataGridColumn>*/}
                {/*<DataGridColumn className={theme.column}><Switch small checked={item.isActive}*/}
                {/*                                                 onChange={this.onActiveChange}/></DataGridColumn>*/}
                {/*<DataGridColumn*/}
                {/*    className={theme.column}>{moment(item.created).format("DD/MM/YYYY HH:mm")}</DataGridColumn>*/}
                {/*<DataGridColumn className={theme.column}>*/}
                {/*    <div className={theme.links}>*/}
                {/*        <LinkButton iconName="icon-edit" iconSize={1.8} onClick={this.onEditClick}/>*/}
                {/*        <LinkButton onClick={this.onStartMineClick} iconName="icon-reload" iconSize={1.8}/>*/}
                {/*        <LinkButton onClick={this.onDeleteClick} iconName="icon-delete" iconSize={1.8}/>*/}
                {/*    </div>*/}
                {/*</DataGridColumn>*/}
            </DataGridRow>
        );
    }
}
