import * as React from "react";
import {IDictionary} from "@vidazoo/ui-framework";
import {
    SortDirection,
    IDataGridHeader,
    DataGrid,
    DataGridRow,
    DataGridHeaderColumn,
    Checkbox,
    Density
} from "@vidazoo/ui";
import JobRow from "./components/jobRow";
import {IJobEntry} from "interfaces/IJobEntry";
import * as moment from "moment";
import * as theme from "./theme.scss";
import * as _ from "lodash";

export interface IDataGridHeaderJobEntries extends IDataGridHeader {
    searchOptions?: Array<{ value: string, label: string }>;
}

const headers: IDataGridHeaderJobEntries[] = [{
    label: "Name",
    sortBy: "params.demandPartner.name",
    searchKey: "params.demandPartner.name"
}, {
    label: "SSP",
    sortBy: "ssp",
    searchKey: "ssp",
}, {
    label: "Status",
    sortBy: "status",
    searchKey: "status",
}, {
    label: "From",
    sortBy: "params.from",
    searchKey: "params.from",
}, {
    label: "To",
    sortBy: "params.to",
    searchKey: "params.to",
}, {
    label: "Created",
    sortBy: "created",
    searchKey: "created",
}, {
    label: "Updated",
    sortBy: "updated",
    searchKey: "updated",
}, {
    label: "Report",
}, {
    label: "Error",
}, {
    label: "Actions",
}];

export interface IListProps {
    items: IJobEntry[];
    sortBy: string;
    sortDirection?: SortDirection;
    onSort: (sortBy: string) => void;
    searchQueries?: IDictionary<string>;
    onSearch?: (searchKey: string, value: string) => void;
    onReportResultsClick: (items: any[], demandPartnerName: string) => void;
    onErrorInfoClick: (error: string) => void;
    cancelJob: (id: number) => void;
    forceStartJob: (id: number) => void;
    setColumnsWidth: (columnsWidth: { [index: string]: number }) => void;
    getColumnsWidth: () => { [index: string]: number };
    density?: Density;
}

export interface IListState {
}

export default class List extends React.Component<IListProps, IListState> {

    constructor(props, context) {
        super(props, context);
    }

    private renderHeaderAndFooter: any = (scrollTop, scrollLeft, columnsWidth, setFreezeColumn, freezeColumnIndex) => {
        const {
            sortDirection,
            onSearch,
            sortBy,
            onSort,
            searchQueries,
        } = this.props;
        return (
            <DataGridRow columnsWidth={columnsWidth}>

                {headers.map((header: IDataGridHeaderJobEntries) => {
                    const props = {
                        blue: true,
                        label: header.label,
                        sortable: !!header.sortBy,
                        sortBy: header.sortBy,
                        sortActive: sortBy === header.sortBy,
                        searchKey: header.searchKey,
                        searchable: !!header.searchKey,
                        searchQuery: searchQueries[header.searchKey || ""],
                        searchOptions: header.searchOptions,
                        freezeColumnIndex,
                        columnsWidth,
                        setFreezeColumn,
                        sortDirection,
                        onSort,
                        onSearch,
                    };
                    return (
                        <DataGridHeaderColumn {...props}/>
                    );
                })}
            </DataGridRow>
        );
    };

    private onColumnResize = (columnsWidth) => {
        this.props.setColumnsWidth(columnsWidth);
    };

    private renderRow: any = (index, viewportIndex, columnsWidth, freezeColumnIndex) => {
        const {
            items,
            sortBy,
            onReportResultsClick,
            onErrorInfoClick,
            cancelJob,
            forceStartJob
        } = this.props;

        const item = items[index];

        return (
            <JobRow
                key={item.id}
                item={item}
                // sortBy={sortBy}
                columnsWidth={columnsWidth}
                freezeColumnIndex={freezeColumnIndex}
                index={index}
                onReportResultsClick={onReportResultsClick}
                onErrorInfoClick={onErrorInfoClick}
                cancelJob={cancelJob}
                forceStartJob={forceStartJob}
            />
        );
    };

    public render() {
        const {items, getColumnsWidth} = this.props;
        const columnsWidth = getColumnsWidth();

        return (
            <DataGrid
                onColumnResize={this.onColumnResize}
                className={theme.list}
                extraData={items}
                headersCount={headers.length}
                itemsCount={items.length}
                renderRow={this.renderRow}
                rowHeight={40}
                columnsWidth={columnsWidth}
                renderHeader={this.renderHeaderAndFooter}/>
        );
    }
}
