import * as React from "react";
import {TableCell, ThemeProvider} from "@vidazoo/ui";
import {ITableCellProps} from "@vidazoo/ui/lib/components/tableCell";
import * as theme from "./theme.scss";

export default (props?: ITableCellProps): JSX.Element => (
    <ThemeProvider theme={{
        RTTableCell: {
            cell: theme.cell,
            inner: theme.inner
        }
    }}>
        <TableCell {...props} />
    </ThemeProvider>
);
