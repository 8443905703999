import * as React from "react";
import Switch from "react-router-dom/Switch";
import { AuthRoute } from "../../components";
import {appUrlsService} from "../../services";
import EditDemandPartner from "./edit";
import CreateDemandPartner from "./create";

export interface ISSPCredentialProps {

}

export default (): JSX.Element => (
    <Switch>
        <AuthRoute path={appUrlsService.editDemandPartner()} exact component={EditDemandPartner} />
        <AuthRoute path={appUrlsService.createDemandPartner("/:flag?")} exact component={CreateDemandPartner} />
    </Switch>
);
