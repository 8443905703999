import * as React from "react";
import {
    Button,
    DateTimeRangePicker,
    FormSelect,
    HLine,
    Label,
    NativeScrollable,
    PageContainer,
    PageTitle,
    Surface,
    Table
} from "@vidazoo/ui";
import {guid} from "@vidazoo/ui-framework";
import * as moment from "moment";
import Headers from "./components/table/headers/Headers";
import ReportRow from "./components/table/reportRow/ReportRow";
import {getKeyByValue, getObjectValues} from "../../common/utils";
import * as theme from "./theme.scss";
import {Moment} from "moment";
import {DailyReportField, TimeRange} from "common/enums";
import IDailyReport from "../../interfaces/dailyReport/IDailyReport";
import {Loader, DateTimeRangePickerThemed, PresetTimes} from "../../components";
import DailyReportChart from "./components/dailyReportChart";

export interface IDailyReportProps {
    report: IDailyReport;
    getReport: () => void;
    getDemandPartners: () => void;
    onStartMineClick: (demandPartnerId: string, from: moment.Moment, to: moment.Moment) => void;
    isLoading: boolean;
    from: Moment;
    to: Moment;
    dates: number[];
    onFromSelect: (from: Moment) => void;
    onToSelect: (to: Moment) => void;
    onSort: (sortBy: string) => void;
    sortBy: string;
    onSortChange: (sortBy: string) => void;
    download: () => void;
    resetReport: () => void;
    chartData: { [demandPartner: string]: number[] };
    chartParameter: string;
    onChartParameterSelect: (parameter: string) => void;
    presetTime?: TimeRange;
    onPresetTimeChange?: (preset: TimeRange) => void;
}

export interface IDailyReportState {
}

export default class DailyReport extends React.Component<IDailyReportProps, IDailyReportState> {

    constructor(props, context) {
        super(props, context);

        const {resetReport} = this.props;

        resetReport();
    }

    public componentDidMount() {
        this.loadReport();
    }

    private onReloadReportClick = () => {
        this.loadReport();
    }

    private loadReport() {
        const {getReport, getDemandPartners} = this.props;
        getReport();
        getDemandPartners();
    }

    private onMineClick = (name: string) => {
        const {onStartMineClick, from, to} = this.props;
        onStartMineClick(name, from, to);
    }

    private onSortByChange = (e) => {
        const {onSortChange} = this.props;
        onSortChange(getKeyByValue(DailyReportField, e.target.value));
    }

    private renderLoader(): JSX.Element {
        const {isLoading} = this.props;

        if (!isLoading) {
            return;
        }

        return <Loader title="Loading Daily Report" description="Please wait..."/>;
    }

    private renderChart(): JSX.Element {
        const {onChartParameterSelect, chartData, chartParameter, isLoading} = this.props;
        if (!chartData || isLoading) {
            return;
        }

        return (
            <div className={theme.chart}>
                <DailyReportChart chartData={chartData} onChangeParameter={onChartParameterSelect}
                                  parameter={chartParameter}/>
            </div>
        );

    }

    private renderReport(): JSX.Element {
        const {report, dates, isLoading} = this.props;

        if (!report || isLoading) {
            return;
        }

        return (
            <Surface level={2}>
                <NativeScrollable scrollSize={14} suppressY stickyOverflowScrollX bold>
                    <Table stickyHeaderOffsetTop={80} enableStickyHeader={true} className={theme.table}>
                        <thead>
                        <Headers dates={dates}/>
                        </thead>
                        <tbody>
                        <ReportRow data={report.totals} dates={dates} isTotalRow={true}/>
                        {report.providersData.map((provider) => (
                            <ReportRow key={guid()} dates={dates} data={provider} onMineClick={this.onMineClick}/>
                        ))}
                        </tbody>
                    </Table>
                </NativeScrollable>
            </Surface>

        );
    }

    public render(): JSX.Element {
        const {from, to, onFromSelect, onToSelect, sortBy, download, presetTime, onPresetTimeChange} = this.props;
        return (
            <PageContainer pageTitle={"Daily Report"}>
                <Surface level={2}>
                    <div className={theme.rangePickerPanel}>
                        <PageTitle className={theme.title} iconName="icon-dashboard" label="Daily Report"/>
                        <div className={theme.presetTimes}>
                            <PresetTimes presetTime={presetTime} onPresetChange={onPresetTimeChange}/>
                        </div>
                        <div className={theme.rangePicker}>
                            <DateTimeRangePickerThemed>
                                <DateTimeRangePicker fromDate={from} toDate={to} onFromChange={onFromSelect}
                                                     onToChange={onToSelect}
                                                     allDay={true}/>
                            </DateTimeRangePickerThemed>
                            <Label className={theme.label} spacing={1} size={11}>Sort By </Label><FormSelect
                            value={DailyReportField[sortBy]} items={getObjectValues(DailyReportField)}
                            onChange={this.onSortByChange}/>
                        </div>
                        <HLine/>
                        <div className={theme.actions}>
                            <div className={theme.buttons}>
                                <Button iconName="icon-download" onClick={download} medium green>Download CSV</Button>
                                <Button iconName="icon-reload" onClick={this.onReloadReportClick} medium blue>Reload
                                    Report</Button>
                            </div>
                        </div>
                    </div>
                </Surface>
                {this.renderLoader()}
                {this.renderChart()}
                {this.renderReport()}
            </PageContainer>
        );
    }
}
