import {BaseAPI} from "@vidazoo/ui-framework";

class JobsStatusAPI extends BaseAPI {

    public getJobs(from: number, to: number): Promise<any> {
        return this.request({
            method: "get",
            url: "/api/jobs/get_jobs",
            params: {
                from,
                to
            }
        });
    }

    public cancelJob(id: number) {
        return this.request({
            method: "patch",
            url: `/api/jobs/${id}/cancel`
        });
    }

    public forceStartJob(id: number) {
        return this.request({
            method: "post",
            url: `/api/jobs/force_start`,
            params: {
                id
            }
        });
    }
}

export default new JobsStatusAPI(window.__BASE_API_URL__);
