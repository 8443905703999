import * as React from "react";
import * as classnames from "classnames";
import * as theme from "./theme.scss";
import { RouteComponentProps } from "react-router";
import { UserScope } from "..";
import { AppBar } from "@vidazoo/ui/lib/components/appBar";
import { UserMenu } from "@vidazoo/ui/lib/components/userMenu";
import { MainMenu } from "@vidazoo/ui/lib/components/mainMenu";
import { Menu, MenuItem } from "@vidazoo/ui/lib/components/menu";
import { MenuTree, IMenuNode } from "@vidazoo/ui/lib/components/menuTree";
import { UserRole, NotificationPanel } from "@vidazoo/ui-framework";
import routes from "common/routes";
import { appUrlsService } from "services";
import { AccountsList } from "..";
import settingsRoute from "common/settingsRoute";
import {VidazooHeaderLogo} from "@vidazoo/ui";
import {userNotificationsStore} from "../../stores";

const USER_MENU_LINKS = [
    { label: "Terms & Conditions", native: true, linkProps: { href: (window as any).__TERMS_URL__, target: "_blank" } },
    { label: "Privacy Policy", native: true, linkProps: { href: (window as any).__PRIVACY_URL__, target: "_blank" } }
];

export interface IAppHeaderProps extends RouteComponentProps<any> {
    userName?: string;

    userEmail?: string;

    isInRoles?: (rols: UserRole[]) => boolean;

    userAvatarUrl?: string;

    onSignOut?: () => void;

    openMyProfile?: () => void;

    isVidazooOrganization?: boolean;

    userId?: string;
}

export interface IAppHeaderState {
    mounted: boolean;
}

export default class AppHeader extends React.Component<IAppHeaderProps, IAppHeaderState> {

    public static defaultProps: Partial<IAppHeaderProps> = {

    };

    constructor(props, context) {
        super(props, context);

        this.state = { mounted: false };
    }

    public componentDidMount() {
        window.requestAnimationFrame(() => {
            this.setState({ mounted: true });
        });
    }

    public onLogoClick = () => {
        const { history } = this.props;

        history.push(appUrlsService.app());
    }

    private onLogout = () => {
        const { onSignOut } = this.props;

        onSignOut();
    }

    private reduceRouteItems(items: IMenuNode[]): IMenuNode[] {
        const { isInRoles } = this.props;

        return items.reduce((result, item) => {
            if (isInRoles(item.access)) {
                if (item.items) {
                    item.items = this.reduceRouteItems(item.items);
                }

                return result.concat(item);
            }

            return result;
        }, []);
    }

    public renderSettingsMenu(): JSX.Element {

        return (
            <UserScope scope={settingsRoute.access}>
                <MainMenu iconName={settingsRoute.iconName} stickTo="right">
                    {settingsRoute.items ? settingsRoute.items.map((item) => {
                        return (
                            <UserScope scope={item.access} key={item.label}>
                                <MenuItem label={item.label} link={item.link} />
                            </UserScope>
                        );
                    }) : null}
                </MainMenu>
            </UserScope>
        );
    }

    public render(): JSX.Element {

        const { location, userName, userEmail, userAvatarUrl, openMyProfile, isVidazooOrganization, userId } = this.props;

        const { mounted } = this.state;

        const classes = classnames(theme.header, {
            [theme.mounted]: mounted
        });

        return (
            <AppBar enableSticky className={classes}>
                <div className={theme.inner}>
                    <div className="flex align-center">
                        <div onClick={this.onLogoClick} className={theme.logo}>
                            <VidazooHeaderLogo width={15.6} color={"#44ff38"} logoName={"Moneybooker"}/>
                        </div>
                    </div>
                    <MenuTree className={"flex-1 " + theme.menuTree} items={this.reduceRouteItems(routes)} />
                    <AccountsList />
                    {isVidazooOrganization ? <NotificationPanel userId={userId} userNotificationsStore={userNotificationsStore} /> : null}
                    <div className="flex align-center">
                        {this.renderSettingsMenu()}
                        <UserMenu links={USER_MENU_LINKS} smallAvatar darkAvatar userName={userName} userEmail={userEmail} avatarUrl={userAvatarUrl} className={theme.user}>
                            <MenuItem iconName="icon-settings" label="My Profile" onClick={openMyProfile} />
                            <MenuItem iconName="icon-sign-out" label="Sign Out" onClick={this.onLogout} />
                        </UserMenu>
                    </div>
                </div>
            </AppBar>
        );
    }
}
