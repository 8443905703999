import * as React from "react";
import { IDictionary, guid } from "@vidazoo/ui-framework";
import {
    SortDirection,
    Density,
    IDataGridHeader,
    DataGridRow,
    DataGridHeaderColumn,
    Checkbox,
    DataGrid,
    DataGridColumn
} from "@vidazoo/ui";
import ECPMReportRow from "./components/ecpmReportRow";
import IECPMReportEntry from "interfaces/IECPMReportEntry";
import IECPMSums from "interfaces/IECPMSums";
import * as numeral from "numeral";
import TableThemedProvider from "./components/tableThemedProvider/index";
import {IntervalType} from "../../../../common/enums";
import * as theme from "./theme.scss";

export const reportListHeaders = [{
    label: "Source",
    sortBy: "source",
    searchKey: "source",
}, {
    label: "Partner",
    sortBy: "tagPartnerId",
    searchKey: "tagPartnerId",
}, {
    label: "Tag",
    sortBy: "tagPartnerName",
    searchKey: "tagPartnerName",
}, {
    label: "Sent",
    sortBy: "adSent",
    searchKey: "adSent",
}, {
    label: "Spm",
    sortBy: "adSpm",
    searchKey: "adSpm",
}, {
    label: "Start",
    sortBy: "adStart",
    searchKey: "adStart",
}, {
    label: "Cpm",
    sortBy: "adCpm",
    searchKey: "adCpm",
}, {
    label: "IMA",
    sortBy: "imaFloorCpm",
    searchKey: "imaFloorCpm",
}, {
    label: "Partner",
    sortBy: "sspProvider",
    searchKey: "sspProvider",
}, {
    label: "Tag",
    sortBy: "sspTagName",
    searchKey: "sspTagName",
}, {
    label: "IMP",
    sortBy: "sspImpressions",
    searchKey: "sspImpressions",
}, {
    label: "Revenue",
    sortBy: "sspNetRevenue",
    searchKey: "sspNetRevenue",
}, {
    label: "REQ",
    sortBy: "sspRequests",
    searchKey: "sspRequests",
}, {
    label: "ECPM",
    sortBy: "sspEcpm",
    searchKey: "sspEcpm",
}, {
    label: "ECPM Diff(%)",
    sortBy: "cpmDiffPercent",
    searchKey: "cpmDiffPercent",
}, {
    label: "IMP Diff(%)",
    sortBy: "impressionsDiffPercent",
    searchKey: "impressionsDiffPercent",
}, {
    label: "IMP Diff",
    sortBy: "impressionsDiff",
    searchKey: "impressionsDiff",
}, {
    label: "REV Diff(%)",
    sortBy: "netRevenueDiffPercent",
    searchKey: "netRevenueDiffPercent",
}, {
    label: "REV Diff",
    sortBy: "netRevenueDiff",
    searchKey: "netRevenueDiff",
}];

export const reportListDayHeaders = [
    {
        label: "Date",
        sortBy: "date",
        searchKey: "date"
    },
    ...reportListHeaders
]

export interface IListProps {
    items?: IECPMReportEntry[];
    searchQueries: IDictionary<string>;
    sortBy: string;
    sortDirection: SortDirection;
    onSort: (sortBy: string) => void;
    onSearch: (searchKey: string, value: string) => void;
    density: Density;
    loadMore: () => void;
    setColumnsWidth: (columnsWidth: { [index: string]: number }) => void;
    getColumnsWidth: () => { [index: string]: number };
    intervalType: IntervalType;
    sums: IECPMSums;
}

export interface IListState {

}

export default class List extends React.Component<IListProps, IListState> {

    constructor(props, context) {
        super(props, context);
    }

    private renderHeaderAndFooter: any = (scrollTop, scrollLeft, columnsWidth, setFreezeColumn, freezeColumnIndex) => {
        const {
            sortDirection,
            onSearch,
            sortBy,
            onSort,
            searchQueries,
            intervalType
        } = this.props;

        const headers = intervalType === IntervalType.Day ? reportListDayHeaders : reportListHeaders;

        return (
            <>
                <DataGridRow columnsWidth={columnsWidth}>
                    {headers.map((header: IDataGridHeader) => {
                        const props = {
                            blue: true,
                            label: header.label,
                            sortable: !!header.sortBy,
                            sortBy: header.sortBy,
                            sortActive: sortBy === header.sortBy,
                            searchKey: header.searchKey,
                            searchable: !!header.searchKey,
                            searchQuery: searchQueries[header.searchKey || ""],
                            freezeColumnIndex,
                            columnsWidth,
                            setFreezeColumn,
                            sortDirection,
                            onSort,
                            onSearch,
                        };
                        return (
                            <DataGridHeaderColumn {...props}/>
                        );
                    })}
                </DataGridRow>
                {this.renderSumBarRow(scrollTop, scrollLeft, columnsWidth, setFreezeColumn, freezeColumnIndex)}
            </>
        );
    };

    private renderSumBarRow: any = (scrollTop, scrollLeft, columnsWidth, setFreezeColumn, freezeColumnIndex) => {
        const {
            intervalType,
            sums,
            items
        } = this.props;

        return (
            <DataGridRow className={theme.sumBar} freezeColumnIndex={freezeColumnIndex} columnsWidth={columnsWidth}>
                {this.renderSumBarColumn(`Total Row: ${items.length}`)}
                {intervalType === IntervalType.Day ? this.renderSumBarColumn() : null}
                {this.renderSumBarColumn()}
                {this.renderSumBarColumn()}
                {this.renderSumBarColumn(numeral(sums.adSent).format("0,0").toString())}
                {this.renderSumBarColumn(numeral(sums.adSpm).format("0,0.00").toString(), "adSpm")}
                {this.renderSumBarColumn(numeral(sums.adStart).format("0,0").toString(), "adStart")}
                {this.renderSumBarColumn(numeral(sums.adCpm).format("$0,0.00").toString(), "adCpm")}
                {this.renderSumBarColumn()}
                {this.renderSumBarColumn()}
                {this.renderSumBarColumn()}
                {this.renderSumBarColumn(numeral(sums.sspImpressions).format("0,0").toString(), "sspImpressions")}
                {this.renderSumBarColumn(numeral(sums.sspNetRevenue).format("$0,0.00").toString(), "sspNetRevenue")}
                {this.renderSumBarColumn(numeral(sums.sspRequests).format("0,0").toString(), "sspRequests")}
                {this.renderSumBarColumn(numeral(sums.sspEcpm).format("$0,0.00").toString(), "sspEcpm")}
                {this.renderSumBarColumn(numeral(sums.cpmDiffPercent).format("0,0.00").toString() + "%", "cpmDiffPercent")}
                {this.renderSumBarColumn(numeral(sums.impressionsDiffPercent).format("0,0.00").toString() + "%", "impressionsDiffPercent")}
                {this.renderSumBarColumn(numeral(sums.impressionsDiff).format("0,0.00").toString(), "impressionsDiff")}
                {this.renderSumBarColumn(numeral(sums.netRevenueDiffPercent).format("0,0.00").toString() + "%", "netRevenueDiffPercent")}
                {this.renderSumBarColumn(numeral(sums.netRevenueDiff).format("0,0").toString(), "netRevenueDiff")}
            </DataGridRow>
        );
    }

    private renderSumBarColumn: any = (value?: string, name?: string) => {
        return (
            <DataGridColumn key={name} density={null}>
                <div className={theme.cell}>
                    {value}
                </div>
            </DataGridColumn>
        );
    }

    private onColumnResize = (columnsWidth) => {
        this.props.setColumnsWidth(columnsWidth);
    };

    private renderRow: any = (index, viewportIndex, columnsWidth, freezeColumnIndex) => {
        const {
            items,
            intervalType,
        } = this.props;
        const item = items[index];

        return (
            <ECPMReportRow
                key={guid()}
                item={item}
                intervalType={intervalType}
                columnsWidth={columnsWidth}
                freezeColumnIndex={freezeColumnIndex}
                index={index}
            />
        );
    };

    public render() {
        const {items, getColumnsWidth, intervalType} = this.props;
        const columnsWidth = getColumnsWidth();
        const headers = intervalType === IntervalType.Day ? reportListDayHeaders : reportListHeaders;

        return (
            <DataGrid
                onColumnResize={this.onColumnResize}
                className={theme.list}
                extraData={items}
                headersCount={headers.length}
                itemsCount={items.length}
                renderRow={this.renderRow}
                rowHeight={40}
                columnsWidth={columnsWidth}
                renderHeader={this.renderHeaderAndFooter}/>
        );
    }
}
